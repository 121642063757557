import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

export const StatusReceivedChip = styled(Chip)(
{  
  borderColor: '#2196f3', color: '#2196f3', fontWeight: 'bold', borderWidth: 3,
});
export const StatusPendingChip = styled(Chip)(
{
  backgroundColor: 'gray',borderColor: 'gray',color: 'white',fontWeight: 'bold', borderWidth: 3,
});
export const StatusAppointmentConfirmedChip = styled(Chip)(
{
  borderColor: 'blue',color: 'blue',fontWeight: 'bold', borderWidth: 3,
});
export const StatusAppointmentCompleteChip = styled(Chip)(
{
  borderColor: 'orange',  color: '#01579b', fontWeight: 'bold', borderWidth: 3,
});
export const CancelledChip = styled(Chip)(
{
  borderColor: 'gray',color: 'gray',fontWeight: 'bold',borderWidth: 3,
});
export const InspectionCompletedChip = styled(Chip)(
{  
  borderColor: '#01579b',color: 'gray',fontWeight: 'bold',borderWidth: 3,
});
export const InspectionStartedChip = styled(Chip)(
{ 
  borderColor: 'red',color: 'gray',fontWeight: 'bold',borderWidth: 3,
});
export const CompletedChip = styled(Chip)(
{ 
  borderColor: '#4caf50',color: '#4caf50',fontWeight: 'bold',borderWidth: 3,
});