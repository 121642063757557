import {Box,Typography,Container} from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const NotFound = () => {      
  return (
    <>
    <Box
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        boxShadow:3,
        justifyContent: 'center',       
      }}
      style={{ marginTop: "1%" }}
    >
      <Container 
        maxWidth="md" 
        style={{ marginTop: "8%",marginBottom: "20%" }}
      >
        <Typography
          align="center"
          color="textPrimary"
          variant="h6"
           style={{ marginTop: "20%",marginBottom: "10%" }}
        >
          <WarningAmberIcon style={{ marginRight: "10px", marginTop:"50px" }}/>
          404: Unable to process your request
        </Typography>
        <Typography
          align="center"
          color="textPrimary"
          variant="subtitle2"
          style={{ marginBottom: "10%" }}
        >
          This page has encountered a critical error. Contact your system administrator if this problem persists.
        </Typography>        
      </Container>
    </Box>
    </>
    );
}

export default NotFound;
