export const IsNull = function (x) {
    if (typeof (x) === 'undefined') return true;
    if (x === null) return true;

    return false;
};

export const IsNullOrEmpty = function (x) {
    if (IsNull(x)) return true;
    if (x === "") return true;

    return false;
};

export const ArrayIsNullOrEmpty = function (x) {
    if (IsNull(x)) return true;
    if (x.length === 0) return true;

    return false;
};

export const ParseEventStringValue = function (event) {
    if (IsNullOrEmpty(event?.target?.value)) return "";

    return event.target.value;
};

export const IsValidDateTime = function (date) {
    if (!IsNullOrEmpty(date))
        return (new Date(date) !== "Invalid Date") && (new Date(date).getFullYear() > 2000);
    else
        return false;
}

export const DigitCount = function (str) {
    let count = 0;

    for (let ch of str) {
        if (ch >= "0" && ch <= "9") {
            count++;
        }
    }
    return count;
}

export const CheckIsValidDomain = function (domain) {
    var re = new RegExp(/[a-z][\.][a-z]{2,5}/);
    return re.test(domain);
}

export function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}

export function reformatDate(date) {
    return [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
    ].join('-');
}