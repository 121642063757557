import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import { BrowserRouter} from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser"; 
import { msalConfigAdmin } from "./views/authentication/AuthConfig";
import { msalConfigB2C } from "./views/authentication/AuthConfigB2C";

window.isCarrierPortalPilotAdmin = window.location.href.includes("admin"); 

const msalConfig = window.isCarrierPortalPilotAdmin ? msalConfigAdmin : msalConfigB2C; 
const msalInstance = new PublicClientApplication(msalConfig);
//const msalInstance = new PublicClientApplication(msalConfigB2C);
//TODO: what are we using BrowserRouter for?
// what is it that would need to be configured around the App? I would like to move all the things inside App

ReactDOM.render(  
  <BrowserRouter>
    <App msalInstance={msalInstance} isAdmin={window.isCarrierPortalPilotAdmin} /> 
  </BrowserRouter>,  
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
