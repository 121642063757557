import { useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./AuthConfig";
import Configuration from '../../helpers/Configuration';
import { IsNullOrEmpty } from "../../helpers/Common";

export default function DirectLogin() {
  const { instance } = useMsal();

  useEffect(() => {
    instance.loginRedirect(loginRequest)
      .catch(e => {
        if (!IsNullOrEmpty(Configuration.ENVIRONMENT) && Configuration.ENVIRONMENT !== "production") {
          console.log("AUTH ERROR", e);
        }
      });
  }, []);
  return null;
};