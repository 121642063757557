import React from 'react'; 
import { styled } from '@mui/material/styles';
import MuiTextField from '@mui/material/TextField';

const options = {
  shouldForwardProp: (prop) => prop !== 'borderColor',
};
 
const CustomTextField = styled(
  MuiTextField,
  options,
)(({ borderColor }) => ({
  '& label.Mui-focused': {
    color: '#000000',
  },
  ['& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline']: {
    borderWidth: 3,
    borderColor,
  },
}));

export const TextField = (props) => <CustomTextField {...props} borderColor="#87b8f3" />