import React from 'react';
import WaitContainer from './WaitContainer';

export default function WaitContainerTimeout(props) {
  
  const [isWaiting, setIsWaiting] = React.useState(true);
  const [isWaitingTimeout, setIsWaitingTimeout] = React.useState(false);

  React.useEffect(() => {  
    setIsWaiting(true);   
  },[props.reset]);
 
  return (
    <WaitContainer isWaiting={isWaiting} timeoutInMilliseconds={500} onWaitTimeout={()=>{setIsWaiting(false);setIsWaitingTimeout(true);}}>
       {props.children} 
    </WaitContainer>    
  );
}