import React, { useEffect, useRef, useState } from "react";
import IdleTimer from "react-idle-timer";
import SessionTimeoutDialog from "./SessionTimeoutDialog";
import { useMsal } from "@azure/msal-react";
import { GetAccessTokenRequest } from "../components/Account";

let countdownInterval;
let timeout;

const SessionTimeout = ({ logOut }) => {
  const { instance } = useMsal();
  const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
  const [timeoutCountdown, setTimeoutCountdown] = useState(0);
  const idleTimer = useRef(null);
  const accounts = instance.getAllAccounts();
  const [expiredInterval, setExpiredInterval] = useState(0);  
  const isAdmin = window.isCarrierPortalPilotAdmin;
  const clearSessionTimeout = () => {
    clearTimeout(timeout);
  };

  const clearSessionInterval = () => {
    clearInterval(countdownInterval);
  };

  const handleLogout = async () => {
    try {
      setTimeoutModalOpen(false);
      if (isAdmin) {
        await instance.logoutRedirect({
          postLogoutRedirectUri: "/admin",
        })
      }
      else {
        await instance.logoutRedirect({
          postLogoutRedirectUri: "/",
        })
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleContinue = () => {
    handleTokenExpired();
    setTimeoutModalOpen(false);
    clearSessionInterval();
    clearSessionTimeout();
    refreshPage();
    setExpiredInterval(0);
  };

  const onActive = () => {
    if (!timeoutModalOpen) {
      clearSessionInterval();
      clearSessionTimeout();
    }
  };

  const refreshPage = () => {
    window.location.reload(true);
  }

  useEffect(() => {
    handleTokenExpired();
  }, [])

  function handleTokenExpired() {
    instance.acquireTokenSilent(GetAccessTokenRequest(accounts)).then(function (accessTokenResponse) {
      var currentUnixTimeInSeconds = new Date().getTime() / 1000;  //convert datetime format in Unix Time (in seconds)
      var expiresOnUnixTimeInSeconds = accessTokenResponse.idTokenClaims.exp; //format in Unix Time (in seconds)
      var tenSeconds = 10;

      if (expiresOnUnixTimeInSeconds < currentUnixTimeInSeconds) {
        // Token Expired                  
        handleLogout(true);
      }
      else {
        //expiredInterval in milliseconds 
        //We want to convert second(Unix Time) to milliseconds for expiredInterval [1 second = 1000 milliseconds]
        //set Modal opens 10 seconds before token expire and start counting down
        //Math.abs() returns the absolute value of a number
        var diff = Math.abs(expiresOnUnixTimeInSeconds - currentUnixTimeInSeconds - tenSeconds) * 1000;
        setExpiredInterval(diff);
      }
    });
  }

  const onIdle = () => {
    if (!timeoutModalOpen && expiredInterval > 0) {
      setTimeout(() => {
        let countDown = 10;
        setTimeoutModalOpen(true);
        setTimeoutCountdown(countDown);
        countdownInterval = setInterval(() => {
          if (countDown > 0) {
            setTimeoutCountdown(--countDown);
          } else {
            handleLogout(true);
          }
        }, 1000);
      }, expiredInterval);
    }
  };

  return (
    <>
      <IdleTimer
        ref={idleTimer}
        onActive={onActive}
        onIdle={onIdle}
        debounce={250}
        timeout={5000}
      />
      <SessionTimeoutDialog
        countdown={timeoutCountdown}
        onContinue={handleContinue}
        onLogout={() => handleLogout(false)}
        open={timeoutModalOpen}
      />
    </>
  );
}

export default SessionTimeout;