import React, { useRef, forwardRef, useImperativeHandle } from "react";
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Grid,
  Typography,
  AlertTitle
} from '@mui/material';

function Complete(props, ref) {
  const jobRequest = useStoreState((state) => state.jobRequest.currentJobRequest);
  const timeSlot = useStoreState((state) => state.timeSlot);
  const history = useHistory();
  const isAdmin = window.isCarrierPortalPilotAdmin;
  const emptyCurrentJobRequest = useStoreActions(actions => actions.jobRequest.emptyCurrentJobRequest);
  const setPageModeValue = useStoreActions(actions => actions.setPageModeValue);
  const btnNext = useRef();
  const reset = () => {
    emptyCurrentJobRequest();
    setPageModeValue("create");
    if (isAdmin) {
      history.push("/admin/jobrequest");
    }
    else {
      history.push("/jobrequest");
    }
  }
  useImperativeHandle(ref, () => ({
    blur: () => {
      reset();
    },
  }))
  return (
    <>
      <div style={{ width: '80vw' }}>
        <Grid container spacing={2} style={{ marginLeft: 50, marginTop: 50 }}>
          <Grid item xs={12}>
            <Typography variant="h6">
              Your request has successfully been submitted for Claim # {jobRequest.claimNumber} - {jobRequest.policyHolderFirstName},  {jobRequest.policyHolderLastName}  for {timeSlot.scheduleDate} & {timeSlot.startHour} - {timeSlot.endHour}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">
              You will receive an email confirmation. Thank you for your business!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-first">
              <Button
                ref={btnNext}
                {...props}
                style={{ marginLeft: 10 }}
                type="button"
                variant="contained"
                aria-label="submit"
                color="primary"
                sx={{ paddingTop: 1.2 }}
              >
                Book a new appointment
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default forwardRef(Complete);