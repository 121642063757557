import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from "@material-ui/core/styles";
// import List from '@mui/material/List';
// import MuiListItem from '@mui/material/ListItem';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText'; //TO-Do: Highlight is not working using mui
import List from "@material-ui/core/List";
import MuiListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useHistory } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Typography from '@mui/material/Typography';
import Ballot from '@mui/icons-material/Ballot';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { Header } from './Header';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem'
import { useStoreState, useStoreActions } from 'easy-peasy';

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: 'yellow'
  }
}));

const ListItem = withStyles({
  root: {
    '&.MuiListItem-root': {
      width: '220px',
      cursor: 'pointer',
    },
    '&:hover': {
      backgroundColor: '#d6d6d6',
      color: 'black',

    },
    "&$selected": {
      backgroundColor: "#3d3d3d",
      color: "white",
      "& .MuiListItemIcon-root": {
        color: "white"
      }
    },
    "&$selected:hover": {
      backgroundColor: "#d6d6d6",
      color: "black",
      "& .MuiListItemIcon-root": {
        color: "white"
      }
    },
  },
  selected: {}
})(MuiListItem);

export default function Layout(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const sidebarSelectedIndex = useStoreState((state) => state.sidebarSelectedIndex);
  const setSidebarSelectedIndex = useStoreActions((actions) => actions.setSidebarSelectedIndex);
  const clearForecast = useStoreActions(actions => actions.clearForecast);
  const emptyCurrentJobRequest = useStoreActions(actions => actions.jobRequest.emptyCurrentJobRequest);
  const isAdmin = window.isCarrierPortalPilotAdmin;
  const [width, setWidth] = useState(window.innerWidth);
  const history = useHistory();
  const isMobile = (width <= 768);
  const handleClose = (ev) => {
    setAnchorEl(null);
  };

  const handleListItemClick = (event, index) => {
    clearForecast();
    emptyCurrentJobRequest();
    setSidebarSelectedIndex(index);
  };

  const handleSignout = () => {
    isAdmin ? window.location.href = "/admin/signout" : window.location.href = "/signout";
  }

  useEffect(() => {
    setSelectedIndex(sidebarSelectedIndex);
  }, [sidebarSelectedIndex])

  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Header isAuthenticated="true" />
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: 500,
              width: '25ch',
            },
          }}
        >
          {['Logout'].map((option) => (
            <MenuItem key={option} onClick={handleClose}>
              {option}
            </MenuItem>
          ))}
        </Menu>
        <div style={{ paddingTop: '65px', maxWidth: '560px', }}>
          <List>
            <ListItem style={{ height: 63, paddingTop: 23 }} selected={selectedIndex === 0} onClick={(event) => { handleListItemClick(event, 0); !isAdmin ? history.push("dashboard") : history.push("/admin/dashboard"); }}>
              <ListItemIcon><DashboardIcon /></ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
            <ListItem selected={selectedIndex === 1} onClick={(event) => { handleListItemClick(event, 1); !isAdmin ? history.push("jobrequest") : history.push("/admin/jobrequest"); }}>
              <ListItemIcon><Ballot /></ListItemIcon>
              <ListItemText primary="Job Request" />
            </ListItem>
            {
              isAdmin &&
              <React.Fragment>
                <ListItem selected={selectedIndex === 2} onClick={(event) => { handleListItemClick(event, 2); history.push("/admin/setting"); }}>
                  <ListItemIcon><SettingsIcon /></ListItemIcon>
                  <ListItemText primary="Setting" />
                </ListItem>
                <ListItem selected={selectedIndex === 3} onClick={(event) => { handleListItemClick(event, 3); history.push("/admin/availability"); }}>
                  <ListItemIcon><UploadFileRoundedIcon /></ListItemIcon>
                  <ListItemText primary="Availability" />
                </ListItem>
              </React.Fragment>
            }
            <ListItem selected={selectedIndex === 4} onClick={(event) => { handleListItemClick(event, 4); handleSignout(); }}>
              <ListItemIcon><LogoutIcon /></ListItemIcon>
              <ListItemText primary="Log out" />
            </ListItem>
          </List>
        </div>
        {
          isMobile ?
            <div style={{ paddingTop: 80 }}>
              <main>
                {props.children}
              </main>
            </div>
            :
            <div style={{ paddingTop: 100, paddingRight: 20, paddingBottom: 20, paddingLeft: 15 }}>
              <main>
                {props.children}
              </main>
            </div>
        }
      </Box>
      <footer style={{ marginTop: 'auto', paddingRight: 20, textAlign: 'right', paddingTop: 150 }} >
        <Typography style={{ marginLeft: drawerWidth }}>
          PILOT CATASTROPHE &nbsp;&copy; {(new Date().getFullYear())}
        </Typography>
      </footer>
    </div>
  );
}
