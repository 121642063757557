import React from "react";
/*
See main ReadMe for details on autocomplete off Form
When the form is present and the user clicks 'enter' key, the form submits and postback to the server.
To prevent that, we handle it in onKeyPress/onSubmit.
*/
export const AutoCompleteOffForm = (props) => {   
    function PreventEnterRefreshPage(e)
    {
      e.key === 'Enter' && e.preventDefault();
    }    
    return (
        <>
            <form
                {...props}
                autoComplete={"off"}   
                onKeyPress={(e) => PreventEnterRefreshPage(e)}
                onSubmit={(e) => false}>            
            </form>            
        </>
    );
}
