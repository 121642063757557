import React from 'react';
import Wait from './Wait';
import { IsNull } from '../helpers/Common';

export default function WaitContainer(props) {
 
  React.useEffect(() => {
    if(!IsNull(props.timeoutInMilliseconds)){
      const timer = setInterval(() => {
        props.onWaitTimeout();
      }, props.timeoutInMilliseconds);

      return () => {
        clearInterval(timer);
      };
    }
  });
  

  //console.log('Child.......', props.isWaiting);
  return (
    props.isWaiting ? 
      <div>
        <Wait /> 
      </div> 
    :
    <div> {props.children} </div> 
  );
}
/*
export default function WaitContainer({isWaiting:_isWaiting, timeoutInMilliseconds, children}) {
  const classes = useStyles();
  const [isWaiting, setIsWaiting] = React.useState(_isWaiting);
  
  React.useEffect(() => {
    if(!IsNull(timeoutInMilliseconds)){
      const timer = setInterval(() => {
        setIsWaiting(false);
      }, timeoutInMilliseconds);

      return () => {
        clearInterval(timer);
      };
    }
  }, []);
  

  return (
    isWaiting ? 
      <div className={classes.root}>
        <Wait /> 
      </div> 
    :
      {children}
  );
}
*/

/*
export default function WaitContainer({isWaiting, onWaitComplete, timeoutInMilliseconds, children}) {
  const classes = useStyles();
  const [isWaiting, setIsWaiting] = React.useState(isWaiting);

  if(!IsNull(timeoutInMilliseconds)){
    React.useEffect(() => {
      const timer = setInterval(() => {
        setIsWaiting(false);
      }, timeoutInMilliseconds);

      return () => {
        clearInterval(timer);
      };
    }, []);
  }

  return (
    isWaiting ?
    <WaitContext.Consumer>
    {({ waitingComponentKeys, _onWaitComplete }) => (
      <div className={classes.root}>
        <Wait /> 
      </div>      
    )}
    </WaitContext.Consumer>
    :
    {children}
  );
}
*/