import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import AddIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router-dom';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useStoreState, useStoreActions } from 'easy-peasy';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Button, Typography, Divider } from '@mui/material';
import { useMsal } from "@azure/msal-react";
import { useTheme } from '@mui/material/styles';
import ConfirmDialog from '../components/ConfirmDialog';
import { GetAccessTokenRequest } from "../components/Account";
import BlockIcon from '@mui/icons-material/Block';
import EventIcon from '@mui/icons-material/Event';
import DoneIcon from '@mui/icons-material/Done';
import { status } from '../helpers/Constants';
import {
  DataGrid,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridActionsCellItem,
} from '@mui/x-data-grid';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { createTheme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DownloadFile from "../components/DownloadFile";
import Tooltip from '@mui/material/Tooltip';
import Signout from './Signout';
import { IsNullOrEmpty, IsValidDateTime, DigitCount } from "../helpers/Common";
import {
  StatusReceivedChip, StatusPendingChip, StatusAppointmentConfirmedChip, CancelledChip
  , InspectionStartedChip, CompletedChip, InspectionCompletedChip
} from '../components/Status';

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const defaultTheme = createTheme();

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      },
      textField: {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
          marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    }),
  { defaultTheme },
);

function QuickSearchToolbar(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div>
        <GridToolbarExport />
        <GridToolbarFilterButton />
        <TextField
          variant="standard"
          value={props.value}
          onChange={props.onChange}
          placeholder="Search…"
          className={classes.textField}
          style={{ marginLeft: 10 }}
          inputprops={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: props.value ? 'visible' : 'hidden' }}
                onClick={props.clearSearch}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
        />
      </div>
    </div>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

function Dashboard(props) {
  const { instance } = useMsal();
  const classes = useStyles();
  const history = useHistory();
  const accessToken = useStoreState((state) => state.accessToken);
  const dashboard = useStoreState((state) => state.dashboard);
  const clearForecast = useStoreActions(actions => actions.clearForecast);
  const fetchDashboard = useStoreActions(actions => actions.fetchDashboard);
  const fetchAdminDashboard = useStoreActions(actions => actions.fetchAdminDashboard);
  const updateError = useStoreActions(actions => actions.updateError);
  const blob = useStoreState((state) => state.blob);
  const dataRows = useStoreState((state) => state.dashboard);
  const log = useStoreState((state) => state.log);
  const error = useStoreState((state) => state.error);
  const cancelSchedule = useStoreActions(actions => actions.cancelSchedule);
  const updateAccessToken = useStoreActions(actions => actions.updateAccessToken);
  const fetchInitialLoad = useStoreActions(actions => actions.fetchInitialLoad);
  const setBlob = useStoreActions(actions => actions.setBlob);
  const setSidebarSelectedIndex = useStoreActions((actions) => actions.setSidebarSelectedIndex);
  const emptyCurrentJobRequest = useStoreActions(actions => actions.jobRequest.emptyCurrentJobRequest);
  const [confirmOpen, setConfirmOpen] = useState();
  const [serviceAppointmentId, setServiceAppointmentId] = useState();
  const [userEmail, setUserEmail] = useState();
  const updateUserEmail = useStoreActions(actions => actions.updateUserEmail);
  const [loading, setLoading] = useState(false)
  const [noDataToggle, setNoDataToggle] = useState(false);

  const handleNewAppointmentClick = () => {
    clearForecast();
    setSidebarSelectedIndex(1);
    if (isAdmin) {
      history.push("/admin/jobrequest");
    }
    else {
      history.push("jobrequest");
    }
  };
  const isAdmin = window.isCarrierPortalPilotAdmin;
  const getAccount = () => {
    const accounts = instance.getAllAccounts();
    instance.acquireTokenSilent(GetAccessTokenRequest(accounts)).then(function (accessTokenResponse) {
      let accessToken = accessTokenResponse.accessToken;
      let email = encodeURI(accessTokenResponse.idTokenClaims.emails ? accessTokenResponse.idTokenClaims.emails[0] : accessTokenResponse.idTokenClaims.preferred_username);
      setUserEmail(email);
      updateUserEmail(email);
      updateAccessToken(accessToken);
      setLoading(true);
      emptyCurrentJobRequest();
      clearForecast();
      getDashboard(email, accessToken);
      setRows(dashboard.filter(item => item?.id !== 0));
      let carrierId = fetchInitialLoad({ accessToken: accessToken, userEmail: email });
      localStorage.setItem('carrierId', carrierId);
      localStorage.setItem('jobRequestId', '');
      emptyCurrentJobRequest();
    });
  };
  useEffect(() => {
    setSidebarSelectedIndex(0);
    getAccount();
  }, []);

  useEffect(() => {
    setLoading(true);
    if (dashboard != null && dashboard[0]?.id != 0 && dashboard[0]?.id != undefined) {
      setRows(dashboard);
      setNoDataToggle(false);
    }
    setLoading(false)
  }, [dashboard]);

  const getUserDashboard = async (userEmail, accessToken) => {
    const res = await fetchDashboard({ userEmail: userEmail, accessToken: accessToken })
      .then(res => {
        if (dashboard[0].id == 0) {
          setNoDataToggle(true);
        }
        else {
          setRows(dashboard);
        }
        setLoading(false);
      })
      .catch(err => {
        updateError({ ...log, message: res.message, isValid: false });
        return new Error(err.message);
      })
  }

  const getAdminDashboard = async (userEmail, accessToken) => {
    const res = await fetchAdminDashboard({ userEmail: userEmail, accessToken: accessToken })
      .then(res => {
        if (dashboard[0].id == 0) {
          setNoDataToggle(true);
        }
        else {
          setRows(dashboard);
        }
        setLoading(false);
      })
      .catch(err => {
        updateError({ ...log, message: res.message, isValid: false });
        return new Error(err.message);
      })
  }

  useEffect(() => {
    if (error === "access denial") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/admin",
      })
    }
  }, [error])

  const getDashboard = async (userEmail, accessToken) => {
    if (isAdmin) {
      getAdminDashboard(userEmail, accessToken);
    }
    else {
      getUserDashboard(userEmail, accessToken);
    }
  };

  const handleEditClick = (param, event) => {
    setSidebarSelectedIndex(1);
    clearForecast();
    if (isAdmin) {
      history.push({ pathname: "/admin/jobRequest-edit", state: { id: event.id } });
    }
    else {
      history.push({ pathname: "/jobRequest-edit", state: { id: event.id } });
    }
  };

  const deleteAppointment = () => {
    setLoading(true);
    const res = cancelSchedule({ serviceAppointmentId: serviceAppointmentId, accessToken: accessToken })
      .then(res => {
        setRows(res);
        getDashboard(userEmail, accessToken);
      })
      .catch(err => {
        updateError({ ...log, message: res.message, isValid: false });
        return new Error(err.message);
      })
    setLoading(false);
  };

  const [fileContents, setFileContents] = useState([]);

  const handleCloseFiles = () => {
    setPopUpLogoutDialog(false);
    setFileContents([])
  };

  const handleOpenFiles = (event, params) => {
    let result = [];
    setFileContents([]);

    var jsonResult = JSON.parse(params.row.files);
    for (var i = 0; i < jsonResult.length; i++) {
      result.push(jsonResult[i]);
    }
    for (const [index, attachment] of Object.entries(result)) {
      setFileContents(fileContents => [...fileContents, { AttachmentMetadataFileName: attachment.AttachmentMetadataFileName, ServiceAppointmentAttachmentId: attachment.ServiceAppointmentAttachmentId, AttachmentMetadataFileType: attachment.AttachmentMetadataFileType }])
    }
    setPopUpLogoutDialog(true);
  };

  useEffect(() => {
    downloadBlob();
    setBlob('');
  }, [blob])

  const downloadBlob = () => {
    if (blob.fileContents !== undefined && blob.fileContents.length > 0) {
      const link = document.createElement('a');
      link.href = `data: ${blob.contentType};base64, ${blob.fileContents}`;
      link.setAttribute(
        'download',
        `${blob.fileDownloadName}`,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    }
  }

  const handleStatus = (params) => {
    let statusValue = params;
    if (statusValue == 'Pending Confirmed Inspection Date')
      return (<div><StatusPendingChip label="Pending Confirmed Inspection Date" variant="outlined" /></div>)
    else if (statusValue == 'Received')
      return (<div><StatusReceivedChip label="Received" variant="outlined" /></div>)
    else if (statusValue == 'Appointment Confirmed')
      return (<div><StatusAppointmentConfirmedChip icon={<EventIcon />} label="Appointment Confirmed" variant="outlined" /></div>)
    else if (statusValue == 'Inspection Started')
      return (<div><InspectionStartedChip label="Inspection Started" variant="outlined" /></div>)
    else if (statusValue == 'Inspection Completed')
      return (<div><InspectionCompletedChip label="Inspection Completed" variant="outlined" /></div>)
    else if (statusValue == 'Complete')
      return (<div><CompletedChip icon={<DoneIcon style={{ color: '#4caf50' }} />} label="Complete" variant="outlined" /></div>)
    else if (statusValue == 'Cancelled')
      return (<div><CancelledChip icon={<BlockIcon />} label="Cancelled" variant="outlined" /></div>)
  }

  let size = 0;
  dataRows.map((row) => {
    if (row !== null && row !== undefined) {
      size = Math.max(size, row.id > 0 ? row.claimnumber.length : 0);
    }
  })

  const dataColumns = [
    { headerName: 'Claim #', field: 'claimnumber', minWidth: (size * 8.82) },
    { headerName: 'Inspector Name', field: 'inspectorname', minWidth: 150 },
    { headerName: 'Inspector Phone #', field: 'inspectorphonenumber', minWidth: 150 },
    { headerName: 'Adjuster Name', field: 'adjustername', minWidth: 150 },
    { headerName: 'Appointment DateTime', field: 'appointmentdatetime', minWidth: 200 },
    { headerName: 'Reschedule DateTime', field: 'rescheduledatetime', minWidth: 200 },
    { headerName: 'Job Start DateTime', field: 'jobstartdatetime', minWidth: 200 },
    { headerName: 'Job Completed DateTime', field: 'jobcompleteddatetime', minWidth: 200 },
    { headerName: 'Job Closed DateTime', field: 'jobcloseddatetime', minWidth: 200 },
    {
      headerName: 'Status',
      //alignItems
      field: 'status',
      renderCell: (params) => {
        return <>
          {handleStatus(params.value)}
        </>
      },
      minWidth: 260
    },
    {
      headerName: 'Files',
      field: 'files',
      renderCell: (params) => {
        return <>
          {params.row.files != "[]" &&
            <Tooltip title="Inspection documents available for download.">
              <AttachFileIcon
                variant="contained"
                color="primary"
                style={{ cursor: 'pointer' }}
                onClick={(event) => {
                  handleOpenFiles(event, params);
                }}
              />
            </Tooltip>
          }
        </>
      },
      minWidth: 100
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      renderCell: (cellValues) => {
        return (
          <>
            {cellValues.row.status != status[0] && cellValues.row.status != status[1] && cellValues.id != 0 &&
              <GridActionsCellItem
                label="Reschedule"
                className="textPrimary"
                onClick={(event) => {
                  handleEditClick(event, cellValues);
                }}
                color="inherit"
                icon={
                  <Tooltip title="Reschedule">
                    <EditIcon />
                  </Tooltip>
                }
              />
            }
            {cellValues.row.status != status[0] && cellValues.row.status != status[1] && cellValues.id != 0 &&
              <GridActionsCellItem
                icon={
                  <Tooltip title="Cancel">
                    <DeleteIcon />
                  </Tooltip>
                }
                label="Cancel"
                onClick={(event) => {
                  setConfirmOpen(true);
                  setServiceAppointmentId(cellValues.id);
                }}
                color="inherit"
              />
            }
          </>
        );
      }
    },
  ];
  const [searchText, setSearchText] = useState('');
  const [rows, setRows] = useState(dataRows.filter(item => item?.id !== 0));

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = dataRows.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field]?.toString());
      });
    });
    setRows(filteredRows);
  };

  const [popUpLogoutDialog, setPopUpLogoutDialog] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xl'));

  return (
    <div style={{ height: '80vh', width: 2200 }}>
      <Typography variant="h5" className={classes.appBarTitle}>
        Dashboard
      </Typography>
      <Divider style={{ marginTop: 10, marginBottom: 10 }} />
      <ConfirmDialog
        title="Delete Appointment"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={deleteAppointment}
      >
        Are you sure you want to cancel this appointment?
      </ConfirmDialog>
      {popUpLogoutDialog &&
        <Dialog
          PaperProps={{
            sx: {
              width: '80%',
              maxHeight: "50%",
              maxWidth: '200vh'
            }
          }}
          fullScreen={fullScreen}
          open={setPopUpLogoutDialog}
          onClose={handleCloseFiles}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Downloads"}
          </DialogTitle>
          <Divider />
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridGap: 1 }}>
                <div style={{ width: '400px', textAlign: 'left' }}>
                  <Typography style={{ marginLeft: 15, fontWeight: 'bold' }}>
                    FileName
                  </Typography>
                </div>
                <div style={{ textAlign: 'left' }}>
                  <Typography style={{ fontWeight: 'bold', marginTop: '1px' }}>
                    File Type
                  </Typography>
                </div>
              </div>
              <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridGap: 1 }}>
                {fileContents && fileContents.map((file) => {
                  return (
                    <>
                      <div >
                        <DownloadFile fileName={file.AttachmentMetadataFileName}
                          id={file.ServiceAppointmentAttachmentId}
                          contentType={file.AttachmentMetadataFileType} />
                      </div>
                      <div style={{ textAlign: 'left', marginLeft: '0px' }}>
                        {file.AttachmentMetadataFileType}
                      </div>
                    </>
                  )
                })}
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseFiles}>X</Button>
          </DialogActions>
        </Dialog>
      }
      {
        noDataToggle &&
        <Typography variant="h6">
          You haven't created any job requests. Create one now!
        </Typography>
      }
      <Button style={{ marginBottom: 15 }} color="primary" startIcon={<AddIcon />} onClick={handleNewAppointmentClick}>
        Schedule a new appointment
      </Button>
      {!noDataToggle &&
        <DataGrid
          loading={loading}
          components={{ Toolbar: QuickSearchToolbar }}
          columns={dataColumns}
          rows={rows}
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: (event) => requestSearch(event.target.value),
              clearSearch: () => requestSearch(''),
            },
          }}
        />
      }
    </div>
  );
}
export default Dashboard;
