const _Configuration = {
    DEVELOPMENT:{
        ENVIRONMENT                 : process.env.REACT_APP_DEVELOPMENT_HOST_ENV                ? process.env.REACT_APP_DEVELOPMENT_HOST_ENV                : '',    
        MSAL_AUTHORITY              : process.env.REACT_APP_DEVELOPMENT_AUTHORITY               ? process.env.REACT_APP_DEVELOPMENT_AUTHORITY               : '',
        MSAL_CLIENTID               : process.env.REACT_APP_DEVELOPMENT_CLIENTID                ? process.env.REACT_APP_DEVELOPMENT_CLIENTID                : '',
        MSAL_SCOPEPREFIX            : process.env.REACT_APP_DEVELOPMENT_SCOPEPREFIX             ? process.env.REACT_APP_DEVELOPMENT_SCOPEPREFIX             : '',
        LOGOUT_REDIRECT             : process.env.REACT_APP_DEVELOPMENT_POSTLOGOUTREDIRECTURI   ? process.env.REACT_APP_DEVELOPMENT_POSTLOGOUTREDIRECTURI   : '',
        LOGIN_REDIRECT              : process.env.REACT_APP_DEVELOPMENT_REDIRECTURI             ? process.env.REACT_APP_DEVELOPMENT_REDIRECTURI             : '',
        POLICY                      : process.env.REACT_APP_DEVELOPMENT_POLICY                  ? process.env.REACT_APP_DEVELOPMENT_POLICY                  : '',
        POLICY_URL                  : process.env.REACT_APP_DEVELOPMENT_POLICY_URL              ? process.env.REACT_APP_DEVELOPMENT_POLICY_URL              : '',
        AUTHORITY_DOMAIN            : process.env.REACT_APP_DEVELOPMENT_AUTHORITY_DOMAIN        ? process.env.REACT_APP_DEVELOPMENT_AUTHORITY_DOMAIN        : '',    
        EDIT_PROFILE_POLICY         : process.env.REACT_APP_DEVELOPMENT_EDIT_PROFILE_POLICY     ? process.env.REACT_APP_DEVELOPMENT_EDIT_PROFILE_POLICY     : '',    
        EDIT_PROFILE_POLICY_URL     : process.env.REACT_APP_DEVELOPMENT_EDIT_PROFILE_POLICY_URL ? process.env.REACT_APP_DEVELOPMENT_EDIT_PROFILE_POLICY_URL : '',  
        API_URL                     : process.env.REACT_APP_DEVELOPMENT_API_URL                 ? process.env.REACT_APP_DEVELOPMENT_API_URL                 : '', 
        MSAL_B2CCLIENTID            : process.env.REACT_APP_DEVELOPMENT_B2CCLIENTID             ? process.env.REACT_APP_DEVELOPMENT_B2CCLIENTID             : '',
        MSAL_B2CAUTHORITY           : process.env.REACT_APP_DEVELOPMENT_B2CAUTHORITY            ? process.env.REACT_APP_DEVELOPMENT_B2CAUTHORITY            : '',
        LOGIN_B2CREDIRECT           : process.env.REACT_APP_DEVELOPMENT_B2CREDIRECTURI          ? process.env.REACT_APP_DEVELOPMENT_B2CREDIRECTURI          : '',
        MSAL_B2CSCOPEPREFIX         : process.env.REACT_APP_DEVELOPMENT_B2CSCOPEPREFIX          ? process.env.REACT_APP_DEVELOPMENT_B2CSCOPEPREFIX          : '',
    },
    STAGING:{
        ENVIRONMENT                 : process.env.REACT_APP_STAGING_HOST_ENV                ? process.env.REACT_APP_STAGING_HOST_ENV                : '',    
        MSAL_AUTHORITY              : process.env.REACT_APP_STAGING_AUTHORITY               ? process.env.REACT_APP_STAGING_AUTHORITY               : '',
        MSAL_CLIENTID               : process.env.REACT_APP_STAGING_CLIENTID                ? process.env.REACT_APP_STAGING_CLIENTID                : '',
        MSAL_SCOPEPREFIX            : process.env.REACT_APP_STAGING_SCOPEPREFIX             ? process.env.REACT_APP_STAGING_SCOPEPREFIX             : '',
        LOGOUT_REDIRECT             : process.env.REACT_APP_STAGING_POSTLOGOUTREDIRECTURI   ? process.env.REACT_APP_STAGING_POSTLOGOUTREDIRECTURI   : '',
        LOGIN_REDIRECT              : process.env.REACT_APP_STAGING_REDIRECTURI             ? process.env.REACT_APP_STAGING_REDIRECTURI             : '',
        POLICY                      : process.env.REACT_APP_STAGING_POLICY                  ? process.env.REACT_APP_STAGING_POLICY                  : '',
        POLICY_URL                  : process.env.REACT_APP_STAGING_POLICY_URL              ? process.env.REACT_APP_STAGING_POLICY_URL              : '',
        AUTHORITY_DOMAIN            : process.env.REACT_APP_STAGING_AUTHORITY_DOMAIN        ? process.env.REACT_APP_STAGING_AUTHORITY_DOMAIN        : '',    
        EDIT_PROFILE_POLICY         : process.env.REACT_APP_STAGING_EDIT_PROFILE_POLICY     ? process.env.REACT_APP_STAGING_EDIT_PROFILE_POLICY     : '',    
        EDIT_PROFILE_POLICY_URL     : process.env.REACT_APP_STAGING_EDIT_PROFILE_POLICY_URL ? process.env.REACT_APP_STAGING_EDIT_PROFILE_POLICY_URL : '',  
        API_URL                     : process.env.REACT_APP_STAGING_API_URL                 ? process.env.REACT_APP_STAGING_API_URL                 : '', 
        MSAL_B2CCLIENTID            : process.env.REACT_APP_STAGING_B2CCLIENTID             ? process.env.REACT_APP_STAGING_B2CCLIENTID             : '',
        MSAL_B2CAUTHORITY           : process.env.REACT_APP_STAGING_B2CAUTHORITY            ? process.env.REACT_APP_STAGING_B2CAUTHORITY            : '',
        LOGIN_B2CREDIRECT           : process.env.REACT_APP_STAGING_B2CREDIRECTURI          ? process.env.REACT_APP_STAGING_B2CREDIRECTURI          : '',  
        MSAL_B2CSCOPEPREFIX         : process.env.REACT_APP_STAGING_B2CSCOPEPREFIX          ? process.env.REACT_APP_STAGING_B2CSCOPEPREFIX          : '',
    },
    PRODUCTION:{
        ENVIRONMENT                 : process.env.REACT_APP_HOST_ENV                ? process.env.REACT_APP_HOST_ENV                : '',    
        MSAL_AUTHORITY              : process.env.REACT_APP_AUTHORITY               ? process.env.REACT_APP_AUTHORITY               : '',
        MSAL_CLIENTID               : process.env.REACT_APP_CLIENTID                ? process.env.REACT_APP_CLIENTID                : '',
        MSAL_SCOPEPREFIX            : process.env.REACT_APP_SCOPEPREFIX             ? process.env.REACT_APP_SCOPEPREFIX             : '',
        LOGOUT_REDIRECT             : process.env.REACT_APP_POSTLOGOUTREDIRECTURI   ? process.env.REACT_APP_POSTLOGOUTREDIRECTURI   : '',
        LOGIN_REDIRECT              : process.env.REACT_APP_REDIRECTURI             ? process.env.REACT_APP_REDIRECTURI             : '',
        POLICY                      : process.env.REACT_APP_POLICY                  ? process.env.REACT_APP_POLICY                  : '',
        POLICY_URL                  : process.env.REACT_APP_POLICY_URL              ? process.env.REACT_APP_POLICY_URL              : '',
        AUTHORITY_DOMAIN            : process.env.REACT_APP_AUTHORITY_DOMAIN        ? process.env.REACT_APP_AUTHORITY_DOMAIN        : '',    
        EDIT_PROFILE_POLICY         : process.env.REACT_APP_EDIT_PROFILE_POLICY     ? process.env.REACT_APP_EDIT_PROFILE_POLICY     : '',    
        EDIT_PROFILE_POLICY_URL     : process.env.REACT_APP_EDIT_PROFILE_POLICY_URL ? process.env.REACT_APP_EDIT_PROFILE_POLICY_URL : '',  
        API_URL                     : process.env.REACT_APP_API_URL                 ? process.env.REACT_APP_API_URL                 : '', 
        MSAL_B2CCLIENTID            : process.env.REACT_APP_B2CCLIENTID             ? process.env.REACT_APP_B2CCLIENTID             : '', 
        MSAL_B2CAUTHORITY           : process.env.REACT_APP_B2CAUTHORITY            ? process.env.REACT_APP_B2CAUTHORITY            : '',
        LOGIN_B2CREDIRECT           : process.env.REACT_APP_B2CREDIRECTURI          ? process.env.REACT_APP_B2CREDIRECTURI          : '',
        MSAL_B2CSCOPEPREFIX         : process.env.REACT_APP_B2CSCOPEPREFIX          ? process.env.REACT_APP_B2CSCOPEPREFIX          : '',     
    }
   };

   
const getEnvironment = () => {
    let urlTest = window.location.href;
   
    //uncomment this line to allow localhost config when hosting in iis locally
    //if(urlTest.includes("localhost")) return "DEVELOPMENT";

    if(urlTest.includes("pilotdev")) return "DEVELOPMENT";
    if(urlTest.includes("pilotstaging")) return "STAGING";
    //.env.development file contains localhost overrides and is used by CRA script when it's npm start command run
    //so PRODUCTION means localhost values here when run with start and not as a build command
    return "PRODUCTION"; 
  
  };
  
const Configuration = _Configuration[getEnvironment()];

   export default Configuration;