import React from 'react';
import dayjs from 'dayjs';
import useWeather from './useWeather';
import Loading from './loading';
import { Box, CardContent, Grid, Typography } from '@mui/material';

const Forecast = () => {

  const { forecast, isLoading, isError } = useWeather(
    'forecast',
  );

  if (isLoading || isError) return <Loading />;
  return (
    <>
      {
        forecast &&
        <CardContent>
          <Box style={{ marginBottom: '100px' }}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              p: 1,
              m: 1,
              bgcolor: 'background.paper',
              height: 100,
              borderRadius: 1,
            }}
          >
            <Grid
              container
              spacing={{ xs: 2, sm: 2, lg: 2 }}
              columns={{ xs: 2, sm: 4, md: 4, lg: 8 }}
            >
              <Grid item xs={2} sm={8} md={4} lg={12}>
                <Box sx={{ flexGrow: 1, height: '100%' }}>
                  <Grid
                    sx={{ height: '100%' }}
                    container
                    spacing={{ xs: 0, sm: 0, lg: 0 }}
                    columns={{ xs: 10, sm: 10, md: 10, lg: 10 }} //change column 
                  >
                    {
                      forecast.map((item) => {
                        return (
                          <>
                            <Grid item xs={2} sm={2} md={2}>
                              <Box
                                display="flex"
                                style={{ flexGrow: 1, textAlign: "center" }}
                              >
                                <Box m="auto">
                                  {dayjs(item.dt_txt).format('dddd')}   {dayjs(item.dt_txt).format('MM-DD')}
                                  <Typography variant="subtitle2" gutterBottom component="div">
                                    <span className={item.forecastIcon} style={{ fontSize: '3rem', paddingTop:'15px' }}></span>
                                  </Typography>
                                  <Typography variant="subtitle2" gutterBottom component="div">
                                    {item.min}&deg; / {item.max}&deg;
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                          </>
                        )
                      })
                    }
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      }
    </>
  )
}
export default (Forecast);
