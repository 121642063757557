import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from "react";
import { useLocation } from "react-router-dom";
import { useStoreState, useStoreActions } from 'easy-peasy';
import { makeStyles } from '@mui/styles';
import { TextField } from "../components/TextField";
import { ParseEventStringValue, IsNullOrEmpty } from "../helpers/Common";
import { useHistory } from 'react-router-dom';
import { states, pageName } from '../helpers/Constants';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { GetAccessTokenRequest } from "../components/Account";
import { format } from 'date-fns';
import {
    Card, CardContent, CardHeader, Box, Button, Divider, Grid, FormControlLabel,
    Alert, Autocomplete, AlertTitle, Switch, MenuItem
} from '@mui/material';
import { useMsal } from "@azure/msal-react";
import Configuration from '../helpers/Configuration';
import { AutoCompleteOffForm } from '../components/AutoCompleteOffForm';

/*
Jobrequest-README

See main ReadMe for details on autocomplete off strategies... there's a lot of trial and error to figure out what works for different components
*/

const useStyles = makeStyles(() => ({
    card: {
        width: '99%',
        marginBottom: 10
    },
    cardHeader: {
        background: 'linear-gradient(0deg, #e0e0e0 10%, #cfd8dc 100%)',
        height: '2.5vw',
        "@media (max-width: 1660px)": {
            "& .MuiCardHeader-title": {
                titleTypographyProps: { variant: 'h6' },
                variant: 'h6'
            },
        }
    },
    phone: {
        marginLeft: 15,
        textDecoration: 'none',
        marginTop: '12%',
        marginRight: 20
    },
    input: {
        height: 6
    },
}));

function ClaimInformation(props, ref) {

    const debugLoggingFlag = ((!IsNullOrEmpty(Configuration.ENVIRONMENT)) && Configuration.ENVIRONMENT !== "production");
    const btnNext = useRef();
    const { instance } = useMsal();
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const [zipcode, setZipcode] = useState("");
    const setReinspectionValue = useStoreActions(actions => actions.setReinspectionValue);
    const [typeOfLossValue, setTypeOfLossValue] = useState(null);
    const [isAdjusterAssigned, setIsAdjusterAssigned] = useState(false);
    const [dateOfLossValue, setDateOfLossvalue] = useState(null);
    const [serviceTypeId, setServiceTypeId] = useState(null);
    const [typeOfLossId, setTypeOfLossId] = useState(null);
    const [carrierId, setCarrierId] = useState(null);
    const [serviceTypeValue, setServiceTypeValue] = useState(null);
    const [carrierValue, setCarrierValue] = useState(null);
    const dataRows = useStoreState((state) => state.dashboard);
    const error = useStoreState((state) => state.error);
    const fetchInitialLoad = useStoreActions(actions => actions.fetchInitialLoad);
    const jobRequestValidation = useStoreState((state) => state.jobRequest.currentJobRequestValidation);
    const setCurrentJobRequest = useStoreActions(actions => actions.jobRequest.setCurrentJobRequest);
    const validateClaimInformation = useStoreActions(actions => actions.jobRequest.validateClaimInformation);
    const clearValidateJobRequest = useStoreActions(actions => actions.jobRequest.clearValidateJobRequest);
    const updateAccessToken = useStoreActions(actions => actions.updateAccessToken);
    const updateLog = useStoreActions(actions => actions.updateLog);
    const validateJobRequestActions = useStoreActions(actions => actions.jobRequest.currentJobRequestValidation);
    const getJobRequest = useStoreActions(actions => actions.jobRequest.getJobRequest);
    const updateError = useStoreActions(actions => actions.updateError);
    const setContractorOnClaimValue = useStoreActions(actions => actions.setContractorOnClaimValue);
    const emptyCurrentJobRequest = useStoreActions(actions => actions.jobRequest.emptyCurrentJobRequest);
    const serviceTypeOptions = useStoreState((state) => state.serviceTypes);
    const setPageModeValue = useStoreActions(actions => actions.setPageModeValue);
    const pageModeValue = useStoreState((state) => state.pageModeValue);
    const typeOfLossOptions = useStoreState((state) => state.lossTypes);
    const carrierOptions = useStoreState((state) => state.carriers);
    const defaultCarrier = useStoreState((state) => String(state.defaultCarrier));
    const includeInactiveCarrierOptions = useStoreState((state) => state.includeInactiveCarriers);
    const accessToken = useStoreState((state) => state.accessToken);
    const isServiceTypeLoading = useStoreState((state) => state.isServiceTypeLoading);
    const isTypeOfLossLoading = useStoreState((state) => state.isTypeOfLossLoading);
    const isCarrierLoading = useStoreState((state) => state.isCarrierLoading);
    const jobRequest = useStoreState((state) => state.jobRequest.currentJobRequest);
    const setClaimInformationValidationPass = useStoreActions(actions => actions.setClaimInformationValidationPass);
    const log = useStoreState((state) => state.log);
    const [userEmail, setUserEmail] = useState('');
    const [userLastName, setUserLastName] = useState('');
    const [userFirstName, setUserFirstName] = useState('');
    const [claimNumber, setClaimNumber] = useState('');
    const isAdmin = window.isCarrierPortalPilotAdmin;
    useEffect(() => {
        updateError(null);

        if (debugLoggingFlag) {
            console.log("CONFIG", Configuration);
        }
        else {
            console.log("ENV", Configuration?.ENVIRONMENT || "");
        }

        const accounts = instance.getAllAccounts();
        instance.acquireTokenSilent(GetAccessTokenRequest(accounts)
        ).then(function (accessTokenResponse) {
            let accessToken = accessTokenResponse.accessToken;

            let email = accessTokenResponse.idTokenClaims.emails ? accessTokenResponse.idTokenClaims.emails[0] : accessTokenResponse.idTokenClaims.preferred_username;
            let firstName = null, lastName = null;
            if (!IsNullOrEmpty(accessTokenResponse.idTokenClaims.given_name) && !IsNullOrEmpty(accessTokenResponse.idTokenClaims.family_name)) {
                firstName = accessTokenResponse.idTokenClaims.given_name;
                lastName = accessTokenResponse.idTokenClaims.family_name;
            }
            else {
                try {
                    let name = accessTokenResponse.idTokenClaims.name.split(' ');
                    if (name.length > 0) {
                        firstName = name[0] || '';
                        lastName = name[1] || '';
                    }
                    else {
                        firstName = name;
                    }
                }
                catch {
                }
            }
            setUserEmail(email);
            setUserFirstName(firstName);
            setUserLastName(lastName);
            setCurrentJobRequest({ ...jobRequest, userEmail: email, userFirstName: firstName, userLastName: lastName, accessToken: accessToken });
            updateLog({ userName: `${firstName} ${lastName}`, url: pageName[0], userBrowser: navigator.userAgent, accessToken: accessToken });
            updateAccessToken(accessToken);
            let pageMode = props.mode;
            fetchInitialLoad({ accessToken: accessToken, userEmail: email });
            if (pageMode === "edit-dashboard") {
                setPageModeValue("edit-dashboard");
                getJobRequest({ accessToken: accessToken, id: location?.state?.id })
                    .then(result => {
                        setContractorOnClaimValue(result?.contractorFirstName?.length > 0 ? "yes" : "no");
                        setReinspectionValue(result?.reinspection === "True" ? "yes" : "no");
                        setCarrierId(parseInt(result.carrierId))
                        setCurrentJobRequest({ ...jobRequest, carrierId: result.carrierId });
                        setCarrierValue(includeInactiveCarrierOptions.find((x) => (x.value === result?.carrierId))?.label);
                        setDateOfLossvalue(result?.dateOfLoss);
                        setServiceTypeId(parseInt(result.serviceType))
                        setServiceTypeValue(serviceTypeOptions.find((x) => (x.value === parseInt(result?.serviceType)))?.label);
                        setTypeOfLossId(parseInt(result.typeOfLossId));
                        setTypeOfLossValue(typeOfLossOptions.find((x) => (x.value === parseInt(result?.typeOfLossId)))?.label);
                        result.adjusterFirstName !== '' ? setIsAdjusterAssigned(true) : setIsAdjusterAssigned(false);
                        let contractorOnClaimResult = result?.contractorFirstName?.length > 0 ? "yes" : "no";
                        clearValidateJobRequest();
                        getJobRequest({ accessToken: accessToken, id: location?.state?.id })
                    });
            }
            else if (pageMode === "create") {
                if (props.isBackButtonClicked) {
                    setServiceTypeId(parseInt(jobRequest.serviceType));
                    setCarrierId(parseInt(jobRequest.carrierId));
                    setTypeOfLossId(parseInt(jobRequest.typeOfLossId));
                    setDateOfLossvalue(jobRequest.dateOfLoss);
                    setIsAdjusterAssigned(jobRequest.isAdjusterAssigned);
                    if (jobRequest.serviceTypeId === 1) {
                        setIsAdjusterAssigned(true);
                    }
                    let serviceTypeLabel = serviceTypeOptions.find((x) => (x.value === parseInt(jobRequest.serviceType))).label;
                    setServiceTypeValue(serviceTypeLabel);
                }
                else if (history.action === "PUSH") {
                    emptyCurrentJobRequest();
                    clearPageValues(email, firstName, lastName, defaultCarrier);
                    setCarrierId(parseInt(defaultCarrier));
                    setCarrierValue(carrierOptions.find((x) => (x.value === defaultCarrier))?.label);
                    setCurrentJobRequest({ ...jobRequest, carrierId: defaultCarrier });
                }
                else {
                    if (jobRequest.id || localStorage.getItem('jobRequestId')) {
                        getJobRequest({ accessToken: accessToken, id: jobRequest.id ? jobRequest.id : localStorage.getItem('jobRequestId') })
                            .then(result => {
                                if (result?.appointmentAvailabilityId !== null) {
                                    setPageModeValue("edit-dashboard");
                                }
                                let contractorOnClaimResult = result?.contractorFirstName?.length > 0 ? "yes" : "no";
                                setCurrentJobRequest({
                                    ...jobRequest,
                                    accessToken: accessToken,
                                    contractorOnClaim: contractorOnClaimResult,
                                    id: result?.id,
                                    claimNumber: result?.claimNumber,
                                    riskAddress: result?.riskAddress,
                                    riskCity: result?.riskCity,
                                    riskState: result?.riskState,
                                    riskZip: result?.riskZip,
                                    policyHolderFirstName: result?.policyHolderFirstName,
                                    policyHolderLastName: result?.policyHolderLastName,
                                    policyHolderEmail: result?.policyHolderEmail,
                                    policyHolderPhone: result?.policyHolderPhone,
                                    adjusterFirstName: result?.adjusterFirstName,
                                    adjusterLastName: result?.adjusterLastName,
                                    adjusterEmail: result?.adjusterEmail,
                                    adjusterPhone: result?.adjusterPhone,
                                    serviceType: result?.serviceType,
                                    carrierId: result?.carrierId,
                                    typeOfLossId: result?.typeOfLossId,
                                    dateOfLoss: result?.dateOfLoss,
                                    contractorFirstName: result?.contractorFirstName,
                                    contractorLastName: result?.contractorLastName,
                                    contractorPhone: result?.contractorPhone,
                                    reinspection: result?.reinspection,
                                    typeOfLossOther: result?.typeOfLossOther,
                                    notes: result?.notes,
                                    appointmentAvailabilityId: result?.appointmentAvailabilityId,
                                    userEmail: result?.userEmail,
                                    userFirstName: result?.userFirstName,
                                    userLastName: result?.userLastName,
                                });
                                setContractorOnClaimValue(contractorOnClaimResult);
                                setReinspectionValue(result?.reinspection === "True" ? "yes" : "no");
                                setCarrierId(parseInt(defaultCarrier));
                                setCarrierValue(carrierOptions.find((x) => (x.value === result?.carrierId))?.label);
                                setCurrentJobRequest({ ...jobRequest, carrierId: result?.carrierId });
                                result.adjusterFirstName !== '' ? setIsAdjusterAssigned(true) : setIsAdjusterAssigned(false);
                                setDateOfLossvalue(result?.dateOfLoss);
                                setServiceTypeId(parseInt(result?.serviceType))
                                setServiceTypeValue(serviceTypeOptions.find((x) => (x.value === parseInt(result?.serviceType)))?.label);
                                setTypeOfLossId(parseInt(result.typeOfLossId));
                                setTypeOfLossValue(typeOfLossOptions.find((x) => (x.value === parseInt(result?.typeOfLossId)))?.label);
                                clearValidateJobRequest();
                            });
                    }
                    else {
                        clearPageValues(email, firstName, lastName, localStorage.getItem('carrierId'), accessToken);
                    }
                }
            }
        }).catch(function (error) {
            if (debugLoggingFlag) {
                console.log(error);
            }
        });
    }, [props.mode]);

    useImperativeHandle(ref, () => ({
        blur: () => {
            onValidate();
        },
    }));

    useEffect(() => {
        if (props.mode === "create" && defaultCarrier !== '') {
            setCarrierId(parseInt(defaultCarrier));
            setCarrierValue(carrierOptions.find((x) => (x.value === defaultCarrier))?.label);
            setCurrentJobRequest({ ...jobRequest, carrierId: defaultCarrier, userEmail: userEmail, userFirstName: userFirstName, userLastName: userLastName, accessToken: accessToken });
            localStorage.setItem('carrierId', defaultCarrier);
        } else {
            fetchInitialLoad({ accessToken: accessToken, userEmail: userEmail });
        }
    }, [isCarrierLoading]);

    function clearPageValues(email, firstName, lastName, carrierid, accessToken) {
        setPageModeValue("create");
        setCurrentJobRequest({
            ...jobRequest,
            id: 0,
            claimNumber: "",
            riskAddress: "",
            riskCity: "",
            riskState: "",
            riskZip: "",
            policyHolderFirstName: "",
            policyHolderLastName: "",
            policyHolderEmail: "",
            policyHolderPhone: "",
            adjusterFirstName: "",
            adjusterLastName: "",
            adjusterEmail: "",
            adjusterPhone: "",
            serviceType: "",
            dateOfLoss: "",
            carrierId: carrierid,
            typeOfLossId: "",
            contractorFirstName: "",
            contractorLastName: "",
            contractorPhone: "",
            reinspection: "",
            typeOfLossOther: "",
            notes: "",
            userEmail: email,
            userFirstName: firstName,
            userLastName: lastName,
            appointmentAvailabilityId: null,
            accessToken: accessToken
        });
        setDateOfLossvalue("")
        setContractorOnClaimValue("");
        setReinspectionValue("");
        setCarrierValue("");
        setServiceTypeId("");
        setTypeOfLossId("");
        setTypeOfLossValue("");
        setCarrierId(carrierid);
        clearValidateJobRequest();
    }

    const onValidate = () => {
        var isValidated = validateClaimInformation(jobRequest);
        setClaimInformationValidationPass(isValidated);
    }

    const toggleAdjusterValidation = () => {
        setCurrentJobRequest({ ...jobRequest, isAdjusterAssigned: isAdjusterAssigned });
    }

    useEffect(() => {
        toggleAdjusterValidation();
    }, [isAdjusterAssigned, jobRequest.serviceType])

    const handleAdjusterAssigned = () => {
        setIsAdjusterAssigned(!isAdjusterAssigned);
    };

    function showValidationMessageOnTop(message) {
        updateError({ ...log, message: message, isValid: false });
        window.scrollTo(0, 0);
    }

    const claimNumberOnChange = (event) => {
        let claimNumberValue = ParseEventStringValue(event);
        if (jobRequestValidation.validationHasRun) {
            const isDuplicate = Boolean(dataRows.filter(row => row?.claimnumber === claimNumberValue && (
                row?.status.toLowerCase() === "cancelled" || row?.rescheduledatetime < new Date())).length);
            validateJobRequestActions.validateClaimNumber(claimNumberValue);
            if (isDuplicate) {
                updateError({
                    ...log,
                    message: `Claim Number is already created: ${claimNumberValue}`, isValid: true
                });
            }
            else { updateError(null); }
        }
        setCurrentJobRequest({ ...jobRequest, claimNumber: claimNumberValue });
    };

    const scrollToTop = () => {
        if (!IsNullOrEmpty(jobRequestValidation.result?.serviceType?.message)) {
            showValidationMessageOnTop(jobRequestValidation.result?.serviceType?.message)
        }
        else if (!IsNullOrEmpty(jobRequestValidation.result?.claimNumber?.message)) {
            showValidationMessageOnTop(jobRequestValidation.result?.claimNumber?.message)
        }
        else if (!IsNullOrEmpty(jobRequestValidation.result?.carrierName?.message)) {
            showValidationMessageOnTop(jobRequestValidation.result?.carrierName?.message)
        }
        else if (!IsNullOrEmpty(jobRequestValidation.result?.dateOfLoss?.message)) {
            showValidationMessageOnTop(jobRequestValidation.result?.dateOfLoss?.message)
        }
        else if (!IsNullOrEmpty(jobRequestValidation.result?.typeOfLossId?.message)) {
            showValidationMessageOnTop(jobRequestValidation.result?.typeOfLossId?.message)
        }
        else if (!IsNullOrEmpty(jobRequestValidation.result?.typeOfLossOther?.message)) {
            showValidationMessageOnTop(jobRequestValidation.result?.typeOfLossOther?.message)
        }
        else if (!IsNullOrEmpty(jobRequestValidation.result?.policyRiskAddressStreet?.message)) {
            showValidationMessageOnTop(jobRequestValidation.result?.policyRiskAddressStreet?.message)
        }
        else if (!IsNullOrEmpty(jobRequestValidation.result?.policyRiskAddressCity?.message)) {
            showValidationMessageOnTop(jobRequestValidation.result?.policyRiskAddressCity?.message)
        }
        else if (!IsNullOrEmpty(jobRequestValidation.result?.policyRiskAddressState?.message)) {
            showValidationMessageOnTop(jobRequestValidation.result?.policyRiskAddressState?.message)
        }
        else if (!IsNullOrEmpty(jobRequestValidation.result?.policyRiskAddressZipcode?.message)) {
            showValidationMessageOnTop(jobRequestValidation.result?.policyRiskAddressZipcode?.message)
        }
        else if (!IsNullOrEmpty(jobRequestValidation.result?.policyHolderFirstName?.message)) {
            showValidationMessageOnTop(jobRequestValidation.result?.policyHolderFirstName?.message)
        }
        else if (!IsNullOrEmpty(jobRequestValidation.result?.policyHolderLastName?.message)) {
            showValidationMessageOnTop(jobRequestValidation.result?.policyHolderLastName?.message)
        }
        else if (!IsNullOrEmpty(jobRequestValidation.result?.policyHolderPhone?.message)) {
            showValidationMessageOnTop(jobRequestValidation.result?.policyHolderPhone?.message)
        }
        else if (!IsNullOrEmpty(jobRequestValidation.result?.policyHolderEmail?.message)) {
            showValidationMessageOnTop(jobRequestValidation.result?.policyHolderEmail?.message)
        }
        else if (!IsNullOrEmpty(jobRequestValidation.result?.adjusterFirstName?.message)) {
            showValidationMessageOnTop(jobRequestValidation.result?.adjusterFirstName?.message)
        }
        else if (!IsNullOrEmpty(jobRequestValidation.result?.adjusterLastName?.message)) {
            showValidationMessageOnTop(jobRequestValidation.result?.adjusterLastName?.message)
        }
        else if (!IsNullOrEmpty(jobRequestValidation.result?.adjusterPhone?.message)) {
            showValidationMessageOnTop(jobRequestValidation.result?.adjusterPhone?.message)
        }
        else if (!IsNullOrEmpty(jobRequestValidation.result?.adjusterEmail?.message)) {
            showValidationMessageOnTop(jobRequestValidation.result?.adjusterEmail?.message)
        }
        else if (!IsNullOrEmpty(jobRequestValidation.result?.contractorOnClaim?.message)) {
            showValidationMessageOnTop(jobRequestValidation.result?.contractorOnClaim?.message)
        }
        else if (!IsNullOrEmpty(jobRequestValidation.result?.contractorFirstName?.message)) {
            showValidationMessageOnTop(jobRequestValidation.result?.contractorFirstName?.message)
        }
        else if (!IsNullOrEmpty(jobRequestValidation.result?.contractorLastName?.message)) {
            showValidationMessageOnTop(jobRequestValidation.result?.contractorLastName?.message)
        }
        else if (!IsNullOrEmpty(jobRequestValidation.result?.contractorPhone?.message)) {
            showValidationMessageOnTop(jobRequestValidation.result?.contractorPhone?.message)
        }
        else if (!IsNullOrEmpty(jobRequestValidation.result?.reinspection?.message)) {
            showValidationMessageOnTop(jobRequestValidation.result?.reinspection?.message)
        }
        else if (!IsNullOrEmpty(jobRequestValidation.result?.notes?.message)) {
            showValidationMessageOnTop(jobRequestValidation.result?.notes?.message)
        }
    };

    const serviceTypeOnChange = (event, newValue) => {
        let serviceTypeLabel = serviceTypeOptions.find((x) => (x.value === event.target.value)).label;
        let serviceTypeId = event.target.value;
        setServiceTypeValue(serviceTypeLabel);
        setServiceTypeId(serviceTypeId);
        if (serviceTypeId === 1) {
            setIsAdjusterAssigned(true);
        }
        toggleAdjusterValidation(jobRequest);
        if (jobRequestValidation.validationHasRun) {
            validateJobRequestActions.validateServiceType(serviceTypeId);
        }
        setCurrentJobRequest({ ...jobRequest, serviceType: String(serviceTypeId) });
    };

    const carrierOnChange = (event, newValue) => {
        let carrierLabel = carrierOptions.find((x) => (x.value === event.target.value)).label;
        let carrierId = String(event.target.value);
        setCarrierValue(carrierLabel);
        setCarrierId(carrierId);
        if (jobRequestValidation.validationHasRun) {
            validateJobRequestActions.validateServiceType(carrierId);
        }
        setCurrentJobRequest({ ...jobRequest, carrierId: carrierId });
    };

    const dateOfLossOnChange = (newValue) => {
        let dateValue = newValue;

        if (jobRequestValidation.validationHasRun) {
            validateJobRequestActions.validateDateOfLoss(dateValue);
        }
        try {
            if (!IsNullOrEmpty(dateValue) && new Date(dateValue) !== "Invalid Date")
                setCurrentJobRequest({ ...jobRequest, dateOfLoss: format(dateValue, "yyyy-MM-dd") });
            else
                setCurrentJobRequest({ ...jobRequest, dateOfLoss: dateValue });
        }
        catch
        {
            setCurrentJobRequest({ ...jobRequest, dateOfLoss: dateValue });
        }
        setDateOfLossvalue(dateValue);
    };

    const typeOfLossOnChange = (event) => {
        let typeOfLossLabel = typeOfLossOptions.find((x) => (x.value === event.target.value)).label;
        let typeOfLossId = event.target.value;
        setTypeOfLossValue(typeOfLossLabel);
        setTypeOfLossId(typeOfLossId);

        if (jobRequestValidation.validationHasRun) {
            validateJobRequestActions.validateTypeOfLoss(typeOfLossId);
        }
        setCurrentJobRequest({ ...jobRequest, typeOfLossId: String(typeOfLossId) });
    };

    const typeOfLossOtherOnChange = (event, newValue) => {
        let typeOfLossOtherValue = ParseEventStringValue(event);
        if (jobRequestValidation.validationHasRun) {
            validateJobRequestActions.validateTypeOfLossOther(typeOfLossOtherValue);
        }
        setCurrentJobRequest({ ...jobRequest, typeOfLossOther: typeOfLossOtherValue });
    };

    const policyRiskAddressStreetOnChange = (event, newValue) => {
        let policyRiskAddressStreetValue = ParseEventStringValue(event);
        if (jobRequestValidation.validationHasRun) {
            validateJobRequestActions.validateRiskAddress(policyRiskAddressStreetValue);
        }
        setCurrentJobRequest({ ...jobRequest, riskAddress: policyRiskAddressStreetValue });
    };

    const policyRiskAddressCityOnChange = (event, newValue) => {
        let policyRiskAddressCityValue = ParseEventStringValue(event);
        if (jobRequestValidation.validationHasRun) {
            validateJobRequestActions.validateRiskCity(policyRiskAddressCityValue);
        }
        setCurrentJobRequest({ ...jobRequest, riskCity: policyRiskAddressCityValue });
    };

    const policyRiskAddressStateOnChange = (event, newValue) => {
        let policyRiskAddressStateValue = String(newValue?.label);
        if (jobRequestValidation.validationHasRun) {
            validateJobRequestActions.validateRiskState(policyRiskAddressStateValue);
        }
        setCurrentJobRequest({ ...jobRequest, riskState: policyRiskAddressStateValue });
    };

    const policyRiskAddressZipcodeOnChange = (event, newValue) => {
        let zipcodeValue = ParseEventStringValue(event);
        setZipcode(zipcodeValue.replace(/[^0-9]/, ""));
        if (jobRequestValidation.validationHasRun) {
            validateJobRequestActions.validateRiskZip(zipcodeValue);
        }
        setCurrentJobRequest({ ...jobRequest, riskZip: zipcodeValue });
    };

    const [hasFocus, setFocus] = useState(false);

    return (
        <div style={{ width: '80vw' }}>
            <Box>
                <Grid container spacing={{ xs: 1, sm: 2, md: 3 }} style={{ backgroundColor: 'white' }} >
                    <Grid item xs={12} style={{ marginRight: 20, marginTop: 2 }} >
                        {
                            error && (
                                <Alert severity="error">
                                    <AlertTitle>Error</AlertTitle>
                                    <strong>{error}</strong>
                                </Alert>
                            )}
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        justify="center"
                        style={{ marginLeft: 10, marginRight: 0, marginTop: 2 }}
                    >
                        <Grid item xs={12}>
                            <Card className={classes.card}>
                                <CardHeader
                                    className={classes.cardHeader}
                                    title="Claim Information"
                                    titleTypographyProps={{ variant: 'h6' }}
                                />
                                <Divider />
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                            <AutoCompleteOffForm >
                                                <TextField
                                                    fullWidth
                                                    select
                                                    InputLabelProps={{ shrink: IsNullOrEmpty(serviceTypeId) ? false : true }}
                                                    aria-label="service Type"
                                                    label="Service Type"
                                                    onBlur={scrollToTop}
                                                    displayEmpty
                                                    required
                                                    disabled={(pageModeValue === "edit-dashboard")}
                                                    disablePortal
                                                    options={serviceTypeOptions}
                                                    loading={isServiceTypeLoading}
                                                    onChange={serviceTypeOnChange}
                                                    value={serviceTypeId}
                                                    error={(jobRequestValidation.result?.serviceType?.isValid === false ? (jobRequestValidation.result?.serviceType?.isValid === false ? jobRequestValidation.result?.serviceType?.message : "INVALID") : null)}
                                                    helperText={(jobRequestValidation.result?.serviceType?.isValid === false ? jobRequestValidation.result?.serviceType?.message : "Required")}
                                                >
                                                    {serviceTypeOptions.map((serviceTypeOption) => (
                                                        <MenuItem value={serviceTypeOption.value}>
                                                            {serviceTypeOption.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </AutoCompleteOffForm>
                                        </Grid>
                                        {
                                            (serviceTypeId !== null && serviceTypeId !== '' && serviceTypeValue !== 'Ladder Assist (Steep & High Assist)') &&
                                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                                <AutoCompleteOffForm>
                                                    Is adjuster assigned?
                                                    <FormControlLabel
                                                        style={{ marginLeft: 10 }}
                                                        control={
                                                            <Switch
                                                                disabled={(pageModeValue === "edit-dashboard")}
                                                                checked={isAdjusterAssigned}
                                                                onClick={() => handleAdjusterAssigned()}
                                                            />
                                                        }
                                                        label={isAdjusterAssigned ? "Yes" : "No"}
                                                    />
                                                </AutoCompleteOffForm>
                                            </Grid>
                                        }
                                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                            <div>
                                                <AutoCompleteOffForm autoComplete={"off"}>
                                                    <TextField
                                                        disabled={(pageModeValue === "edit-dashboard")}
                                                        aria-label="claim number"
                                                        fullWidth
                                                        label="Claim Number"
                                                        required
                                                        InputLabelProps={{ shrink: IsNullOrEmpty(jobRequest.claimNumber) ? false : true }}
                                                        value={jobRequest.claimNumber}
                                                        onChange={claimNumberOnChange}
                                                        variant="outlined"
                                                        onBlur={scrollToTop}
                                                        inputProps={{ maxLength: 40, autocomplete: "new-password" }}
                                                        error={(jobRequestValidation.result?.claimNumber?.isValid === false ? (jobRequestValidation.result?.claimNumber?.isValid === false ? jobRequestValidation.result?.claimNumber?.message : "INVALID") : null)}
                                                        helperText={(jobRequestValidation.result?.claimNumber?.isValid === false ? jobRequestValidation.result?.claimNumber?.message : "Required")}
                                                    />
                                                </AutoCompleteOffForm>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                            <AutoCompleteOffForm>
                                                <TextField
                                                    fullWidth
                                                    select
                                                    InputLabelProps={{ shrink: IsNullOrEmpty(carrierId) ? false : true }}
                                                    aria-label="carrier"
                                                    label="Carrier"
                                                    onBlur={scrollToTop}
                                                    displayEmpty
                                                    required
                                                    disabled={(pageModeValue === "edit-dashboard" || !isAdmin)}
                                                    //disabled={(pageModeValue === "edit-dashboard")}
                                                    disablePortal
                                                    options={pageModeValue === "edit-dashboard" ? includeInactiveCarrierOptions : carrierOptions}
                                                    loading={isCarrierLoading}
                                                    onChange={carrierOnChange}
                                                    value={carrierId}
                                                    error={(jobRequestValidation.result?.carrierName?.isValid === false ? (jobRequestValidation.result?.carrierName?.isValid === false ? jobRequestValidation.result?.carrierName?.message : "INVALID") : jobRequestValidation.result?.carrierName?.isValid === false)}
                                                    helperText={(jobRequestValidation.result?.carrierName?.isValid === false ? jobRequestValidation.result?.carrierName?.message : "Required")}
                                                >
                                                    {
                                                        pageModeValue !== "edit-dashboard" ?
                                                            carrierOptions.map((carrier) => (
                                                                <MenuItem value={carrier.value}>
                                                                    {carrier.label}
                                                                </MenuItem>
                                                            ))
                                                            :
                                                            includeInactiveCarrierOptions.map((carrier) => (
                                                                <MenuItem value={carrier.value}>
                                                                    {carrier.label}
                                                                </MenuItem>
                                                            ))
                                                    }
                                                </TextField>
                                            </AutoCompleteOffForm>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    disabled={(pageModeValue === "edit-dashboard")}
                                                    label="Date of Loss"
                                                    onChange={dateOfLossOnChange}
                                                    value={dateOfLossValue ? new Date(dateOfLossValue) : null}
                                                    maxDate={new Date()}
                                                    renderInput={(params) =>
                                                        <TextField {...params}
                                                            aria-label="date of loss"
                                                            required
                                                            fullWidth
                                                            onBlur={scrollToTop}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                autoComplete: "off"
                                                            }}
                                                            error={(jobRequestValidation.result?.dateOfLoss?.isValid === false ? (jobRequestValidation.result?.dateOfLoss?.isValid === false ? jobRequestValidation.result?.dateOfLoss?.message : "INVALID") : null)}
                                                            helperText={(jobRequestValidation.result?.dateOfLoss?.isValid === false ? jobRequestValidation.result?.dateOfLoss?.message : "Required")}
                                                        />
                                                    }
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                            <AutoCompleteOffForm>
                                                <TextField
                                                    fullWidth
                                                    select
                                                    InputLabelProps={{ shrink: IsNullOrEmpty(typeOfLossId) ? false : true }}
                                                    aria-label="Type of Loss"
                                                    label="Type of Loss"
                                                    onBlur={scrollToTop}
                                                    displayEmpty
                                                    required
                                                    disabled={(pageModeValue === "edit-dashboard")}
                                                    disablePortal
                                                    options={typeOfLossOptions}
                                                    loading={isTypeOfLossLoading}
                                                    onChange={typeOfLossOnChange}
                                                    value={typeOfLossId}
                                                    error={(jobRequestValidation.result?.typeOfLossId?.isValid === false ? (jobRequestValidation.result?.typeOfLossId?.isValid === false ? jobRequestValidation.result?.typeOfLossId?.message : "INVALID") : null)}
                                                    helperText={(jobRequestValidation.result?.typeOfLossId?.isValid === false ? jobRequestValidation.result?.typeOfLossId?.message : "Required")}
                                                >
                                                    {typeOfLossOptions.map((typeOfLossOption) => (
                                                        <MenuItem value={typeOfLossOption.value}>
                                                            {typeOfLossOption.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </AutoCompleteOffForm>
                                        </Grid>
                                        {
                                            (typeOfLossValue === 'Other') &&
                                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                                <AutoCompleteOffForm autoComplete={"off"}>
                                                    <TextField
                                                        disabled={(pageModeValue === "edit-dashboard")}
                                                        variant="outlined"
                                                        aria-label="type of loss other"
                                                        label="Type of Loss Other: Description"
                                                        required
                                                        InputLabelProps={{ shrink: IsNullOrEmpty(jobRequest.typeOfLossOther) ? false : true }}
                                                        value={jobRequest.typeOfLossOther}
                                                        onBlur={scrollToTop}
                                                        fullWidth
                                                        inputProps={{ maxLength: 255, autocomplete: "off" }}
                                                        onChange={typeOfLossOtherOnChange}
                                                        error={(jobRequestValidation.result?.typeOfLossOther?.isValid === false ? (jobRequestValidation.result?.typeOfLossOther?.isValid === false ? jobRequestValidation.result?.typeOfLossOther?.message : "INVALID") : null)}
                                                        helperText={(jobRequestValidation.result?.typeOfLossOther?.isValid === false ? jobRequestValidation.result?.typeOfLossOther?.message : "Required")}
                                                    />
                                                </AutoCompleteOffForm>
                                            </Grid>
                                        }
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        justify="center"
                        style={{ marginLeft: 10, marginRight: 0 }}
                    >
                        <Grid item xs={12}>
                            <Card className={classes.card}>
                                <CardHeader
                                    className={classes.cardHeader}
                                    title="Risk Location"
                                    titleTypographyProps={{ variant: 'h6' }}
                                />
                                <Divider />
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item md={6} xs={12}>
                                            <AutoCompleteOffForm autoComplete={"off"}>
                                                <TextField
                                                    disabled={(pageModeValue === "edit-dashboard")}
                                                    aria-label="risk address"
                                                    fullWidth
                                                    label="Address"
                                                    required
                                                    InputLabelProps={{ shrink: IsNullOrEmpty(jobRequest.riskAddress) ? false : true }}
                                                    value={jobRequest.riskAddress}
                                                    onBlur={scrollToTop}
                                                    autoComplete="new-password"
                                                    inputProps={{ maxLength: 200 }}
                                                    onChange={policyRiskAddressStreetOnChange}
                                                    variant="outlined"
                                                    error={(jobRequestValidation.result?.policyRiskAddressStreet?.isValid === false ? (jobRequestValidation.result?.policyRiskAddressStreet?.isValid === false ? jobRequestValidation.result?.policyRiskAddressStreet?.message : "INVALID") : null)}
                                                    helperText={(jobRequestValidation.result?.policyRiskAddressStreet?.isValid === false ? jobRequestValidation.result?.policyRiskAddressStreet?.message : "Required")}
                                                />
                                            </AutoCompleteOffForm>
                                        </Grid>
                                        <Grid item md={2} xs={12}>
                                            <AutoCompleteOffForm autoComplete={"off"}>
                                                <TextField
                                                    disabled={(pageModeValue === "edit-dashboard")}
                                                    aria-label="risk city"
                                                    fullWidth
                                                    label="City"
                                                    required
                                                    InputLabelProps={{ shrink: IsNullOrEmpty(jobRequest.riskCity) ? false : true }}
                                                    value={jobRequest.riskCity}
                                                    onBlur={scrollToTop}
                                                    inputProps={{ maxLength: 100 }}
                                                    autoComplete='new-password'
                                                    onChange={policyRiskAddressCityOnChange}
                                                    variant="outlined"
                                                    error={(jobRequestValidation.result?.policyRiskAddressCity?.isValid === false ? (jobRequestValidation.result?.policyRiskAddressCity?.isValid === false ? jobRequestValidation.result?.policyRiskAddressCity?.message : "INVALID") : null)}
                                                    helperText={(jobRequestValidation.result?.policyRiskAddressCity?.isValid === false ? jobRequestValidation.result?.policyRiskAddressCity?.message : "Required")}
                                                />
                                            </AutoCompleteOffForm>
                                        </Grid>
                                        <Grid item md={2} xs={12}>
                                            <Autocomplete
                                                disabled={(pageModeValue === "edit-dashboard")}
                                                disablePortal
                                                aria-label="risk state"
                                                options={states}
                                                onChange={policyRiskAddressStateOnChange}
                                                disableClearable
                                                value={jobRequest.riskState}
                                                renderInput={(params) =>
                                                    <AutoCompleteOffForm autoComplete={"off"}>
                                                        <TextField {...params}
                                                            required
                                                            onBlur={scrollToTop}
                                                            label="State"
                                                            error={(jobRequestValidation.result?.policyRiskAddressState?.isValid === false ? (jobRequestValidation.result?.policyRiskAddressState?.isValid === false ? jobRequestValidation.result?.policyRiskAddressState?.message : "INVALID") : null)}
                                                            helperText={(jobRequestValidation.result?.policyRiskAddressState?.isValid === false ? jobRequestValidation.result?.policyRiskAddressState?.message : "Required")}
                                                        />
                                                    </AutoCompleteOffForm>
                                                }
                                            />
                                        </Grid>
                                        <Grid item md={2} xs={12}>
                                            <AutoCompleteOffForm autoComplete={"off"}>
                                                <TextField
                                                    disabled={(pageModeValue === "edit-dashboard")}
                                                    aria-label="zipcode"
                                                    fullWidth
                                                    label="Zip"
                                                    value={zipcode}
                                                    required
                                                    InputLabelProps={{ shrink: IsNullOrEmpty(jobRequest.riskZip) ? false : true }}
                                                    value={jobRequest.riskZip}
                                                    onBlur={scrollToTop}
                                                    inputProps={{ maxLength: 5, autocomplete: "new-password" }}
                                                    onChange={policyRiskAddressZipcodeOnChange}
                                                    variant="outlined"
                                                    error={(jobRequestValidation.result?.policyRiskAddressZipcode?.isValid === false ? (jobRequestValidation.result?.policyRiskAddressZipcode?.isValid === false ? jobRequestValidation.result?.policyRiskAddressZipcode?.message : "INVALID") : null)}
                                                    helperText={(jobRequestValidation.result?.policyRiskAddressZipcode?.isValid === false ? jobRequestValidation.result?.policyRiskAddressZipcode?.message : "Required")}
                                                />
                                            </AutoCompleteOffForm>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Grid container justifyContent="flex-end">
                <Button
                    {...props}
                    type="button"
                    variant="contained"
                    disabled={props.activeStep === 0}
                    onClick={props.handleBack}
                    color="primary"
                    sx={{ mr: 1, paddingTop: 1.2 }}
                >
                    Back
                </Button>
                <Button
                    ref={btnNext}
                    {...props}
                    style={{ marginLeft: 10 }}
                    type="button"
                    variant="contained"
                    aria-label="submit"
                    color="primary"
                    sx={{ paddingTop: 1.2 }}
                >
                    Next
                </Button>
            </Grid>
        </div>
    );
}
export default forwardRef(ClaimInformation);
