import React from 'react';
import Box from '@mui/material/Box';

//https://material-ui.com/system/flexbox/
 
export const FlexRow = (props) => {
  const { kind, ...other } = props;
  return <Box display="flex" 
              flexDirection="row" 
              justifyContent={props.justifyContent ||"space-between"} 
              flexWrap={props.flexWrap || "nowrap"} {...other} >
                {props.children}
         </Box>
};