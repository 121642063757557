import React from "react";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AccountIcon from '@mui/icons-material/AccountCircle';
import {ProfileContent} from './ProfileContent';
import { useMsal } from "@azure/msal-react";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem'
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import Fade from '@mui/material/Fade';

const PREFIX = 'MyCard';
const classes = {
  root: `${PREFIX}-root`,
  toolbarLeft: `${PREFIX}-toolbarLeft`,
  appBar: `${PREFIX}-appBar`,
  appBarTitle: `${PREFIX}-appBarTitle`,
  toolbarRight: `${PREFIX}-toolbarRight`,
  menuButton:  `${PREFIX}-menuButton`,
  hide:  `${PREFIX}-hide`,
  menuItem:  `${PREFIX}-menuItem`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
  },
  [`&.${classes.menuItem}`]: {
    display: 'flex',
    color: 'black',
  },
  [`& .${classes.toolbarLeft}`]: {
    display: 'flex',
    alignItems: 'center',    
    alignSelf: 'flex-start',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 1),
    //necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  [`& .${classes.appBar}`]: {
    zIndex: theme.zIndex.drawer + 1,
    color: 'white',
    display: 'flex',  
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding:'1vh',    
    background: '#8a2432', 
    background: 'linear-gradient(0deg, #8a2432 98%, #8a2432 100%)',       
  },
  [`& .${classes.appBarTitle}`]: {
    flexGrow: 1,
    marginLeft: 30,
    marginTop:25
  },
  [`& .${classes.toolbarRight}`]: {
    display: 'flex',
    alignItems: 'center',    
    alignSelf: 'flex-end',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),    
     marginRight: '2%',
     paddingTop: 25,
    //necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  [`& .${classes.menuButton}`]: {
    marginRight: 36,
  },
  [`& .${classes.hide}`]: {
    display: 'none',
  },
}))
 
export function Header() {  
    const theme = useTheme();            
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const { instance } = useMsal();        
    const isAdmin = window.isCarrierPortalPilotAdmin;
    const handleClick = (event) => {    
        setAnchorEl(event.currentTarget);
    };    
    const handleClose = async (ev) => {
      setAnchorEl(null);          
      if(ev.target.innerText === 'Logout') { 
        isAdmin? window.location.href = "/admin/signout":  window.location.href = "/signout";
      }
    };      
return (
    <>  
     <Root className={classes.root}>
    <AppBar 
    position="fixed"
    className={classes.appBar}
    >   
    <Toolbar className={classes.toolbarLeft}>
      <img style={{ display: "block",
                  maxWidth:215,
                  maxHeight:60,
                  width: "auto",
                  height: "auto"
                  }} src="/pilot_logo_white.png" 
                  alt=""></img>
      <Typography 
      variant="h6"
      className={classes.appBarTitle}
      >
      Scheduling Portal
      </Typography>
    </Toolbar>
    
    <Toolbar className={classes.toolbarRight}>      
      <IconButton 
        color="inherit"
        aria-label="account menu"
        aria-controls="account-menu"
        aria-haspopup="true"
        onClick={handleClick}
        edge="end" 
      >
      <AccountIcon />
      </IconButton>
      <ProfileContent /> 
    </Toolbar>    
    </AppBar>    
    <Menu
        id="long-menu"
        aria-labelledby="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}       
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        TransitionComponent={Fade}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >           
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
    </Menu>    
    </Root>
    </>
  )
};

