import React from 'react';
import IconButton from '@mui/material/IconButton';
import FileDownload from '@mui/icons-material/FileDownload';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Typography } from '@mui/material';

export default function DownloadFile({ fileName, id, contentType }) {
  const fetchBlob = useStoreActions(actions => actions.fetchBlob);
  const updateError = useStoreActions(actions => actions.updateError);
  const blob = useStoreState((state) => state.blob);
  const log = useStoreState((state) => state.log);
  const accessToken = useStoreState((state) => state.accessToken);

  const downloadBlob = () => {
    if (blob.fileContents !== undefined && blob.fileContents.length > 0) {
      const link = document.createElement('a');
      link.href = `data: ${blob.contentType};base64, ${blob.fileContents}`;
      link.setAttribute(
        'download',
        `${blob.fileName}`,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    }
  }

  const handleClick = () => {
    const res = fetchBlob({ attachmentId: id, accessToken: accessToken })
      .then(res => {
        downloadBlob();
      })
      .catch(err => {
        updateError({ ...log, message: res.message, isValid: false });
        return new Error(err.message);
      })
  };

  return (
    <IconButton
      color="inherit"
      aria-label="download"
      onClick={handleClick}
    >
      <FileDownload />
      <Typography style={{ textAlign: 'left' }}>
        {fileName}
      </Typography>
    </IconButton>
  );
}
