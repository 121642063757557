import React from 'react';
//import { makeStyles } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
const PREFIX = 'MyCard';
const classes = {
  root: `${PREFIX}-root`, 
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));

export default function Wait() {
  //const classes = useStyles();

  return (
    <Root>
      <CircularProgress /> 
    </Root>
  );
}