import { createStore, action, thunk } from 'easy-peasy';
import axios from 'axios';
import { IsNullOrEmpty, IsValidDateTime, DigitCount } from "./helpers/Common";
import { matchEmailPattern } from "./helpers/Constants";
import Configuration from './helpers/Configuration';
import { format } from 'date-fns';

import { debug } from 'easy-peasy';

let formData = new FormData();

const validateEmail = (email) => matchEmailPattern.test(email);

const model = {
  error: [],
  sidebarSelectedIndex: 0,
  timeSlots: [],
  isServiceTypeLoading: true,
  isTypeOfLossLoading: true,
  isCarrierLoading: true,
  claimInformationValidationPass: false,
  contactDetailsValidationPass: false,
  otherInformationValidationPass: false,
  scheduleValidationPass: false,
  isContractorOnClaim: false,
  reinspectionValue: false,
  navbarIndex: 0,
  pageModeValue: '',
  jobRequest: {
    currentJobRequest: {
      id: 0,
      claimNumber: "",
      riskAddress: "",
      riskCity: "",
      riskState: "",
      riskZip: "",
      policyHolderFirstName: "",
      policyHolderLastName: "",
      policyHolderEmail: "",
      policyHolderPhone: "",
      adjusterFirstName: "",
      adjusterLastName: "",
      adjusterEmail: "",
      adjusterPhone: "",
      serviceType: "",
      carrierId: "",
      dateOfLoss: "",
      typeOfLossId: "",
      contractorFirstName: "",
      contractorLastName: "",
      contractorPhone: "",
      reinspection: "",
      typeOfLossOther: "",
      notes: "",
      userEmail: "",
      userFirstName: "",
      userLastName: "",
      appointmentAvailabilityId: null,
      accessToken: ""
    },

    currentJobRequestValidation: {
      result: {},

      setValidateFlag: action((state, flagValue) => {
        state.validationHasRun = flagValue;
      }),

      setValidationResultIsValid: action((state, isValid) => {
        state.result.isValid = isValid;
      }),

      validateServiceType: action((state, serviceType) => {
        let message = "";

        //required rule
        if (IsNullOrEmpty(serviceType)) {
          state.result.isValid = false;
          message = "Service Type is Required!";
        }

        state.result.serviceType = {
          isValid: state.result.isValid,
          message: message
        };
      }),

      validateClaimNumber: action((state, claimNumber) => {
        let message = "";

        //required rule
        if (IsNullOrEmpty(claimNumber)) {
          state.result.isValid = false;
          message = "Claim Number is Required!";
        }

        state.result.claimNumber = {
          isValid: state.result.isValid,
          message: message
        };
      }),

      validateCarrier: action((state, id) => {
        let message = "", isValid = true;

        //required rule
        if (IsNullOrEmpty(IDBCursor)) {
          isValid = false;
          state.result.isValid = false;
          message = "Carrier is Required!";
        }

        state.result.carrierId = {
          isValid: isValid,
          message: message
        };
      }),

      validateAdjusterEmail: action((state, adjusterEmail) => {
        let message = "";

        //required rule
        if (IsNullOrEmpty(adjusterEmail)) {
          state.result.isValid = false;
          message = "Adjuster Email is Required!";
        }
        else if (!validateEmail(adjusterEmail)) {
          state.result.isValid = false;
          message = "Invalid Email Address!";
        }

        state.result.adjusterEmail = {
          isValid: state.result.isValid,
          message: message
        };
      }),

      validateTypeOfLoss: action((state, typeOfLossId) => {
        let message = "";

        //required rule
        if (IsNullOrEmpty(typeOfLossId)) {
          state.result.isValid = false;
          message = "Type of Loss is Required!";
        }

        state.result.typeOfLossId = {
          isValid: state.result.isValid,
          message: message
        };
      }),

      validateTypeOfLossOther: action((state, typeOfLossOther) => {
        let message = "";

        //required rule
        if (IsNullOrEmpty(typeOfLossOther)) {
          state.result.isValid = false;
          message = "Description is required when 'Other' is selected as Loss Type!";
        }

        state.result.typeOfLossOther = {
          isValid: state.result.isValid,
          message: message
        };
      }),

      validateDateOfLoss: action((state, dateOfLoss) => {
        let message = "";

        //required rule
        if (IsNullOrEmpty(dateOfLoss)) {
          state.result.isValid = false;
          message = "Loss date is Required!";
        }
        else if (!IsValidDateTime(dateOfLoss)) {
          state.result.isValid = false;
          message = "Invalid Loss date";
        }

        state.result.dateOfLoss = {
          isValid: state.result.isValid,
          message: message
        };
      }),

      validateRiskAddress: action((state, riskAddress) => {
        let message = "";

        //required rule
        if (IsNullOrEmpty(riskAddress)) {
          state.result.isValid = false;
          message = "Address is Required!";
        }

        state.result.policyRiskAddressStreet = {
          isValid: state.result.isValid,
          message: message
        };
      }),

      validateRiskCity: action((state, riskCity) => {
        let message = "";

        //required rule
        if (IsNullOrEmpty(riskCity)) {
          state.result.isValid = false;
          message = "City for the risk address is Required!";
        }

        state.result.policyRiskAddressCity = {
          isValid: state.result.isValid,
          message: message
        };
      }),

      validateRiskState: action((state, riskState) => {
        let message = "";

        //required rule
        if (IsNullOrEmpty(riskState)) {
          state.result.isValid = false;
          message = "State for risk address is Required!";
        }

        state.result.policyRiskAddressState = {
          isValid: state.result.isValid,
          message: message
        };
      }),

      validateRiskZip: action((state, riskZip) => {
        let message = "";

        //required rule
        if (IsNullOrEmpty(riskZip)) {
          state.result.isValid = false;
          message = "Zipcode for risk address is Required!";
        }

        if (riskZip.length < 5) {
          state.result.isValid = false;
          message = "Invalid Zipcode";
        }

        state.result.policyRiskAddressZipcode = {
          isValid: state.result.isValid,
          message: message
        };
      }),

      validatePolicyHolderFirstName: action((state, policyHolderFirstName) => {
        let message = "";

        //required rule
        if (IsNullOrEmpty(policyHolderFirstName)) {
          state.result.isValid = false;
          message = "Policy holder first name is Required!";
        }

        state.result.policyHolderFirstName = {
          isValid: state.result.isValid,
          message: message
        };
      }),

      validatePolicyHolderLastName: action((state, policyHolderLastName) => {
        let message = "";

        //required rule
        if (IsNullOrEmpty(policyHolderLastName)) {
          state.result.isValid = false;
          message = "Policy holder last name is Required!";
        }

        state.result.policyHolderLastName = {
          isValid: state.result.isValid,
          message: message
        };
      }),

      validatePolicyHolderPhone: action((state, policyHolderPhone) => {
        let message = "";

        //required rule
        if (IsNullOrEmpty(policyHolderPhone)) {
          state.result.isValid = false;
          message = "Policy holder phone is Required!";
        }
        else if (DigitCount(policyHolderPhone) < 10) {
          state.result.isValid = false;
          message = "10 Digits!";
        }

        state.result.policyHolderPhone = {
          isValid: state.result.isValid,
          message: message
        };
      }),

      validateAdjusterFirstName: action((state, adjusterFirstName) => {
        let message = "";

        //required rule
        if (IsNullOrEmpty(adjusterFirstName)) {
          state.result.isValid = false;
          message = "Adjuster first name is Required!";
        }

        state.result.adjusterFirstName = {
          isValid: state.result.isValid,
          message: message
        };
      }),

      clearAdjusterValidation: action((state, adjusterFirstName) => {
        state.result.adjusterFirstName = {
          isValid: true,
          message: ""
        };
        state.result.adjusterLastName = {
          isValid: true,
          message: ""
        };
        state.result.adjusterPhone = {
          isValid: true,
          message: ""
        };
        state.result.adjusterEmail = {
          isValid: true,
          message: ""
        };
      }),

      validateAdjusterLastName: action((state, adjusterLastName) => {
        let message = "";

        //required rule
        if (IsNullOrEmpty(adjusterLastName)) {
          state.result.isValid = false;
          message = "Adjuster last name is Required!";
        }

        state.result.adjusterLastName = {
          isValid: state.result.isValid,
          message: message
        };
      }),

      validateAdjusterPhone: action((state, adjusterPhone) => {
        let message = "";

        //required rule
        if (IsNullOrEmpty(adjusterPhone)) {
          state.result.isValid = false;
          message = "Adjuster phone is Required!";
        }
        else if (DigitCount(adjusterPhone) < 10) {
          state.result.isValid = false;
          message = "10 Digits!";
        }

        state.result.adjusterPhone = {
          isValid: state.result.isValid,
          message: message
        };
      }),

      validateContractorFirstName: action((state, contractorFirstName) => {
        let message = "";

        //required rule
        if (IsNullOrEmpty(contractorFirstName)) {
          state.result.isValid = false;
          message = "Contractor first name is Required!";
        }

        state.result.contractorFirstName = {
          isValid: state.result.isValid,
          message: message
        };
      }),

      validateContractorLastName: action((state, contractorLastName) => {
        let message = "";

        //required rule
        if (IsNullOrEmpty(contractorLastName)) {
          state.result.isValid = false;
          message = "Contractor last name is Required!";
        }

        state.result.contractorLastName = {
          isValid: state.result.isValid,
          message: message
        };
      }),

      validateContractorPhone: action((state, contractorPhone) => {
        let message = "";

        //required rule
        if (IsNullOrEmpty(contractorPhone)) {
          state.result.isValid = false;
          message = "Contractor phone is Required!";
        }
        else if (DigitCount(contractorPhone) < 10) {
          state.result.isValid = false;
          message = "10 Digits!";
        }

        state.result.contractorPhone = {
          isValid: state.result.isValid,
          message: message
        };
      }),

      validateContractorOnClaim: action((state, contractorOnClaim) => {
        let message = "";

        //required rule
        if (IsNullOrEmpty(contractorOnClaim)) {
          state.result.isValid = false;
          message = "Contractor is Required!";
        }

        state.result.contractorOnClaim = {
          isValid: state.result.isValid,
          message: message
        };
      }),

      validateReinspection: action((state, reinspection) => {
        let message = "";

        //required rule
        if (IsNullOrEmpty(reinspection)) {
          state.result.isValid = false;
          message = "Reinspection is Required!";
        }

        state.result.reinspection = {
          isValid: state.result.isValid,
          message: message
        };
      }),

      clearValidation: action((state) => {
        state.result.serviceType = {
          isValid: true,
          message: ""
        };
        state.result.claimNumber = {
          isValid: true,
          message: ""
        };
        state.result.carrierId = {
          isValid: true,
          message: ""
        };
        state.result.dateOfLoss = {
          isValid: true,
          message: ""
        };
        state.result.lossTypes = {
          isValid: true,
          message: ""
        };
        state.result.typeOfLossId = {
          isValid: true,
          message: ""
        };
        state.result.typeOfLossOther = {
          isValid: true,
          message: ""
        };

        state.result.policyRiskAddressStreet = {
          isValid: true,
          message: ""
        };
        state.result.policyRiskAddressCity = {
          isValid: true,
          message: ""
        };
        state.result.policyRiskAddressState = {
          isValid: true,
          message: ""
        };
        state.result.policyRiskAddressZipcode = {
          isValid: true,
          message: ""
        };

        state.result.policyHolderFirstName = {
          isValid: true,
          message: ""
        };
        state.result.policyHolderLastName = {
          isValid: true,
          message: ""
        };
        state.result.policyHolderPhone = {
          isValid: true,
          message: ""
        };

        state.result.adjusterFirstName = {
          isValid: true,
          message: ""
        };
        state.result.adjusterLastName = {
          isValid: true,
          message: ""
        };
        state.result.adjusterPhone = {
          isValid: true,
          message: ""
        };
        state.result.adjusterEmail = {
          isValid: true,
          message: ""
        };

        state.result.contractorOnClaim = {
          isValid: true,
          message: ""
        };
        state.result.contractorFirstName = {
          isValid: true,
          message: ""
        };
        state.result.contractorLastName = {
          isValid: true,
          message: ""
        };
        state.result.contractorPhone = {
          isValid: true,
          message: ""
        };

        state.result.reinspection = {
          isValid: true,
          message: ""
        };
      }),
    },

    clearValidateJobRequest: thunk((actions, payload, { getState }) => {
      actions.currentJobRequestValidation.setValidationResultIsValid(true);
      actions.currentJobRequestValidation.setValidateFlag(true);
      actions.currentJobRequestValidation.clearValidation();
    }),

    validateJobRequest: thunk((actions, payload, { getState }) => {
      actions.currentJobRequestValidation.setValidationResultIsValid(true);
      actions.currentJobRequestValidation.setValidateFlag(true);
      actions.currentJobRequestValidation.validateCarrier(payload.carrierId);
      actions.currentJobRequestValidation.validateClaimNumber(payload.claimNumber);
      actions.currentJobRequestValidation.validateServiceType(payload.serviceType);
      actions.currentJobRequestValidation.validateDateOfLoss(payload.dateOfLoss);
      actions.currentJobRequestValidation.validateTypeOfLoss(payload.typeOfLossId);
      if (payload.typeOfLossId === 'Other')
        actions.currentJobRequestValidation.validateTypeOfLossOther(payload.typeOfLossOther);
      actions.currentJobRequestValidation.validateRiskAddress(payload.riskAddress);
      actions.currentJobRequestValidation.validateRiskCity(payload.riskCity);
      actions.currentJobRequestValidation.validateRiskState(payload.riskState);
      actions.currentJobRequestValidation.validateRiskZip(payload.riskZip);
      actions.currentJobRequestValidation.validatePolicyHolderFirstName(payload.policyHolderFirstName);
      actions.currentJobRequestValidation.validatePolicyHolderLastName(payload.policyHolderLastName);
      actions.currentJobRequestValidation.validatePolicyHolderPhone(payload.policyHolderPhone);
      if (payload.serviceType === "1" || payload.isAdjusterAssigned) {
        actions.currentJobRequestValidation.validateAdjusterFirstName(payload.adjusterFirstName);
        actions.currentJobRequestValidation.validateAdjusterLastName(payload.adjusterLastName);
        actions.currentJobRequestValidation.validateAdjusterPhone(payload.adjusterPhone);
        actions.currentJobRequestValidation.validateAdjusterEmail(payload.adjusterEmail);
      }
      else {
        actions.currentJobRequestValidation.clearAdjusterValidation(payload.adjusterFirstName);
      }
      actions.currentJobRequestValidation.validateContractorOnClaim(payload.contractorOnClaim);
      if (payload.contractorOnClaim === 'yes') {
        actions.currentJobRequestValidation.validateContractorFirstName(payload.contractorFirstName);
        actions.currentJobRequestValidation.validateContractorLastName(payload.contractorLastName);
        actions.currentJobRequestValidation.validateContractorPhone(payload.contractorPhone);
      }
      actions.currentJobRequestValidation.validateReinspection(payload.reinspection);
      return getState().currentJobRequestValidation.result.isValid;
    }),

    validateClaimInformation: thunk((actions, payload, { getState }) => {
      actions.currentJobRequestValidation.setValidationResultIsValid(true);
      actions.currentJobRequestValidation.setValidateFlag(true);
      actions.currentJobRequestValidation.validateCarrier(payload.carrierId);
      actions.currentJobRequestValidation.validateClaimNumber(payload.claimNumber);
      actions.currentJobRequestValidation.validateServiceType(payload.serviceType);
      actions.currentJobRequestValidation.validateDateOfLoss(payload.dateOfLoss);
      actions.currentJobRequestValidation.validateTypeOfLoss(payload.typeOfLossId);
      if (payload.typeOfLossId === 'Other')
        actions.currentJobRequestValidation.validateTypeOfLossOther(payload.typeOfLossOther);
      actions.currentJobRequestValidation.validateRiskAddress(payload.riskAddress);
      actions.currentJobRequestValidation.validateRiskCity(payload.riskCity);
      actions.currentJobRequestValidation.validateRiskState(payload.riskState);
      actions.currentJobRequestValidation.validateRiskZip(payload.riskZip);
      return getState().currentJobRequestValidation.result.isValid;
    }),

    validateContactDetails: thunk((actions, payload, { getState }) => {
      actions.currentJobRequestValidation.setValidationResultIsValid(true);
      actions.currentJobRequestValidation.setValidateFlag(true);
      actions.currentJobRequestValidation.validatePolicyHolderFirstName(payload.policyHolderFirstName);
      actions.currentJobRequestValidation.validatePolicyHolderLastName(payload.policyHolderLastName);
      actions.currentJobRequestValidation.validatePolicyHolderPhone(payload.policyHolderPhone);
      if (payload.serviceType === "1" || payload.isAdjusterAssigned) {
        actions.currentJobRequestValidation.validateAdjusterFirstName(payload.adjusterFirstName);
        actions.currentJobRequestValidation.validateAdjusterLastName(payload.adjusterLastName);
        actions.currentJobRequestValidation.validateAdjusterPhone(payload.adjusterPhone);
        actions.currentJobRequestValidation.validateAdjusterEmail(payload.adjusterEmail);
      }
      else {
        actions.currentJobRequestValidation.clearAdjusterValidation(payload.adjusterFirstName);
      }
      return getState().currentJobRequestValidation.result.isValid;
    }),

    validateOtherInformation: thunk((actions, payload, { getState }) => {
      actions.currentJobRequestValidation.setValidationResultIsValid(true);
      actions.currentJobRequestValidation.setValidateFlag(true);
      if (payload.contractorOnClaim === 'yes') {
        actions.currentJobRequestValidation.validateContractorFirstName(payload.contractorFirstName);
        actions.currentJobRequestValidation.validateContractorLastName(payload.contractorLastName);
        actions.currentJobRequestValidation.validateContractorPhone(payload.contractorPhone);
      }
      actions.currentJobRequestValidation.validateReinspection(payload.reinspection);
      return getState().currentJobRequestValidation.result.isValid;
    }),

    validateAdjusterInformation: thunk((actions, payload, { getState }) => {
      if (payload.serviceType === "1" || getState().currentJobRequest.isAdjusterAssigned) {
        actions.currentJobRequestValidation.validateAdjusterFirstName(payload.adjusterFirstName);
        actions.currentJobRequestValidation.validateAdjusterLastName(payload.adjusterLastName);
        actions.currentJobRequestValidation.validateAdjusterPhone(payload.adjusterPhone);
        actions.currentJobRequestValidation.validateAdjusterEmail(payload.adjusterEmail);
      }
      else {
        if (getState().currentJobRequest.isAdjusterAssigned == false) {
          actions.currentJobRequestValidation.clearAdjusterValidation(payload.adjusterFirstName);
        }
      }
      return getState().currentJobRequestValidation.result.isValid;
    }),

    setCurrentJobRequest: action((state, payload) => {
      state.currentJobRequest = payload;
    }),

    emptyCurrentJobRequest: action((state, payload) => {
      const emptyJobRequest = {
        id: 0,
        claimNumber: "",
        riskAddress: "",
        riskCity: "",
        riskState: "",
        riskZip: "",
        policyHolderFirstName: "",
        policyHolderLastName: "",
        policyHolderEmail: "",
        policyHolderPhone: "",
        adjusterFirstName: "",
        adjusterLastName: "",
        adjusterEmail: "",
        adjusterPhone: "",
        serviceType: "",
        carrierId: "",
        carrierId: "",
        dateOfLoss: "",
        typeOfLossId: "",
        contractorFirstName: "",
        contractorLastName: "",
        contractorPhone: "",
        reinspection: "",
        typeOfLossOther: "",
        notes: "",
        appointmentAvailabilityId: null
      }
      state.currentJobRequest = emptyJobRequest;
    }),

    updateJobRequestId: action((state, payload) => {
      state.currentJobRequest.id = payload;
    }),

    createJobRequest: thunk(async (actions, payload, { getState }) => {
      try {
        const { accessToken, ...payloadRest } = payload;
        const response = await axios.post(
          `${Configuration.API_URL}/serviceappointment`,
          payloadRest,
          { headers: { "Authorization": `Bearer ${accessToken}` } }
        )
          .then(res => {
            actions.setCurrentJobRequest(payload);
            if (res.data === "Zip Code doesn't exist") {
              return res.data;
            }
            else {
              actions.updateJobRequestId(res.data);
            }
            return res;
          })
          .catch((error) => {
            if (error.response) {
              return new Error(error.response.data.title || error.response.data);
            }
            else if (error.request) {
              return new Error(error.request);
            }
            else {
              return new Error(error.message);
            }
          });
        return response;
      }
      catch (error) {
        console.log(error);
        actions.setError(error.message);
        return new Error(error.message);
      }
    }),

    updateJobRequest: thunk(async (actions, payload) => {
      try {
        const { accessToken, ...payloadSeperateAccessToken } = payload;
        const { isAdjusterAssigned, ...payloadSeperateisAdjusterAssigned } = payloadSeperateAccessToken;
        const { contractorOnClaim, ...payloadRest } = payloadSeperateisAdjusterAssigned;
        const response = await axios.put(
          `${Configuration.API_URL}/serviceappointment/${payloadRest.id}?`,
          payloadRest,
          { headers: { "Authorization": `Bearer ${accessToken}` } }
        )
          .then(res => {
            actions.setCurrentJobRequest(payload);
            if (res.data === "Zip Code doesn't exist") {
              return res.data;
            }
            else {
              actions.updateJobRequestId(res.data);
            }
            return res;
          })
          .catch((error) => {
            if (error.response) {
              return new Error(error.response.data.title || error.response.data);
            }
            else if (error.request) {
              return new Error(error.request);
            }
            else {
              return new Error(error.message);
            }
          });
        return response;
      }
      catch (error) {
        console.log(error);
        actions.setError(error.message);
        return new Error(error.message);
      }
    }),

    updateJobNotes: thunk(async (actions, payload) => {
      try {
        const { accessToken, ...payloadRest } = payload;

        const response = await axios.post(`${Configuration.API_URL}/serviceappointment/updatenotes?`,
          payloadRest,
          { headers: { "Authorization": `Bearer ${accessToken}` } }
        )
          .then(res => {
            return res;
          })
          .catch((error) => {
            if (error.response) {
              return new Error(error.response.data.title || error.response.data);
            }
            else if (error.request) {
              return new Error(error.request);
            }
            else {
              return new Error(error.message);
            }
          });
        return response;
      }
      catch (error) {
        console.log(error);
        actions.setError(error.message);
        return new Error(error.message);
      }
    }),

    getReinspection: thunk(async (actions, payload, { getState }) => {
      try {
        const { accessToken, ...payloadRest } = payload;
        var x = `${Configuration.API_URL}/serviceappointment?id=${payloadRest.id}`;

        const response = await axios.get(`${Configuration.API_URL}/serviceappointment?id=${payloadRest.id}`,
          {
            headers: { "Authorization": `Bearer ${accessToken}` }
          }
        )
          .then(res => {
            if (!IsNullOrEmpty(res.data)) {
              let existedJobRequest = {
                id: 0,
                claimNumber: "",
                riskAddress: "",
                riskCity: "",
                riskState: "",
                riskZip: "",
                policyHolderFirstName: "",
                policyHolderLastName: "",
                policyHolderEmail: "",
                policyHolderPhone: "",
                adjusterFirstName: "",
                adjusterLastName: "",
                adjusterEmail: "",
                adjusterPhone: "",
                serviceType: "",
                carrierId: "",
                dateOfLoss: "",
                typeOfLossId: "",
                contractorFirstName: "",
                contractorLastName: "",
                contractorPhone: "",
                reinspection: "",
                typeOfLossOther: "",
                notes: "",
                appointmentAvailabilityId: null
              }
              let response = res.data;
              existedJobRequest.id = response?.id;
              existedJobRequest.claimNumber = response?.claimNumber;
              existedJobRequest.riskAddress = response?.riskAddress;
              existedJobRequest.riskCity = response?.riskCity;
              existedJobRequest.riskState = response?.riskState.toUpperCase();//response?.riskState.toUpperCase();
              existedJobRequest.riskZip = response?.riskZip;
              existedJobRequest.policyHolderFirstName = response?.policyHolderFirstName;
              existedJobRequest.policyHolderLastName = response?.policyHolderLastName;
              existedJobRequest.policyHolderEmail = response?.policyHolderEmail;
              existedJobRequest.policyHolderPhone = response?.policyHolderPhone;
              existedJobRequest.adjusterFirstName = response?.adjusterFirstName;
              existedJobRequest.adjusterLastName = response?.adjusterLastName;
              existedJobRequest.adjusterEmail = response?.adjusterEmail;
              existedJobRequest.adjusterPhone = response?.adjusterPhone;
              existedJobRequest.serviceType = response?.serviceType;
              existedJobRequest.carrierId = response?.carrierId;
              existedJobRequest.dateOfLoss = response?.dateOfLoss;
              existedJobRequest.typeOfLossId = response?.typeOfLossId;
              existedJobRequest.contractorFirstName = response?.contractorFirstName;
              existedJobRequest.contractorLastName = response?.contractorLastName;
              existedJobRequest.contractorPhone = response?.contractorPhone;
              existedJobRequest.reinspection = response?.reinspection;
              existedJobRequest.typeOfLossOther = response?.typeOfLossOther;
              existedJobRequest.notes = response?.notes;
              existedJobRequest.appointmentAvailabilityId = response?.appointmentAvailabilityId;
              actions.setCurrentJobRequest(existedJobRequest);
            }

            return getState().currentJobRequest.reinspection;
          })
          .catch((error) => {
            if (error.response) {
              return new Error(error.response.data.title || error.response.data);
            }
            else if (error.request) {
              return new Error(error.request);
            }
            else {
              return new Error(error.message);
            }
          });

        return response;
      }
      catch (error) {
        console.log(error);
        actions.setError(error.message);
        return new Error(error.message);
      }
    }),

    getJobRequest: thunk(async (actions, payload, { getState }) => {
      try {
        const { accessToken, ...payloadRest } = payload;
        const response = await axios.get(`${Configuration.API_URL}/serviceappointment?id=${payloadRest.id}`,
          {
            headers: { "Authorization": `Bearer ${accessToken}` }
          }
        )
          .then(res => {
            if (!IsNullOrEmpty(res.data)) {
              let existedJobRequest = {
                id: 0,
                claimNumber: "",
                riskAddress: "",
                riskCity: "",
                riskState: "",
                riskZip: "",
                policyHolderFirstName: "",
                policyHolderLastName: "",
                policyHolderEmail: "",
                policyHolderPhone: "",
                adjusterFirstName: "",
                adjusterLastName: "",
                adjusterEmail: "",
                adjusterPhone: "",
                serviceType: "",
                carrierId: "",
                dateOfLoss: "",
                typeOfLossId: "",
                contractorFirstName: "",
                contractorLastName: "",
                contractorPhone: "",
                reinspection: "",
                typeOfLossOther: "",
                notes: "",
                appointmentAvailabilityId: null,
                userEmail: "",
                userFirstName: "",
                userLastName: null,
              }
              let response = res.data;
              existedJobRequest.id = response?.id;
              existedJobRequest.claimNumber = response?.claimNumber;
              existedJobRequest.riskAddress = response?.riskAddress;
              existedJobRequest.riskCity = response?.riskCity;
              existedJobRequest.riskState = response?.riskState;//response?.riskState.toUpperCase();
              existedJobRequest.riskZip = response?.riskZip;
              existedJobRequest.policyHolderFirstName = response?.policyHolderFirstName;
              existedJobRequest.policyHolderLastName = response?.policyHolderLastName;
              existedJobRequest.policyHolderEmail = response?.policyHolderEmail;
              existedJobRequest.policyHolderPhone = response?.policyHolderPhone;
              existedJobRequest.adjusterFirstName = response?.adjusterFirstName;
              existedJobRequest.adjusterLastName = response?.adjusterLastName;
              existedJobRequest.adjusterEmail = response?.adjusterEmail;
              existedJobRequest.adjusterPhone = response?.adjusterPhone;
              existedJobRequest.serviceType = response?.serviceType;
              existedJobRequest.carrierId = response?.carrierId;
              existedJobRequest.dateOfLoss = format(new Date(response?.dateOfLoss), 'yyyy-MM-dd');
              existedJobRequest.typeOfLossId = response?.typeOfLossId;
              existedJobRequest.contractorFirstName = response?.contractorFirstName;
              existedJobRequest.contractorLastName = response?.contractorLastName;
              existedJobRequest.contractorPhone = response?.contractorPhone;
              existedJobRequest.reinspection = response?.reinspection;
              existedJobRequest.typeOfLossOther = response?.typeOfLossOther;
              existedJobRequest.notes = response?.notes;
              existedJobRequest.appointmentAvailabilityId = response?.appointmentAvailabilityId;
              existedJobRequest.userEmail = response?.userEmail;
              existedJobRequest.userFirstName = response?.userFirstName;
              existedJobRequest.userLastName = response?.userLastName;
              actions.setCurrentJobRequest(existedJobRequest);
            }

            return getState().currentJobRequest;
          })
          .catch((error) => {
            if (error.response) {
              return new Error(error.response.data.title || error.response.data);
            }
            else if (error.request) {
              return new Error(error.request);
            }
            else {
              return new Error(error.message);
            }
          });

        return getState().currentJobRequest;
      }
      catch (error) {
        console.log(error);
        actions.setError(error.message);
        return new Error(error.message);
      }
    }),


    getZipCode: thunk(async (actions, payload, { getState }) => {
      let existedJobRequest = {};
      try {
        const { accessToken, ...payloadRest } = payload;
        const response = await axios.get(`${Configuration.API_URL}/serviceappointment?id=${payloadRest.id}`,
          {
            headers: { "Authorization": `Bearer ${accessToken}` }
          }
        )
          .then(res => {
            if (!IsNullOrEmpty(res.data)) {
              existedJobRequest = {
                id: 0,
                claimNumber: "",
                riskAddress: "",
                riskCity: "",
                riskState: "",
                riskZip: "",
                policyHolderFirstName: "",
                policyHolderLastName: "",
                policyHolderEmail: "",
                policyHolderPhone: "",
                adjusterFirstName: "",
                adjusterLastName: "",
                adjusterEmail: "",
                adjusterPhone: "",
                serviceType: "",
                carrierId: "",
                dateOfLoss: "",
                typeOfLossId: "",
                contractorFirstName: "",
                contractorLastName: "",
                contractorPhone: "",
                reinspection: "",
                typeOfLossOther: "",
                notes: "",
                appointmentAvailabilityId: null,
                userEmail: "",
                userFirstName: "",
                userLastName: "",
              }
              let response = res.data;
              existedJobRequest.id = response?.id;
              existedJobRequest.claimNumber = response?.claimNumber;
              existedJobRequest.riskAddress = response?.riskAddress;
              existedJobRequest.riskCity = response?.riskCity;
              existedJobRequest.riskState = response?.riskState;//response?.riskState.toUpperCase();
              existedJobRequest.riskZip = response?.riskZip;
              existedJobRequest.policyHolderFirstName = response?.policyHolderFirstName;
              existedJobRequest.policyHolderLastName = response?.policyHolderLastName;
              existedJobRequest.policyHolderEmail = response?.policyHolderEmail;
              existedJobRequest.policyHolderPhone = response?.policyHolderPhone;
              existedJobRequest.adjusterFirstName = response?.adjusterFirstName;
              existedJobRequest.adjusterLastName = response?.adjusterLastName;
              existedJobRequest.adjusterEmail = response?.adjusterEmail;
              existedJobRequest.adjusterPhone = response?.adjusterPhone;
              existedJobRequest.serviceType = response?.serviceType;
              existedJobRequest.carrierId = response?.carrierId;
              existedJobRequest.dateOfLoss = format(new Date(response?.dateOfLoss), 'yyyy-MM-dd');
              existedJobRequest.typeOfLossId = response?.typeOfLossId;
              existedJobRequest.contractorFirstName = response?.contractorFirstName;
              existedJobRequest.contractorLastName = response?.contractorLastName;
              existedJobRequest.contractorPhone = response?.contractorPhone;
              existedJobRequest.reinspection = response?.reinspection;
              existedJobRequest.typeOfLossOther = response?.typeOfLossOther;
              existedJobRequest.notes = response?.notes;
              existedJobRequest.appointmentAvailabilityId = response?.appointmentAvailabilityId;
              existedJobRequest.userEmail = response?.userEmail;
              existedJobRequest.userFirstName = response?.userFirstName;
              existedJobRequest.userLastName = response?.userLastName;
              actions.setCurrentJobRequest(existedJobRequest);
            }

            return existedJobRequest.riskZip;
          })
          .catch((error) => {
            if (error.response) {
              return new Error(error.response.data.title || error.response.data);
            }
            else if (error.request) {
              return new Error(error.request);
            }
            else {
              return new Error(error.message);
            }
          });

        return getState().currentJobRequest.zipCode;
      }
      catch (error) {
        console.log(error);
        actions.setError(error.message);
        return new Error(error.message);
      }
    }),

  },
  accessToken: '',
  userEmail: '',
  timeSlot: {
    scheduleDate: '',
    timeSlotId: '',
    startHour: '',
    endHour: '',
  },
  log: {
    url: null,
    message: null,
    userName: null,
    userBrowser: null,
    accessToken: null,
    isValid: null,
  },
  dashboard: [{
    id: 0,
    claimnumber: null,
    inspectorname: null,
    inspectorphonenumber: null,
    adjustername: null,
    appointmentdatetime: null,
    jobstartdatetime: null,
    appointmentdatetime: null,
    jobcompleteddatetime: null,
    jobcloseddatetime: null,
    status: null,
    files: null,
  }],
  blob: [],
  forecast: [],
  serviceTypes: [],//{value, label}
  lossTypes: [],//{value, label}
  carriers: [],
  defaultCarrier: '',
  includeInactiveCarriers: [],
  domains: [],
  setForecast: action((state, payload) => {
    state.forecast = payload;
  }),
  setReinspectionValue: action((state, payload) => {
    state.reinspectionValue = payload;
  }),
  setIsContractorOnClaim: action((state, payload) => {
    state.isContractorOnClaim = payload;
  }),
  setContractorOnClaimValue: action((state, payload) => {
    state.contractorOnClaimValue = payload;
  }),
  setPageModeValue: action((state, payload) => {
    state.pageModeValue = payload;
  }),
  setClaimInformationValidationPass: action((state, payload) => {
    state.claimInformationValidationPass = payload;
  }),
  setContactDetailsValidationPass: action((state, payload) => {
    state.contactDetailsValidationPass = payload;
  }),
  setOtherInformationValidationPass: action((state, payload) => {
    state.otherInformationValidationPass = payload;
  }),
  setScheduleValidationPass: action((state, payload) => {
    state.scheduleValidationPass = payload;
  }),
  setDefaultCarrier: action((state, payload) => {
    state.defaultCarrier = payload;
  }),
  setUserEmail: action((state, payload) => {
    state.userEmail = payload;
  }),
  setDomains: action((state, payload) => {
    state.domains = payload;
  }),
  setBlob: action((state, payload) => {
    state.blob = payload;
  }),
  setSidebarSelectedIndex: action((state, payload) => {
    state.sidebarSelectedIndex = payload;
  }),
  setServiceTypes: action((state, payload) => {
    state.serviceTypes = payload;
  }),
  setIsServiceTypeLoading: action((state, payload) => {
    state.isServiceTypeLoading = payload;
  }),
  setIsTypeOfLossLoading: action((state, payload) => {
    state.isTypeOfLossLoading = payload;
  }),
  setIsCarrierLoading: action((state, payload) => {
    state.isCarrierLoading = payload;
  }),
  setLossTypes: action((state, payload) => {
    state.lossTypes = payload;
  }),
  setCarriers: action((state, payload) => {
    state.carriers = payload;
  }),
  setIncludeInactiveCarriers: action((state, payload) => {
    state.includeInactiveCarriers = payload;
  }),
  bookATimeSlot: action((state, payload) => {
    state.timeSlot = payload;
  }),
  setTimeSlots: action((state, payload) => {
    state.timeSlots = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
  updateUserEmail: thunk(async (actions, userEmail) => {
    actions.setUserEmail(userEmail);
  }),
  getDomains: thunk(async (actions, token) => {
    try {
      const response = await axios.get(
        `${Configuration.API_URL}/account/getdomain`,
        {
          headers: { "Authorization": `Bearer ${token}` }
        })
        .then(res => {
          actions.setDomains(res.data);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 403) {
              actions.setError("access denial");
            }
            return new Error(error.response.data);
          }
          else if (error.request) {
            return new Error(error.request);
          }
          else {
            return new Error(error.message);
          }
        });
    }
    catch (error) {
      actions.setError(error.message);
    }
  }),
  saveDomains: thunk(async (actions, payload) => {
    actions.setDomains(payload);
  }),
  updateDomain: thunk(async (actions, payload) => {

    if (payload != null && !payload?.isValid) {
      try {
        const { accessToken, ...payloadRest } = payload;
        const response = await axios.put(`${Configuration.API_URL}/account/updatedomain/${payloadRest.id}?`,
          payloadRest,
          { headers: { "Authorization": `Bearer ${accessToken}` } }
        )
          .then(res => {
            actions.setDomains(res.data);
          })
          .catch((error) => {
            if (error.response) {
              actions.setError(error.response.data);
              return new Error("Unable to update domain");
            }
            else if (error.request) {
              return new Error(error.request);
            }
            else {
              return new Error(error.message);
            }
          });
      }
      catch (error) {
        actions.setError(error.message);
      }
    }
  }),

  createDomain: thunk(async (actions, payload, { getState }) => {
    try {
      const { accessToken, ...payloadRest } = payload;

      const response = await axios.post(
        `${Configuration.API_URL}/account/insertdomain`,
        payloadRest,
        { headers: { "Authorization": `Bearer ${accessToken}` } }
      )
        .then(res => {

        })
        .catch((error) => {
          if (error.response) {
            actions.setError(error.response.data);
            return new Error(error.response.data);
          }
          else if (error.request) {
            return new Error(error.request);
          }
          else {
            return new Error(error.message);
          }
        });
      return response;
    }
    catch (error) {
      console.log(error);
      actions.setError(error.message);
      return new Error(error.message);
    }
  }),

  updateError: thunk(async (actions, payload) => {
    actions.setError(payload?.message);
    if (payload != null && !payload?.isValid) {
      try {
        const { accessToken, ...payloadRest } = payload;
        const response = await axios.post(`${Configuration.API_URL}/Log/errorinfo?`,
          payloadRest,
          { headers: { "Authorization": `Bearer ${accessToken}` } }
        )
      }
      catch { }
    }
  }),
  setNavbarIndex: action((state, payload) => {
    state.navbarIndex = payload;
  }),
  setAccessToken: action((state, payload) => {
    state.accessToken = payload;
  }),
  updateAccessToken: thunk(async (actions, payload) => {
    actions.setAccessToken(payload);
  }),
  addToBookATimeSlot: thunk(async (actions, payload) => {
    actions.bookATimeSlot(payload);
  }),
  setLog: action((state, payload) => {
    state.log = payload;
  }),
  updateLog: thunk(async (actions, payload) => {
    actions.setLog(payload);
  }),
  setDashboard: action((state, payload) => {
    state.dashboard = payload;
  }),

  fetchInitialLoad: thunk(async (actions, payload) => {
    try {
      const { accessToken, ...payloadRest } = payload;
      const response = await axios.get(
        `${Configuration.API_URL}/serviceappointment/initialLoad?email=${payloadRest.userEmail}`,
        {
          headers: { "Authorization": `Bearer ${accessToken}` }
        })
        .then(res => {
          actions.setIsCarrierLoading(false);
          actions.setCarriers(res.data.carrier);
          actions.setIsCarrierLoading(true);
          actions.setIncludeInactiveCarriers(res.data.includeInactiveCarrier);
          actions.setIsCarrierLoading(false);
          actions.setDefaultCarrier(res.data.carrierId);
          actions.setServiceTypes(res.data.serviceTypes);
          actions.setIsServiceTypeLoading(false);
          actions.setLossTypes(res.data.typeOfLoss);
          actions.setIsTypeOfLossLoading(false);
          return res.data.carrierId;
        })
        .catch((error) => {
          if (error.response) {
            return new Error("Unable to get Service Types");
          }
          else if (error.message) {
            actions.setError(error.message);
            return new Error(error.message);
          }
          else if (error.request) {
            return new Error(error.request);
          }
          else {
            return new Error(error.message);
          }
        });
    }
    catch (error) {
      actions.setError(error.message);
    }
  }),

  createSchedule: thunk(async (actions, payload) => {
    try {
      const { accessToken, ...payloadRest } = payload;
      const response = await axios.post(`${Configuration.API_URL}/serviceappointment/schedule?`,
        payloadRest,
        { headers: { "Authorization": `Bearer ${accessToken}` } }
      )
        .then(res => {
          return res;
        })
        .catch((error) => {
          if (error.response) {
            return new Error(error.response.data);
          }
          else if (error.message) {
            actions.setError(error.message);
            return new Error(error.message);
          }
          else if (error.request) {
            return new Error(error.request);
          }
          else {
            return new Error(error.message);
          }
        });
      return response;
    }
    catch (error) {
      actions.setError(error.message);
    }
  }),

  accessToken: '',
  timeSlot: {
    scheduleDate: '',
    timeSlotId: '',
    startHour: '',
    endHour: '',
  },
  log: {
    url: null,
    message: null,
    userName: null,
    userBrowser: null,
    accessToken: null,
    isValid: null,
  },
  serviceTypes: [],//{value, label}
  lossTypes: [],//{value, label}

  setServiceTypes: action((state, payload) => {
    state.serviceTypes = payload;
  }),
  setIsServiceTypeLoading: action((state, payload) => {
    state.isServiceTypeLoading = payload;
  }),
  setIsTypeOfLossLoading: action((state, payload) => {
    state.isTypeOfLossLoading = payload;
  }),
  setLossTypes: action((state, payload) => {
    state.lossTypes = payload;
  }),
  bookATimeSlot: action((state, payload) => {
    state.timeSlot = payload;
  }),
  setTimeSlots: action((state, payload) => {
    state.timeSlots = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
  updateError: thunk(async (actions, payload) => {
    actions.setError(payload?.message);
    if (payload != null && !payload?.isValid) {
      try {
        const { accessToken, ...payloadRest } = payload;
        const response = await axios.post(`${Configuration.API_URL}/Log/errorinfo?`,
          payloadRest,
          { headers: { "Authorization": `Bearer ${accessToken}` } }
        )
      }
      catch { }
    }
  }),

  setAccessToken: action((state, payload) => {
    state.accessToken = payload;
  }),
  updateAccessToken: thunk(async (actions, payload) => {
    actions.setAccessToken(payload);
  }),
  addToBookATimeSlot: thunk(async (actions, payload) => {
    actions.bookATimeSlot(payload);
  }),
  setLog: action((state, payload) => {
    state.log = payload;
  }),
  updateLog: thunk(async (actions, payload) => {
    actions.setLog(payload);
  }),

  reschedule: thunk(async (actions, payload) => {
    try {
      const { accessToken, ...payloadRest } = payload;
      const response = await axios.post(`${Configuration.API_URL}/serviceappointment/reschedule?`,
        payloadRest,
        { headers: { "Authorization": `Bearer ${accessToken}` } }
      )
        .then(res => {
          return res;
        })
        .catch((error) => {
          if (error.response) {
            return new Error(error.response.data);
          }
          else if (error.request) {
            return new Error(error.request);
          }
          else {
            return new Error(error.message);
          }
        });
      return response;
    }
    catch (error) {
      actions.setError(error.message);
      return new Error(error.message);
    }
  }),

  cancelSchedule: thunk(async (actions, payload, { getState }) => {
    try {
      const { accessToken, ...payloadRest } = payload;
      const response = await axios.put(`${Configuration.API_URL}/serviceappointment/CancelServiceAppointment?serviceAppointmentId=${payloadRest.serviceAppointmentId}`,
        payloadRest,
        {
          headers: { "Authorization": `Bearer ${accessToken}` }
        }
      )
        .then(res => {
          return getState().dashboard;
        })
        .catch((error) => {
          if (error.response) {
            return new Error(error.response.data);
          }
          else if (error.request) {
            return new Error(error.request);
          }
          else {
            return new Error(error.message);
          }
        });
      return response;
    }
    catch (error) {
      actions.setError(error.message);
      return new Error(error.message);
    }
  }),

  fetchTimeSlots: thunk(async (actions, payload) => {
    try {
      const { accessToken, ...payloadRest } = payload;
      const response = await axios.get(`${Configuration.API_URL}/availability/day?zipcode=${payload.zipCode}&dayToQuery=${format(payload.dayToQuery, "yyyy-MM-dd")}`,
        {
          headers: { "Authorization": `Bearer ${accessToken}` }
        }
      )
        .then(res => {
          actions.setTimeSlots(res.data.sort((a, b) => { return a.order - b.order; }));
        })
        .catch((error) => {
          if (error.response) {
            return new Error(error.response.data);
          }
          else if (error.request) {
            return new Error(error.request);
          }
          else {
            return new Error(error.message);
          }
        });
      return response;
    }
    catch (error) {
      actions.setError(error.message);
      return new Error(error.message);
    }
  }),
  fetchBlob: thunk(async (actions, payload, { getState }) => {
    try {
      const { accessToken, ...payloadRest } = payload;
      const response = await axios.get(`${Configuration.API_URL}/serviceappointment/attachmentFile?attachmentId=${payload.attachmentId}`,
        {
          headers: { "Authorization": `Bearer ${accessToken}` }
        }
      )
        .then(blob => {
          actions.setBlob(blob.data);
          return getState().blob; //blob.data.fileContents;
        })
        .catch((error) => {
          if (error.response) {
            return new Error("Unable to get Blob data");
          }
          else if (error.request) {
            return new Error(error.request);
          }
          else {
            return new Error(error.message);
          }
        });
    }
    catch (err) {
      actions.setError(err.message);
    }
  }),

  clearForecast: thunk(async (actions) => {
    actions.setForecast('');
  }),
  fetchForecast: thunk(async (actions, payload, { getState }) => {

    try {
      const response = await axios.get(`${Configuration.API_URL}/weather?City=${payload.city}&ZipCode=${payload.zipCode}`)
        .then(res => {
          actions.setForecast(res.data);
          return getState().forecast;
        })
        .catch((error) => {
          if (error.response) {
            return new Error("Unable to get forecast");
          }
          else if (error.request) {
            return new Error(error.request);
          }
          else {
            return new Error(error.message);
          }
        });
    }
    catch (err) {
      actions.setError(err.message);
    }
    return getState().forecast;
  }),

  fetchDashboard: thunk(async (actions, payload, { getState }) => {
    try {
      const { accessToken, ...payloadRest } = payload;
      const response = await axios.get(`${Configuration.API_URL}/serviceappointment/userdashboard?email=${payloadRest.userEmail}`,
        {
          headers: { "Authorization": `Bearer ${accessToken}` }
        })
        .then(res => {
          let _dashboard = [{
            id: 0,
            claimnumber: null,
            inspectorname: null,
            inspectorphonenumber: null,
            adjustername: null,
            appointmentdatetime: null,
            jobstartdatetime: null,
            appointmentdatetime: null,
            jobcompleteddatetime: null,
            jobcloseddatetime: null,
            files: null,
            status: null,
            rescheduledatetime: null,
          }];
          let result = res.data;
          for (var i = 0; i < result.length; i++) {
            _dashboard[i] = {
              id: null,
              claimnumber: null,
              inspectorname: null,
              inspectorphonenumber: null,
              adjustername: null,
              jobstartdatetime: null,
              appointmentdatetime: null,
              jobcompleteddatetime: null,
              jobcloseddatetime: null,
              files: null,
              status: null,
              rescheduledatetime: null,
            };
            _dashboard[i].id = result[i].id;
            _dashboard[i].claimnumber = result[i].claimnumber;
            _dashboard[i].inspectorname = result[i].inspectorname;
            _dashboard[i].inspectorphonenumber = result[i].inspectorphonenumber;
            _dashboard[i].adjustername = result[i].adjustername;
            _dashboard[i].jobstartdatetime = result[i].jobStartdatetime;
            _dashboard[i].appointmentdatetime = result[i].appointmentdatetime;
            _dashboard[i].jobcompleteddatetime = result[i].jobcompleteddatetime;
            _dashboard[i].jobcloseddatetime = result[i].jobcloseddatetime;
            _dashboard[i].rescheduledatetime = result[i].snappointmentdatetime;
            _dashboard[i].status = result[i].status;
            _dashboard[i].files = result[i].files;
          }
          actions.setDashboard(_dashboard);
          return getState().dashboard;
        })
        .catch((error) => {
          if (error.response) {
            return new Error(error.response.data.title || error.response.data);
          }
          else if (error.request) {
            return new Error(error.request);
          }
          else {
            return new Error(error.message);
          }
        });
    }
    catch (err) {
      actions.setError(err.message);
    }
  }),
  fetchAdminDashboard: thunk(async (actions, payload, { getState }) => {
    try {
      const { accessToken, ...payloadRest } = payload;
      const response = await axios.get(`${Configuration.API_URL}/serviceappointment/admindashboard`,
        {
          headers: { "Authorization": `Bearer ${accessToken}` }
        })
        .then(res => {
          let _dashboard = [{
            id: 0,
            claimnumber: null,
            inspectorname: null,
            inspectorphonenumber: null,
            adjustername: null,
            jobstartdatetime: null,
            appointmentdatetime: null,
            jobcompleteddatetime: null,
            jobcloseddatetime: null,
            files: null,
            status: null,
            rescheduledatetime: null,
          }];
          let result = res.data;
          for (var i = 0; i < result.length; i++) {
            _dashboard[i] = {
              id: null,
              claimnumber: null,
              inspectorname: null,
              inspectorphonenumber: null,
              adjustername: null,
              appointmentdatetime: null,
              jobstartdatetime: null,
              jobcompleteddatetime: null,
              jobcloseddatetime: null,
              files: null,
              status: null,
              rescheduledatetime: null,
            };
            _dashboard[i].id = result[i].id;
            _dashboard[i].claimnumber = result[i].claimnumber;
            _dashboard[i].inspectorname = result[i].inspectorname;
            _dashboard[i].inspectorphonenumber = result[i].inspectorphonenumber;
            _dashboard[i].adjustername = result[i].adjustername;
            _dashboard[i].appointmentdatetime = result[i].appointmentdatetime;
            _dashboard[i].rescheduledatetime = result[i].snappointmentdatetime;
            _dashboard[i].jobstartdatetime = result[i].jobStartdatetime;
            _dashboard[i].appointmentdatetime = result[i].appointmentdatetime;
            _dashboard[i].jobcompleteddatetime = result[i].jobcompleteddatetime;
            _dashboard[i].jobcloseddatetime = result[i].jobcloseddatetime;
            _dashboard[i].status = result[i].status;
            _dashboard[i].files = result[i].files;
          }
          actions.setDashboard(_dashboard);
          return getState().dashboard;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 403) {
              actions.setError("access denial");
            }
            return new Error(error.response.data.title || error.response.data);
          }
          else if (error.request) {
            return new Error(error.request);
          }
          else {
            return new Error(error.message);
          }
        });
    }
    catch (err) {
      actions.setError(err.message);
    }
  }),

  deleteDomain: thunk(async (actions, payload) => {
    try {
      const { accessToken, ...payloadRest } = payload;

      const response = await axios.delete(
        `${Configuration.API_URL}/account/DeleteCarrier/${payloadRest.id}`,
        { headers: { "Authorization": `Bearer ${accessToken}` } }
      )
        .then(res => {

        })
        .catch((error) => {
          if (error.response) {
            actions.setError(error.response.data);
            return new Error(error.response.data);
          }
          else if (error.request) {
            return new Error(error.request);
          }
          else {
            return new Error(error.message);
          }
        });
      return response;
    }
    catch (error) {
      console.log(error);
      actions.setError(error.message);
      return new Error(error.message);
    }
  }),

  uploadFile: thunk(async (actions, payload) => {
    try {
      const { accessToken, ...payloadRest } = payload;
      formData.append('file', payloadRest.fileStream);
      
      const response = await axios.post(
        `${Configuration.API_URL}/account/upload`,
        formData,
        { headers: { "Authorization": `Bearer ${accessToken}`, 'content-type': 'multipart/form-data' } }
      )
        .then(res => {

        })
        .catch((error) => {
          if (error.response) {
            actions.setError(error.response.data);
            return new Error(error.response.data);
          }
          else if (error.request) {
            return new Error(error.request);
          }
          else {
            return new Error(error.message);
          }
        });
      return response;
    }
    catch (error) {
      console.log(error);
      actions.setError(error.message);
      return new Error(error.message);
    }
  })
};

export default createStore(model); 