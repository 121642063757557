import React, { forwardRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Slide
} from "@mui/material";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  dialog: {
    borderRadius: 0
  },
  countdown: {
    color: "#A92517"
  }
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SessionTimeoutDialog = ({ open, countdown, onLogout, onContinue }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.dialog }}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        Session Timeout
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          The current session is about to expire in{" "}
          <span className={classes.countdown}>{countdown}</span> seconds.
        </Typography>
        <Typography variant="body2">{`Would you like to continue the session?`}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onLogout}
          variant="contained"
          style={{
            backgroundColor: "#A92517",
          }}
          sx={{ paddingTop: 1.2 }}
        >
          Logout
        </Button>
        <Button
          onClick={onContinue}
          color="primary"
          style={{
            backgroundColor: "#A92517",
          }}
          variant="contained"
          sx={{ paddingTop: 1.2 }}
        >
          Continue Session
        </Button>
      </DialogActions>
    </Dialog>
  );
}


export default SessionTimeoutDialog;