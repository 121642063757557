import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { useLocation } from "react-router-dom";
import { useStoreState, useStoreActions } from 'easy-peasy';
import { makeStyles } from '@mui/styles';
import { TextField } from "../components/TextField";
import { PopUpDialog } from "../components/PopUpDialog";
import { ParseEventStringValue, IsNullOrEmpty } from "../helpers/Common";
import { useHistory } from 'react-router-dom';
import InputMask from "react-input-mask";
import { Card, CardContent, CardHeader, Box, Button, Divider, Grid, Alert, AlertTitle, createFilterOptions } from '@mui/material';
import { useMsal } from "@azure/msal-react";
import Configuration from '../helpers/Configuration';
import { AutoCompleteOffForm } from '../components/AutoCompleteOffForm';

/*
Jobrequest-README

See main ReadMe for details on autocomplete off strategies... there's a lot of trial and error to figure out what works for different components
*/

const useStyles = makeStyles(() => ({
  card: {
    width: '99%',
    marginBottom: 10
  },
  cardHeader: {
    background: 'linear-gradient(0deg, #e0e0e0 10%, #cfd8dc 100%)',
    height: '2.5vw',
    "@media (max-width: 1660px)": {
      "& .MuiCardHeader-title": {
        titleTypographyProps: { variant: 'h6' },
        variant: 'h6'
      },
    }
  },
  phone: {
    marginLeft: 15,
    textDecoration: 'none',
    marginTop: '12%',
    marginRight: 20
  },
  input: {
    height: 6
  },
}));

function ContactDetails(props, ref) {
  const debugLoggingFlag = ((!IsNullOrEmpty(Configuration.ENVIRONMENT)) && Configuration.ENVIRONMENT !== "production");
  const btnNext = useRef();
  const { instance } = useMsal();
  const classes = useStyles();
  const history = useHistory();
  const isAdjusterAssigned = useStoreState((state) => state.jobRequest.currentJobRequest.isAdjusterAssigned);
  const [onSubmitClick, setOnSubmitClick] = useState(false);
  const error = useStoreState((state) => state.error);
  const jobRequestValidation = useStoreState((state) => state.jobRequest.currentJobRequestValidation);
  const setCurrentJobRequest = useStoreActions(actions => actions.jobRequest.setCurrentJobRequest);
  const validateAdjusterInformation = useStoreActions(actions => actions.jobRequest.validateAdjusterInformation);
  const validateJobRequestActions = useStoreActions(actions => actions.jobRequest.currentJobRequestValidation);
  const validateContactDetails = useStoreActions(actions => actions.jobRequest.validateContactDetails);
  const setContactDetailsValidationPass = useStoreActions(actions => actions.setContactDetailsValidationPass);
  const updateError = useStoreActions(actions => actions.updateError);
  const setPageModeValue = useStoreActions(actions => actions.setPageModeValue);
  const pageModeValue = useStoreState((state) => state.pageModeValue);
  const jobRequest = useStoreState((state) => state.jobRequest.currentJobRequest);
  const log = useStoreState((state) => state.log);

  function showValidationMessageOnTop(message) {
    updateError({ ...log, message: message, isValid: false });
    window.scrollTo(0, 0);
  }

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option.label,
  });

  const scrollToTop = () => {
    if (!IsNullOrEmpty(jobRequestValidation.result?.policyHolderFirstName?.message)) {
      showValidationMessageOnTop(jobRequestValidation.result?.policyHolderFirstName?.message)
    }
    else if (!IsNullOrEmpty(jobRequestValidation.result?.policyHolderLastName?.message)) {
      showValidationMessageOnTop(jobRequestValidation.result?.policyHolderLastName?.message)
    }
    else if (!IsNullOrEmpty(jobRequestValidation.result?.policyHolderPhone?.message)) {
      showValidationMessageOnTop(jobRequestValidation.result?.policyHolderPhone?.message)
    }
    else if (!IsNullOrEmpty(jobRequestValidation.result?.policyHolderEmail?.message)) {
      showValidationMessageOnTop(jobRequestValidation.result?.policyHolderEmail?.message)
    }
    else if (!IsNullOrEmpty(jobRequestValidation.result?.adjusterFirstName?.message)) {
      showValidationMessageOnTop(jobRequestValidation.result?.adjusterFirstName?.message)
    }
    else if (!IsNullOrEmpty(jobRequestValidation.result?.adjusterLastName?.message)) {
      showValidationMessageOnTop(jobRequestValidation.result?.adjusterLastName?.message)
    }
    else if (!IsNullOrEmpty(jobRequestValidation.result?.adjusterPhone?.message)) {
      showValidationMessageOnTop(jobRequestValidation.result?.adjusterPhone?.message)
    }
    else if (!IsNullOrEmpty(jobRequestValidation.result?.adjusterEmail?.message)) {
      showValidationMessageOnTop(jobRequestValidation.result?.adjusterEmail?.message)
    }
  };

  const policyHolderFirstNameOnChange = (event, newValue) => {
    let policyHolderFirstNameValue = ParseEventStringValue(event);
    if (jobRequestValidation.validationHasRun) {
      validateJobRequestActions.validatePolicyHolderFirstName(policyHolderFirstNameValue);
    }
    setCurrentJobRequest({ ...jobRequest, policyHolderFirstName: policyHolderFirstNameValue });
  };

  const policyHolderEmailOnChange = (event, newValue) => {
    let policyHolderEmailValue = ParseEventStringValue(event);
    setCurrentJobRequest({ ...jobRequest, policyHolderEmail: policyHolderEmailValue });
  };

  const policyHolderPhoneOnChange = (event, newValue) => {
    let policyHolderPhoneValue = ParseEventStringValue(event);
    if (jobRequestValidation.validationHasRun) {
      validateJobRequestActions.validatePolicyHolderPhone(policyHolderPhoneValue);
    }
    setCurrentJobRequest({ ...jobRequest, policyHolderPhone: policyHolderPhoneValue });
  };

  const policyHolderLastNameOnChange = (event, newValue) => {
    let policyHolderLastNameValue = ParseEventStringValue(event);
    if (jobRequestValidation.validationHasRun) {
      validateJobRequestActions.validatePolicyHolderLastName(policyHolderLastNameValue);
    }
    setCurrentJobRequest({ ...jobRequest, policyHolderLastName: policyHolderLastNameValue });
  };

  const adjusterFirstNameOnChange = (event, newValue) => {
    let adjusterFirstNameValue = ParseEventStringValue(event);
    if (jobRequestValidation.validationHasRun && isAdjusterAssigned) {
      validateJobRequestActions.validateAdjusterFirstName(adjusterFirstNameValue);
    }
    setCurrentJobRequest({ ...jobRequest, adjusterFirstName: adjusterFirstNameValue });
  };

  const adjusterLastNameOnChange = (event, newValue) => {
    let adjusterLastNameValue = ParseEventStringValue(event);
    if (jobRequestValidation.validationHasRun) {
      validateJobRequestActions.validateAdjusterLastName(adjusterLastNameValue);
    }
    setCurrentJobRequest({ ...jobRequest, adjusterLastName: adjusterLastNameValue });
  };

  const adjusterPhoneOnChange = (event, newValue) => {
    let adjusterPhoneValue = ParseEventStringValue(event);
    if (jobRequestValidation.validationHasRun && isAdjusterAssigned) {
      validateJobRequestActions.validateAdjusterPhone(adjusterPhoneValue);
    }
    setCurrentJobRequest({ ...jobRequest, adjusterPhone: adjusterPhoneValue });
  };

  const adjusterEmailOnChange = (event, newValue) => {
    let adjusterEmailValue = ParseEventStringValue(event);
    if (jobRequestValidation.validationHasRun) {
      validateJobRequestActions.validateAdjusterEmail(adjusterEmailValue);
    }
    setCurrentJobRequest({ ...jobRequest, adjusterEmail: adjusterEmailValue });
  };

  useImperativeHandle(ref, () => ({
    blur: () => {
      onValidate();
    },
  }));

  function onValidate() {
    var isValidated = validateContactDetails(jobRequest);
    return setContactDetailsValidationPass(isValidated);
  }

  const handleBack = () => {
    props.handleBack();
  };

  return (
    <div style={{ width: '80vw' }}>
      <Box>
        <Grid container spacing={{ xs: 1, sm: 2, md: 3 }} style={{ backgroundColor: 'white' }} >
          <Grid item xs={12} style={{ marginRight: 20, marginTop: 2 }} >

            {
              error && (
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  <strong>{error}</strong>
                </Alert>
              )}
          </Grid>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justify="center"
            style={{ marginLeft: 10, marginRight: 0, marginTop: 2 }}
          >
            <Grid
              container
              spacing={2}
              alignItems="center"
              justify="center"
              style={{ marginLeft: 10, marginRight: 0 }}
            >
              <Grid item xs={12}>
                <Card className={classes.card}>
                  <CardHeader
                    className={classes.cardHeader}
                    title="Policy Holder Information"
                    titleTypographyProps={{ variant: 'h6' }}
                  />
                  <Divider />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item md={3} xs={12}>
                        <AutoCompleteOffForm autoComplete={"off"}>
                          <TextField
                            disabled={(pageModeValue === "edit-dashboard")}
                            aria-label="policy holder first name"
                            fullWidth
                            label="First Name"
                            required
                            InputLabelProps={{ shrink: IsNullOrEmpty(jobRequest.policyHolderFirstName) ? false : true }}
                            value={jobRequest.policyHolderFirstName}
                            onBlur={scrollToTop}
                            inputProps={{ maxLength: 50, autocomplete: "new-password" }}
                            onChange={policyHolderFirstNameOnChange}
                            variant="outlined"
                            error={(jobRequestValidation.result?.policyHolderFirstName?.isValid === false ? (jobRequestValidation.result?.policyHolderFirstName?.isValid === false ? jobRequestValidation.result?.policyHolderFirstName?.message : "INVALID") : null)}
                            helperText={(jobRequestValidation.result?.policyHolderFirstName?.isValid === false ? jobRequestValidation.result?.policyHolderFirstName?.message : "Required")}
                          />
                        </AutoCompleteOffForm>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <AutoCompleteOffForm autoComplete={"off"}>
                          <TextField
                            disabled={(pageModeValue === "edit-dashboard")}
                            aria-label="policy holder last name"
                            fullWidth
                            label="Last Name"
                            required
                            InputLabelProps={{ shrink: IsNullOrEmpty(jobRequest.policyHolderLastName) ? false : true }}
                            value={jobRequest.policyHolderLastName}
                            onBlur={scrollToTop}
                            inputProps={{ maxLength: 50, autocomplete: "new-password" }}
                            onChange={policyHolderLastNameOnChange}
                            variant="outlined"
                            error={(jobRequestValidation.result?.policyHolderLastName?.isValid === false ? (jobRequestValidation.result?.policyHolderLastName?.isValid === false ? jobRequestValidation.result?.policyHolderLastName?.message : "INVALID") : null)}
                            helperText={(jobRequestValidation.result?.policyHolderLastName?.isValid === false ? jobRequestValidation.result?.policyHolderLastName?.message : "Required")}
                          />
                        </AutoCompleteOffForm>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <InputMask
                          mask="(999)999-9999"
                          onChange={policyHolderPhoneOnChange}
                          value={jobRequest?.policyHolderPhone}
                        >
                          {() => <AutoCompleteOffForm autoComplete={"off"}>
                            <TextField
                              disabled={(pageModeValue === "edit-dashboard")}
                              aria-label="policy holder phone"
                              label="Phone"
                              fullWidth
                              required
                              InputLabelProps={{ shrink: IsNullOrEmpty(jobRequest.policyHolderPhone) ? false : true }}
                              onBlur={scrollToTop}
                              inputProps={{ autocomplete: "new-password" }}
                              error={(jobRequestValidation.result?.policyHolderPhone?.isValid === false ? (jobRequestValidation.result?.policyHolderPhone?.isValid === false ? jobRequestValidation.result?.policyHolderPhone?.message : "INVALID") : null)}
                              helperText={(jobRequestValidation.result?.policyHolderPhone?.isValid === false ? jobRequestValidation.result?.policyHolderPhone?.message : "Required")}
                            />
                          </AutoCompleteOffForm>
                          }
                        </InputMask>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <AutoCompleteOffForm autoComplete={"off"}>
                          <TextField
                            disabled={(pageModeValue === "edit-dashboard")}
                            aria-label="policy holder email"
                            fullWidth
                            label="Email"
                            onBlur={scrollToTop}
                            InputLabelProps={{ shrink: IsNullOrEmpty(jobRequest.policyHolderEmail) ? false : true }}
                            value={jobRequest?.policyHolderEmail}
                            inputProps={{ maxLength: 255, autocomplete: "new-password" }}
                            onChange={policyHolderEmailOnChange}
                            variant="outlined"
                          />
                        </AutoCompleteOffForm>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justify="center"
              style={{ marginLeft: 10, marginRight: 0 }}
            >
              <Grid item xs={12}>
                <Card className={classes.card}>
                  <CardHeader
                    className={classes.cardHeader}
                    title="Adjuster Information"
                    titleTypographyProps={{ variant: 'h6' }}
                  />
                  <Divider />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item md={3} xs={12}>
                        <AutoCompleteOffForm autoComplete={"off"}>
                          <TextField
                            disabled={(pageModeValue === "edit-dashboard")}
                            aria-label="adjuster first name"
                            fullWidth
                            label="First Name"
                            required={isAdjusterAssigned}
                            InputLabelProps={{ shrink: IsNullOrEmpty(jobRequest.adjusterFirstName) ? false : true }}
                            value={jobRequest?.adjusterFirstName}
                            onBlur={scrollToTop}
                            inputProps={{ maxLength: 50, autocomplete: "new-password" }}
                            onChange={adjusterFirstNameOnChange}
                            variant="outlined"
                            error={(jobRequestValidation.result?.adjusterFirstName?.isValid === false ? (jobRequestValidation.result?.adjusterFirstName?.isValid === false ? jobRequestValidation.result?.adjusterFirstName?.message : "INVALID") : null)}
                            helperText={(jobRequestValidation.result?.adjusterFirstName?.isValid === false || !isAdjusterAssigned ? jobRequestValidation.result?.adjusterFirstName?.message : "Required")}
                          />
                        </AutoCompleteOffForm>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <AutoCompleteOffForm autoComplete={"off"}>
                          <TextField
                            disabled={(pageModeValue === "edit-dashboard")}
                            aria-label="adjuster last name"
                            fullWidth
                            label="Last Name"
                            required={isAdjusterAssigned}
                            InputLabelProps={{ shrink: IsNullOrEmpty(jobRequest.adjusterLastName) ? false : true }}
                            value={jobRequest?.adjusterLastName}
                            onBlur={scrollToTop}
                            inputProps={{ maxLength: 50, autocomplete: "new-password" }}
                            onChange={adjusterLastNameOnChange}
                            variant="outlined"
                            error={(jobRequestValidation.result?.adjusterLastName?.isValid === false ? (jobRequestValidation.result?.adjusterLastName?.isValid === false ? jobRequestValidation.result?.adjusterLastName?.message : "INVALID") : null)}
                            helperText={(jobRequestValidation.result?.adjusterLastName?.isValid === false || !isAdjusterAssigned ? jobRequestValidation.result?.adjusterLastName?.message : "Required")}
                          />
                        </AutoCompleteOffForm>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <InputMask
                          mask="(999)999-9999"
                          onChange={adjusterPhoneOnChange}
                          value={jobRequest?.adjusterPhone}
                        >
                          {() =>
                            <AutoCompleteOffForm autoComplete={"off"}>
                              <TextField
                                disabled={(pageModeValue === "edit-dashboard")}
                                aria-label="adjuster phone"
                                label="Phone"
                                fullWidth
                                required={isAdjusterAssigned}
                                InputLabelProps={{ shrink: IsNullOrEmpty(jobRequest.adjusterPhone) ? false : true }}
                                onBlur={scrollToTop}
                                inputProps={{ autocomplete: "new-password" }}
                                error={(jobRequestValidation.result?.adjusterPhone?.isValid === false ? (jobRequestValidation.result?.adjusterPhone?.isValid === false ? jobRequestValidation.result?.adjusterPhone?.message : "INVALID") : null)}
                                helperText={(jobRequestValidation.result?.adjusterPhone?.isValid === false || !isAdjusterAssigned ? jobRequestValidation.result?.adjusterPhone?.message : "Required")}
                              />
                            </AutoCompleteOffForm>
                          }
                        </InputMask>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <AutoCompleteOffForm autoComplete={"off"}>
                          <TextField
                            disabled={(pageModeValue === "edit-dashboard")}
                            aria-label="adjuster email"
                            fullWidth
                            label="Email"
                            required={isAdjusterAssigned}
                            InputLabelProps={{ shrink: IsNullOrEmpty(jobRequest.adjusterEmail) ? false : true }}
                            value={jobRequest?.adjusterEmail}
                            onBlur={scrollToTop}
                            inputProps={{ maxLength: 255, autocomplete: "new-password" }}
                            onChange={adjusterEmailOnChange}
                            variant="outlined"
                            error={(jobRequestValidation.result?.adjusterEmail?.isValid === false ? (jobRequestValidation.result?.adjusterEmail?.isValid === false ? jobRequestValidation.result?.adjusterEmail?.message : "INVALID") : null)}
                            helperText={(jobRequestValidation.result?.adjusterEmail?.isValid === false || !isAdjusterAssigned ? jobRequestValidation.result?.adjusterEmail?.message : "Required")}
                          />
                        </AutoCompleteOffForm>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Button
                {...props}
                type="button"
                variant="contained"
                disabled={props.activeStep === 0}
                onClick={handleBack}
                color="primary"
                sx={{ mr: 1, paddingTop: 1.2 }}
              >
                Back
              </Button>
              <Button
                ref={btnNext}
                {...props}
                style={{ marginLeft: 10 }}
                type="button"
                variant="contained"
                aria-label="submit"
                color="primary"
                sx={{ paddingTop: 1.2 }}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default forwardRef(ContactDetails);
