import dayjs from 'dayjs';
import * as weatherIcons from '../../icons';
import { reformatDate } from "../../helpers/Common";
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useState } from 'react';
const iconPrefix = `wi wi-`;

export default function useWeather(endpoint, date) {
  const fetchForecast = useStoreActions(actions => actions.fetchForecast);
  const updateError = useStoreActions(actions => actions.updateError);
  const jobRequest = useStoreState((state) => state.jobRequest.currentJobRequest);
  const forecast = useStoreState((state) => state.forecast);
  const log = useStoreState((state) => state.log);
  const error = useStoreState((state) => state.error);
  const [prevZip, setPrevZip] = useState();

  if (forecast.length === 0 && jobRequest.riskZip !== null && prevZip !== jobRequest.riskZip) {
    setPrevZip(jobRequest.riskZip);
    const res = fetchForecast({ zipCode: jobRequest.riskZip, city: jobRequest.riskCity })
      .then(res => {
      })
      .catch(err => {
        updateError({ ...log, message: res.message, isValid: false });
        return new Error(err.message);
      })
  }

  if (endpoint === 'weather') {
    let formatDate = dayjs(date).format('YYYY-MM-DD');
    let regularExpression = new RegExp(`${formatDate}`);
    let resultObj = forecast?.list && Object.entries(forecast).length
      ? forecast.list
        .filter((f) => reformatDate(new Date(f.dt * 1000)).match(regularExpression))
        .map(mapResponseProperties)
      : null;
    resultObj = resultObj?.map(obj => ({ ...obj, country: forecast.city.country, location: forecast.city.name }));
    return {
      forecast: resultObj ? resultObj : null,
      isLoading: !forecast && !error,
      isError: error,
    };
  } else {
    const today = new Date();
    return {
      forecast:
        forecast?.list && Object.entries(forecast).length
          ? forecast.list
            .filter((f) => Math.abs(new Date(f.dt * 1000).getDate() - today.getDate()) > 0)
            .map(mapResponseProperties)
          : null,
      isLoading: !forecast && !error,
      isError: error,
    };
  }
}

function mapResponseProperties(data) {
  const mapped = {
    location: data.location,
    date: new Date(data.dt * 1000),
    description: data.description,
    feels_like: Math.round((data.feels_like.day - 272.15) * (9 / 5) + 32),
    sunrise: new Date(data.sunrise * 1000),
    sunset: new Date(data.sunset * 1000),
    dt_txt: new Date(data.dt * 1000),
    country: data.country,
    humidity: data.humidity,
    icon_id: data.weather[0].id,
    sunrise: data.sunrise,
    sunset: data.sunset,
    wind_speed: Math.round(data.speed * 3.6),
    max: Math.round((data.temp.max - 272.15) * (9 / 5) + 32),
    min: Math.round((data.temp.min - 272.15) * (9 / 5) + 32),
  };

  // Add extra properties for the five day forecast: dt_txt, icon, min, max
  if (mapped.dt_txt) {
    mapped.dt_txt = new Date(data.dt * 1000);
    mapped.forecastIcon =
      iconPrefix + weatherIcons.default['day'][mapped.icon_id].icon;
  }

  if (data.sunrise && data.sunset) {
    mapped.sunrise = dayjs(new Date(data.sunrise * 1000)).format('h:mm a');
    mapped.sunset = dayjs(new Date(data.sunset * 1000)).format('h:mm a');
  }
  // remove undefined fields
  Object.entries(mapped).map(
    ([key, value]) => value === undefined && delete mapped[key],
  );

  return mapped;
}
