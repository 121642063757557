import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import { steps } from '../helpers/Constants';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InfoIcon from '@mui/icons-material/Info';
import DateRangeIcon from '@mui/icons-material/DateRange';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClaimInformation from "./ClaimInformation";
import ContactDetails from "./ContactDetails";
import OtherInformation from "./OtherInformation";
import Schedule from "./Schedule";
import Complete from "./Complete";
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Box, Button, Divider, Typography } from '@mui/material';
import { IsNullOrEmpty } from "../helpers/Common";
import { withRouter } from 'react-router-dom';

function JobRequest(props) {
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const claimInformationForm = useRef(null);
  const contactDetailsForm = useRef(null);
  const otherInformationForm = useRef(null);
  const scheduleForm = useRef(null);
  const completeForm = useRef(null);
  const setClaimInformationValidationPass = useStoreActions((actions) => actions.setClaimInformationValidationPass);
  const setContactDetailsValidationPass = useStoreActions((actions) => actions.setContactDetailsValidationPass);
  const setOtherInformationValidationPass = useStoreActions((actions) => actions.setOtherInformationValidationPass);
  const setScheduleValidationPass = useStoreActions((actions) => actions.setScheduleValidationPass);
  const setSidebarSelectedIndex = useStoreActions((actions) => actions.setSidebarSelectedIndex);
  const claimInformationValidationPass = useStoreState((state) => state.claimInformationValidationPass);
  const contactDetailsValidationPass = useStoreState((state) => state.contactDetailsValidationPass);
  const otherInformationValidationPass = useStoreState((state) => state.otherInformationValidationPass);
  const scheduleValidationPass = useStoreState((state) => state.scheduleValidationPass);
  const clearValidateJobRequest = useStoreActions(actions => actions.jobRequest.clearValidateJobRequest);
  const [isBackButtonClicked, setIsBackButtonClicked] = useState(false);
  const updateError = useStoreActions(actions => actions.updateError);
  const log = useStoreState((state) => state.log);
  const accessToken = useStoreState((state) => state.accessToken);
  const firstUpdate = useRef(true);
  const totalSteps = () => {
    return steps.length;
  };
  let pageMode = props.mode;
  useEffect(() => {
    setSidebarSelectedIndex(1);      
    if (pageMode === "edit-dashboard") {
      setActiveStep(3);
    }
    else {
      setActiveStep(0);
    }
    if (IsNullOrEmpty(accessToken)) {
      setActiveStep(0);
    }
    resetValidationPass();   
  }, [])

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return activeStep + 1 === totalSteps();
  };

  const handleNext = () => {
    setIsBackButtonClicked(false);
    if (activeStep === 0) {
      claimInformationForm.current.blur();
    }
    else if (activeStep === 1) {
      contactDetailsForm.current.blur();
    }
    else if (activeStep === 2) {
      otherInformationForm.current.blur();
    }
    else if (activeStep === 3) {
      scheduleForm.current.blur();
    }
    else if (activeStep === 5) {
      completeForm.current.blur();
      setActiveStep(0);
      resetValidationPass();
    }
    validationBeforeMovingNext();
  };

  const resetValidationPass = () => {
    setClaimInformationValidationPass(false);
    setContactDetailsValidationPass(false);
    setOtherInformationValidationPass(false);
    setScheduleValidationPass(false);  
  }

  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    else {
      validationBeforeMovingNext();
    }    
  }, [claimInformationValidationPass, contactDetailsValidationPass, otherInformationValidationPass, scheduleValidationPass])

  function validationBeforeMovingNext() {
    if (activeStep == 0 && claimInformationValidationPass ) {
      incrementActiveStep();
    }
    else if (activeStep == 1 && contactDetailsValidationPass ) {
      incrementActiveStep();
    }
    else if (activeStep == 2 && otherInformationValidationPass ) {
      incrementActiveStep();
    }
    else if (activeStep == 3 && scheduleValidationPass ) {
      incrementActiveStep();
    }
    else if (activeStep == 4 ) {
      incrementActiveStep();
    }
  }

  const incrementActiveStep = () => {
    let newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
        // find the first step that has been completed
        steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    if (newActiveStep == 4) {
      newActiveStep = newActiveStep + 1;
    }
    setActiveStep(newActiveStep);
    clearValidateJobRequest();
    updateError({ ...log, message: "", isValid: true });
  }


  const handleBack = () => {
    validationBeforeMovingNext();
    clearValidateJobRequest();
    setIsBackButtonClicked(true);
    updateError(null);
    if (activeStep == 5) {
      setActiveStep(activeStep - 2);
    }
    else {
      setActiveStep(activeStep - 1);
    }
  };

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg, green 0%, green 50%, green 100%)',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:          
          'linear-gradient( 95deg, green, green 100%, green 100%)',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.completed && {
      backgroundImage:
        'linear-gradient( 136deg, rgb(0,154,36) 0%, #43A047 50%, rgb(0,154,36) 100%)',
    }),
    ...(ownerState.active && {
      backgroundImage://Black inside blue outside
        'linear-gradient( 136deg, rgb(86,86,86) 0%, rgb(86,86,86) 50%, rgb(86,86,86) 100%)',
      boxShadow: '0 -3em 3em rgba(0,0,0,0.1), 0 0  0 5px rgb(55,151,211), 0.3em 0.3em 1em rgba(0,0,0,0.3)'
    }),
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
      1: <AssignmentIcon />,
      2: <AccountCircleIcon />,
      3: <InfoIcon />,
      4: <DateRangeIcon />,
      5: <CheckCircleIcon />,
    };

    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };
  return (

    <Box sx={{ width: '100%' }}>
      <Typography variant="h5" >
        Job Request
      </Typography>
      <Divider style={{ marginTop: 10, marginBottom: 10 }} />
      <Stack sx={{ width: '100%' }} spacing={4}>
        <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Stack>
      <div>
        {activeStep === 5 ? (
          <>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Complete onClick={() => handleNext()} {...props} ref={completeForm} isBackButtonClicked={isBackButtonClicked} handleBack={handleBack} activeStep={activeStep} sx={{ mr: 1 }} />
            </Box>
          </>
        ) : (
          <>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              {activeStep === 0 && <ClaimInformation onClick={() => handleNext()} {...props} ref={claimInformationForm} isBackButtonClicked={isBackButtonClicked} handleBack={handleBack} activeStep={activeStep} sx={{ mr: 1 }} />}
              {activeStep === 1 && <ContactDetails onClick={() => handleNext()} {...props} ref={contactDetailsForm} isBackButtonClicked={isBackButtonClicked} handleBack={handleBack} activeStep={activeStep} sx={{ mr: 1 }} />}
              {activeStep === 2 && <OtherInformation onClick={() => handleNext()} {...props} ref={otherInformationForm} isBackButtonClicked={isBackButtonClicked} handleBack={handleBack} activeStep={activeStep} sx={{ mr: 1 }} />}
              {activeStep === 3 && <Schedule onClick={() => handleNext()} {...props} ref={scheduleForm} isBackButtonClicked={isBackButtonClicked} handleBack={handleBack} activeStep={activeStep} sx={{ mr: 1 }} />}              
            </Box>
          </>
        )}
      </div>
    </Box>
  );
}

export default withRouter(JobRequest)