import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle, useCallback } from "react";
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useLocation } from "react-router-dom";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { makeStyles } from '@mui/styles';
import TimeRangePicker from "../components/TimeRangePicker";
import Paper from '@mui/material//Paper';
import { FlexRow } from "../components/Flex";
import { useHistory } from 'react-router-dom';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import WeatherCard from '../components/weatherForecast/weather-card';
import ForecastCard from '../components/weatherForecast/forecast-card';
import {
  Button,
  Divider,
  Grid,
  TextField,
  Alert,
  Typography,
  AlertTitle,
  Box
} from '@mui/material';
import { IsNullOrEmpty } from "../helpers/Common";
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  inputSubmit: {
    display: 'none',
  },
}));

const onTimeSlotSelected = (timeSlot) => { alert(timeSlot) };

function Schedule(props, ref) {
  const classes = useStyles();
  const history = useHistory();
  const isAdmin = window.isCarrierPortalPilotAdmin;
  const btnNext = useRef();
  const [date, setDate] = useState(new Date());
  const [nextDate, setNextDate] = useState('');
  const fetchTimeSlots = useStoreActions(actions => actions.fetchTimeSlots);
  const timeSlot = useStoreState((state) => state.timeSlot);
  const createSchedule = useStoreActions((actions) => actions.createSchedule);
  const reschedule = useStoreActions((actions) => actions.reschedule);
  const setScheduleValidationPass = useStoreActions((actions) => actions.setScheduleValidationPass);
  const jobRequestId = useStoreState((state) => state.jobRequest.currentJobRequest.id);
  const jobRequest = useStoreState((state) => state.jobRequest.currentJobRequest);
  const zipCode = useStoreState((state) => state.jobRequest.currentJobRequest.riskZip);
  const accessToken = useStoreState((state) => state.accessToken);
  const error = useStoreState((state) => state.error);
  const updateError = useStoreActions(actions => actions.updateError);
  const log = useStoreState((state) => state.log);
  const location = useLocation();
  const getJobRequest = useStoreActions(actions => actions.jobRequest.getJobRequest);
  const [units, setUnits] = useState('imperial');

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let pageMode = props.mode;
    var currDate = new Date();
    currDate.setDate(currDate.getDate() + 1);
    setDate(currDate);
    setNextDate(currDate);
    if (pageMode === "edit-dashboard") {
      getJobRequest({ accessToken: accessToken, id: location?.state?.id })
        .then(res => {
          if (!(res instanceof Error)) {
            fetchTimeSlots({ dayToQuery: currDate, zipCode: res.riskZip, accessToken: accessToken });
          }
          else {
            updateError({ ...log, message: res.message, isValid: false });
          }
        })
    }

  }, []);

  useEffect(() => {
    if (!IsNullOrEmpty(zipCode)) {
      fetchTimeSlots({ dayToQuery: date, zipCode: zipCode, accessToken: accessToken });
    }
  }, [date])

  const onDateSelected = (dateSelected) => {
    setDate(dateSelected);

    fetchTimeSlots({ dayToQuery: dateSelected, zipCode: zipCode, accessToken: accessToken });
  };

  const onSubmit = async (e) => {
    setLoading(true);
    setScheduleValidationPass(false);
    if (!timeSlot.timeSlotId) {
      updateError({ ...log, message: "You must select a time slot!", isValid: true });
    }
    else {
      if (jobRequest.appointmentAvailabilityId === null) {
        const res = await createSchedule({ id: jobRequestId, zoneServiceAppointmentAvailabilityId: timeSlot.timeSlotId, accessToken: accessToken })
          .catch(err => {
            updateError({ ...log, message: err.message, isValid: false });
            return new Error(err.message);
          });

        if (!(res instanceof Error)) {
          setScheduleValidationPass(true);
        } else {
          updateError({ ...log, message: res.message, isValid: false });
          if (isAdmin) {
            history.push('/admin/notfound');
          }
          else {
            history.push('/notfound');
          }
        }
      } else {
        const res = await reschedule({ id: jobRequestId, zoneServiceAppointmentAvailabilityId: timeSlot.timeSlotId, accessToken: accessToken })
          .catch(err => {
            updateError({ ...log, message: err.message, isValid: false });
            return new Error(err.message);
          });

        if (!(res instanceof Error)) {
          setScheduleValidationPass(true);
        } else {
          updateError({ ...log, message: res.message, isValid: false });
          if (isAdmin) {
            history.push('/admin/notfound');
          }
          else {
            history.push('/notfound');
          };
        }
      }
    }
    setLoading(false);
  };

  useImperativeHandle(ref, () => ({
    blur: () => {
      onSubmit();
    },
  }));

  const handleBack = () => {
    props.handleBack();
  };

  return (
    <div style={{ width: '80vw' }}>
      <ForecastCard />
      <Grid container spacing={2} style={{ marginTop: '50px', marginLeft: '2%' }}>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.appBarTitle}>
            Please select a preferred date and time window for the inspection. Our inspector/adjuster will contact the insured to confirm and narrow down the arrival time.
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.datepicker}>
            <FlexRow justifyContent="flex-start" flexWrap="wrap">
              <div style={{ display: "flex", }}>
                <Paper elevation={3} style={{ justifyContent: "center", textDecoration: 'none' }}>
                  {
                    error && (
                      <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        <strong>{error}</strong>
                      </Alert>
                    )}
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <StaticDatePicker
                      orientation="landscape"
                      openTo="day"
                      value={nextDate}
                      minDate={nextDate}
                      allowSameDateSelection={false}
                      onChange={(newValue) => {
                        onDateSelected(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Paper>
              </div>
              <Paper elevation={3}>
                <TimeRangePicker onTimeSlotSelected={onTimeSlotSelected} targetDate={date} />
              </Paper>
              <WeatherCard date={date} />
            </FlexRow>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item />
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <FlexRow justifyContent="space-evenly" flexWrap="wrap">
            <div style={{ marginRight: '33%' }}>
              <Grid container justifyContent="flex-end">
                <Button
                  {...props}
                  type="button"
                  variant="contained"
                  disabled={props.activeStep === 0}
                  onClick={handleBack}
                  color="primary"
                  sx={{ mr: 1, paddingTop: 1.2 }}
                >
                  Back
                </Button>
                <Box>
                  <Button
                    ref={btnNext}
                    {...props}
                    style={{ marginLeft: 10 }}
                    disabled={loading}
                    type="button"
                    variant="contained"
                    aria-label="submit"
                    color="primary"
                    sx={{ paddingTop: 1.2 }}
                  >
                    {loading ?
                      <CircularProgress size={24} />
                      :
                      "Next"
                    }
                  </Button>
                </Box>
              </Grid>
            </div>
          </FlexRow>
        </Grid>
      </Grid>
    </div>
  );;
}

export default forwardRef(Schedule);
