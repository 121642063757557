import { LogLevel } from "@azure/msal-browser";
import Configuration from '../../helpers/Configuration';
/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */

 export const b2cPolicies = {
    names: {
        signUpSignIn: Configuration.POLICY_URL,
        forgotPassword: "",
        editProfile: ""
    },
    authorities: {
        signUpSignIn: {
            authority: Configuration.POLICY_URL,
        },
        forgotPassword: {
            authority: "",
        },
        editProfile: {
            authority: ""
        }
    },
    authorityDomain: Configuration.AUTHORITY_DOMAIN
}

/*
export const b2cPolicies = {
    names: {
        signUpSignIn: Configuration.POLICY,
        forgotPassword: Configuration.POLICY,
        editProfile: Configuration.EDIT_PROFILE_POLICY
    },
    authorities: {
        signUpSignIn: {
            authority: Configuration.POLICY_URL,
        },
        forgotPassword: {
            authority: Configuration.POLICY_URL,
        },
        editProfile: {
           authority: Configuration.EDIT_PROFILE_POLICY_URL,
        } 
    },
    authorityDomain: Configuration.MSAL_B2C_AUTHORITY_DOMAIN
}
*/
/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfigB2C = {    
    auth: {
        clientId: Configuration.MSAL_B2CCLIENTID, // This is the ONLY mandatory field that you need to supply.
        authority: b2cPolicies.authorities.signUpSignIn.authority, // Use a sign-up/sign-in user-flow as a default authority
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: "/", // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
        postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {	
        loggerOptions: {	
            loggerCallback: (level, message, containsPii) => {	
               
                if (containsPii || Configuration.ENVIRONMENT === "production") 
                {		
                    return;		
                }		
                switch (level) {		
                    case LogLevel.Error:		
                        console.error(message);		
                        return;		
                    case LogLevel.Info:		
                        console.info(message);		
                        return;		
                    case LogLevel.Verbose:		
                        console.debug(message);		
                        return;		
                    case LogLevel.Warning:		
                        console.warn(message);		
                        return;	
                    default:
                        return;	
                }	
            }	
        }	
    }
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
 const apiConfig = {
    b2cScopes: ["openid", "profile"],    
  };

export const loginRequest = {
    scopes: ["openid", ...apiConfig.b2cScopes]
};

export const b2cApiRequest = {
   // scopes: ["https://PilotDevB2C.onmicrosoft.com/CarrierPortal-Api-Dev/access_as_user"]
    scopes: [`${Configuration.MSAL_B2CSCOPEPREFIX}/access_as_user`]
}; 

  
