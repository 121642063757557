import { b2cApiRequest } from "../views/authentication/AuthConfigB2C";
import { apiRequest } from "../views/authentication/AuthConfig";

export const GetAccessTokenRequest  = function (accounts){    
    if(accounts.length > 0)
    {
      const account = accounts[0];
      const accessTokenRequest = {
        scopes:  !window.isCarrierPortalPilotAdmin? b2cApiRequest.scopes : apiRequest.scopes, 
        account: account
      }
      return accessTokenRequest;
    }          
}; 

