import React, { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useStoreActions } from 'easy-peasy'; 
import { GetAccessTokenRequest } from "../components/Account";
import { ParseEventStringValue, IsNullOrEmpty } from "../helpers/Common";

export const ProfileContent = (props) => {    
    const { instance } = useMsal();     
    const [firstName, setFirstName] = useState("");
    const [lastName, setlastName] = useState("");
    const accounts = instance.getAllAccounts();   
      instance.acquireTokenSilent(GetAccessTokenRequest(accounts)
      ).then(function (accessTokenResponse) {
        if (!IsNullOrEmpty(accessTokenResponse.idTokenClaims.given_name) && !IsNullOrEmpty(accessTokenResponse.idTokenClaims.family_name)) {
          setFirstName(accessTokenResponse.idTokenClaims.given_name);
          setlastName(accessTokenResponse.idTokenClaims.family_name);
        }
        else {
          try {
            let name = accessTokenResponse.idTokenClaims.name.split(' ');
            if (name.length > 0) {
                setFirstName(name[0] || '');
                setlastName(name[1] || '');
            }
            else {
                setFirstName(name);
            }
          }
          catch {
          }
        }  
    }); 
    return (
        <>
            <div style={{ marginLeft: 15 }}>        
                {firstName} {lastName}
            </div>            
        </>
    );
};