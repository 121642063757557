import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { EventType, InteractionType } from "@azure/msal-browser";
import { Router, Switch, Route, withRouter, BrowserRouter } from "react-router-dom";
import Layout from "./components/Layout";
import Navigate from "./components/Navigate";
import React, { useEffect, useContext, createContext, useState } from "react";
import store from './store';
import { StoreProvider } from 'easy-peasy';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import NotFound from './views/NotFound';
import Setting from './views/Setting';
import Availability from "./views/Availability";
import Dashboard from './views/Dashboard';
import Signout from './views/Signout';
import JobRequest from './views/JobRequest';
import { createBrowserHistory } from "history";
import SessionTimeout from './components/SessionTimeout';
import DirectLogin from './views/authentication/DirectLogin';
import AccessDenial from './views/AccessDenial';
export const appHistory = createBrowserHistory({ forceRefresh: true });

const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#A92517',
    },
    secondary: {
      main: '#000000'
    },
  },
});


const MainContent = ({ msalInstance }) => {
  const { instance } = useMsal();
  const [location, setLocation] = useState('');

  /**
   * Using the event API, you can register an event callback that will do something when an event is emitted. 
   * When registering an event callback in a react component you will need to make sure you do 2 things.
   * 1) The callback is registered only once
   * 2) The callback is unregistered before the component unmounts.
   * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/events.md
   */

  useEffect(() => {
    const callbackId = msalInstance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_FAILURE) {
        if (event.error && event.error.errorMessage.indexOf("AADB2C90118") > -1) {
          if (event.interactionType === InteractionType.Redirect) {
            //msalInstance.loginRedirect(b2cPolicies.authorities.forgotPassword);
          } else if (event.interactionType === InteractionType.Popup) {
            //msalInstance.loginPopup(b2cPolicies.authorities.forgotPassword)
            //    .catch(e => {
            //       return;
            //  });
          }
        }
      }

      if (event.eventType === EventType.LOGIN_SUCCESS) {
        if (event?.payload) {
          /**
           * We need to reject id tokens that were not issued with the default sign-in policy.
           * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
           * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
           */
          //if (event.payload.idTokenClaims["acr"] === b2cPolicies.names.forgotPassword) {
          //   window.alert("Password has been reset successfully. \nPlease sign-in with your new password");
          //  return msalInstance.logout();
          // }
        }
      }
      if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
        handleLogout();
      }
    });

    return () => {
      if (callbackId) {
        msalInstance.removeEventCallback(callbackId);
      }
    };

  }, []);
  const handleLogout = () => {
    Signout();
  }
  return (
    <div>
      <AuthenticatedTemplate>
        <StoreProvider store={store}>
          <ThemeProvider theme={muiTheme}>
            <CssBaseline />
            <SessionTimeout logOut={handleLogout} />
            <Layout>
              <Switch>
                <Route exact path="/dashboard" render={(props) => { return <Dashboard {...props} />; }}></Route>
                <Route exact path="/jobrequest" render={() => { return <JobRequest mode={"create"} key={location.key} />; }}></Route>
                <Route exact path="/jobrequest-edit" render={() => { return <JobRequest mode={"edit-dashboard"} /> }} ></Route>
                <Route exact path="/notfound" render={(props) => { return <NotFound {...props} />; }}></Route>
                <Route exact path="/signout" render={(props) => { return <Signout {...props} />; }}></Route>
                <Route exact path="/" render={(props) => { return <Dashboard {...props} />; }}></Route>

                <Route exact path="/admin/dashboard" render={(props) => { return <Dashboard {...props} />; }}></Route>
                <Route exact path="/admin/schedule" render={(props) => { return <JobRequest {...props} />; }}></Route>
                <Route exact path="/admin/jobrequest" render={() => { return <JobRequest mode={"create"} />; }}></Route>
                <Route exact path="/admin/jobrequest-edit" render={() => { return <JobRequest mode={"edit-dashboard"} /> }} ></Route>
                <Route exact path="/admin/setting" render={(props) => { return <Setting {...props} />; }}></Route>
                <Route exact path="/admin/availability" render={(props) => { return <Availability {...props} />; }}></Route>
                <Route exact path="/admin/notfound" render={(props) => { return <NotFound {...props} />; }}></Route>
                <Route exact path="/admin/signout" render={(props) => { return <Signout {...props} />; }}></Route>
                <Route exact path="/admin/" render={(props) => { return <Dashboard {...props} />; }}></Route>
              </Switch>
              {location !== '' &&
                <Navigate to={location} />
              }
            </Layout>
          </ThemeProvider>
        </StoreProvider>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <DirectLogin />
      </UnauthenticatedTemplate>
    </div>
  );
};

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be 
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication 
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the 
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more,
 * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */

function App({ msalInstance }) {
  return (
    <>
      <MsalProvider instance={msalInstance}>
        <MainContent msalInstance={msalInstance} />
      </MsalProvider>
    </>
  );
}

export default withRouter(App);