import React, {
    useState,
    useEffect,
    useCallback
} from 'react';
import clsx from 'clsx';
import { useMsal } from "@azure/msal-react";
import { GetAccessTokenRequest } from "../components/Account";
import { makeStyles } from '@mui/styles';
import { useDropzone } from 'react-dropzone';
import { useStoreState, useStoreActions } from 'easy-peasy';
import {
    Box,
    Grid,
    Card,
    CardHeader,
    CardContent,
    Alert,
    AlertTitle,
    Divider,
    Typography,
    CircularProgress,
} from '@mui/material';
import attachedFile from '../assets/images/attachedFile.svg';

const useStyles = makeStyles((theme) => ({
    root: {},
    card: {
        width: '99%',
        marginBottom: 10
    },
    cardHeader: {
        background: 'linear-gradient(0deg, #e0e0e0 10%, #cfd8dc 100%)',
        height: '2.5vw',
        "@media (max-width: 1660px)": {
            "& .MuiCardHeader-title": {
                titleTypographyProps: { variant: 'h6' },
                variant: 'h6'
            },
        }
    },
    dropZone: {
        border: `1px dashed ${theme.palette.divider}`,
        padding: theme.spacing(6),
        outline: 'none',
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
            opacity: 0.5,
            cursor: 'pointer'
        }
    },
    dragActive: {
        backgroundColor: theme.palette.action.active,
        opacity: 0.5
    },
    progressZone:{
        border: `1px dashed ${theme.palette.divider}`,
        padding: '100px 200px',
        outline: 'none',
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        alignItems: 'center',
    },
    image: {
        width: 150,
        height: 150
    }
}));

const Availability = ({ className, staticContext, ...rest }) => {

    const { instance } = useMsal();
    const classes = useStyles();
    const log = useStoreState((state) => state.log);
    const [loading, setLoading] = useState(false);
    const error = useStoreState((state) => state.error);
    const updateError = useStoreActions(actions => actions.updateError);
    const accessToken = useStoreState((state) => state.accessToken);
    const uploadFile = useStoreActions((actions) => actions.uploadFile);
    const setSidebarSelectedIndex = useStoreActions((actions) => actions.setSidebarSelectedIndex);
    const updateAccessToken = useStoreActions(actions => actions.updateAccessToken);

    let errorState = { status: undefined, message: '' };
    const [validationControl, setValidationControl] = useState({
        summary: errorState
    });


    useEffect(() => {
        const accounts = instance.getAllAccounts();
        instance.acquireTokenSilent(GetAccessTokenRequest(accounts)).then(function (accessTokenResponse) {
            let accessToken = accessTokenResponse.accessToken;
            updateAccessToken(accessToken);
            setSidebarSelectedIndex(3);
            return accessToken;
        });
        updateError(null);
    }, [])

    const handleDrop = useCallback((acceptedFiles) => {
        setLoading(true);
        acceptedFiles.map((acceptedFile, index) => {
            if (checkValidation(acceptedFile)) {
                updateError({ ...log, message: "", isValid: true });
                let fileDetails = { fileStream: acceptedFile }
                fileDetails.accessToken = accessToken;
                const res = uploadFile(fileDetails)
                    .then(res => {
                        if ((res instanceof Error)) {
                            updateError({ ...log, message: res.message, isValid: true });
                            setValidationControl({ summary: { status: 'error', message: res.message } });
                        }
                        else {
                            setValidationControl({ summary: { status: 'success', message: `${acceptedFile.name} is uploaded!` } });
                        }
                        setLoading(false);
                    })
                    .catch(err => {
                        updateError({ ...log, message: res.message, isValid: false });
                        return new Error(err.message);
                    })
            }
        })
    }, [accessToken]);


    const checkValidation = (file) => {
        if (file.size > 2097152000) {
            setLoading(false);
            setValidationControl({
                summary: {
                    status: 'error',
                    message: "File size should be less than 2MB"
                }
            });
            return false;
        }
        if (file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            setLoading(false);
            setValidationControl({
                summary: {
                    status: 'error',
                    message: "Invalid file type. Please upload only .xlsx files!"
                }
            });
            return false;
        }
        return true;
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: handleDrop
    });

    return (
        <div className={classes.root}>
            <Box>
                <Grid container spacing={{ xs: 1, sm: 2, md: 3 }} style={{ backgroundColor: 'white' }} >
                    <Grid item xs={12} style={{ marginRight: 20, marginTop: 2 }} >
                        <Typography variant={"h5"} component={'div'} className={classes.appBarTitle}>
                            Availability
                        </Typography>
                        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                        alignItems={"center"}
                        justify={"center"}
                        style={{ marginLeft: 10, marginRight: 0, marginTop: 2 }}
                    >
                        <Grid item xs={12}>
                            <Card className={classes.card}>
                                <CardHeader
                                    className={classes.cardHeader}
                                    title={"Upload Files"}
                                    titleTypographyProps={{ variant: 'h6' }}
                                />
                                <Divider />
                                <Grid item xs={12} style={{ margin: '10px 10px 0 10px' }} >
                                    {
                                        validationControl?.summary?.status != undefined && (
                                            <Alert severity={validationControl?.summary.status == 'error' ? "error" : "success"}>
                                                <AlertTitle>{validationControl?.summary.status == 'error' ? "Error" : "Saved"}</AlertTitle>
                                                <strong>{validationControl?.summary.message}</strong>
                                            </Alert>
                                        )}
                                </Grid>
                                <CardContent>
                                    <div
                                        className={clsx(classes.root, className)}
                                        {...rest}
                                    >
                                        {
                                            loading ?
                                                <div className={classes.progressZone}>
                                                    <CircularProgress size={70} />
                                                </div>
                                                :
                                                <div
                                                    className={clsx({
                                                        [classes.dropZone]: true,
                                                        [classes.dragActive]: isDragActive
                                                    })}
                                                    {...getRootProps()}
                                                >
                                                    <input {...getInputProps()} />
                                                    <div>
                                                        <img
                                                            alt={"Select file"}
                                                            className={classes.image}
                                                            src={attachedFile}
                                                        />
                                                    </div>
                                                    <div>
                                                        <Typography
                                                            gutterBottom
                                                            variant={"h3"}
                                                            component={'div'}
                                                        >
                                                            Select files
                                                        </Typography>
                                                        <Box mt={2}>
                                                            <Typography
                                                                color={"textPrimary"}
                                                                variant={"body1"}
                                                                component={'div'}
                                                            >
                                                                Click or Drop files here to Upload
                                                            </Typography>
                                                        </Box>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}

export default Availability;