import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import { useMsal } from "@azure/msal-react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export const PopUpDialog = (props) => {
    const { instance } = useMsal();
    const history = useHistory();
    const isAdmin = window.isCarrierPortalPilotAdmin;
    const [popUpDialog, setPopUpDialog] = useState({ shouldPopUp: props.params.shouldPopUp, msgHeader: props.params.msgHeader, msgBody: props.params.msgBody, buttonStatus: props.params.buttonStatus });
    const handleClose = () => {
        setPopUpDialog(prevState => ({
            ...prevState,
            shouldPopUp: false
        }));
        if (isAdmin) {
            history.push('/admin/dashboard');
        }
        else {
            history.push('/dashboard');
        }
    };

    useEffect(() => {
        setPopUpDialog({ shouldPopUp: props.params.shouldPopUp, msgHeader: props.params.msgHeader, msgBody: props.params.msgBody, buttonStatus: props.params.buttonStatus });
    }, [props])

    const handleLogout = () => {
        setPopUpDialog(prevState => ({
            ...prevState,
            shouldPopUp: false
        }));
        instance.logoutRedirect({ postLogoutRedirectUri: "/", });
    };

    return (
        <>
            {popUpDialog &&
                <Dialog
                    open={popUpDialog.shouldPopUp}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {popUpDialog.msgHeader}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {popUpDialog.msgBody}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {popUpDialog.buttonStatus === "logout" ?
                            <Button onClick={handleLogout}>Logout</Button>
                            :
                            <Button onClick={handleClose}>X</Button>
                        }
                    </DialogActions>
                </Dialog>
            }
        </>
    )
}

