import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { useLocation } from "react-router-dom";
import { useStoreState, useStoreActions } from 'easy-peasy';
import { makeStyles } from '@mui/styles';
import { TextField } from "../components/TextField";
import { PopUpDialog } from "../components/PopUpDialog";
import { ParseEventStringValue, IsNullOrEmpty } from "../helpers/Common";
import { useHistory } from 'react-router-dom';
import InputMask from "react-input-mask";
import { Card, CardContent, Box, Button, RadioGroup, Grid, FormControlLabel, Radio, Alert, Typography, AlertTitle } from '@mui/material';
import { useMsal } from "@azure/msal-react";
import Configuration from '../helpers/Configuration';
import { AutoCompleteOffForm } from '../components/AutoCompleteOffForm';
import { GetAccessTokenRequest } from "../components/Account";
import CircularProgress from '@mui/material/CircularProgress';

/*
Jobrequest-README

See main ReadMe for details on autocomplete off strategies... there's a lot of trial and error to figure out what works for different components
*/

const useStyles = makeStyles(() => ({
  card: {
    width: '99%',
    marginBottom: 10
  },
  cardHeader: {
    background: 'linear-gradient(0deg, #e0e0e0 10%, #cfd8dc 100%)',
    height: '2.5vw',
    "@media (max-width: 1660px)": {
      "& .MuiCardHeader-title": {
        titleTypographyProps: { variant: 'h6' },
        variant: 'h6'
      },
    }
  },
  phone: {
    marginLeft: 15,
    textDecoration: 'none',
    marginTop: '12%',
    marginRight: 20
  },
  input: {
    height: 6
  },
}));

function OtherInformation(props, ref) {
  const debugLoggingFlag = ((!IsNullOrEmpty(Configuration.ENVIRONMENT)) && Configuration.ENVIRONMENT !== "production");
  const classes = useStyles();
  const isAdmin = window.isCarrierPortalPilotAdmin;
  const history = useHistory();
  const location = useLocation();
  const btnNext = useRef();
  const { instance } = useMsal();
  const [isReinspection, setIsReinspection] = useState(null);
  const setReinspectionValue = useStoreActions(actions => actions.setReinspectionValue);
  const reinspectionValue = useStoreState((state) => state.reinspectionValue);
  const [isAdjusterAssigned, setIsAdjusterAssigned] = useState(false);
  const [onSubmitClick, setOnSubmitClick] = useState(false);
  const [saveNotesBtnDisabled, setSaveNotesBtnDisabled] = useState(true);
  const error = useStoreState((state) => state.error);
  const updateAccessToken = useStoreActions(actions => actions.updateAccessToken);
  const jobRequestValidation = useStoreState((state) => state.jobRequest.currentJobRequestValidation);
  const setCurrentJobRequest = useStoreActions(actions => actions.jobRequest.setCurrentJobRequest);
  const createJobRequest = useStoreActions(actions => actions.jobRequest.createJobRequest);
  const updateJobRequest = useStoreActions(actions => actions.jobRequest.updateJobRequest);
  const validateOtherInformation = useStoreActions(actions => actions.jobRequest.validateOtherInformation);
  const validateJobRequest = useStoreActions(actions => actions.jobRequest.validateJobRequest);
  const validateAdjusterInformation = useStoreActions(actions => actions.jobRequest.validateAdjusterInformation);
  const validateJobRequestActions = useStoreActions(actions => actions.jobRequest.currentJobRequestValidation);
  const updateError = useStoreActions(actions => actions.updateError);
  const setOtherInformationValidationPass = useStoreActions(actions => actions.setOtherInformationValidationPass);
  const setContractorOnClaimValue = useStoreActions(actions => actions.setContractorOnClaimValue);
  const contractorOnClaimValue = useStoreState((state) => state.contractorOnClaimValue);
  const [isContractorOnClaim, setIsContractorOnClaim] = useState(null);
  const pageModeValue = useStoreState((state) => state.pageModeValue);
  const jobRequest = useStoreState((state) => state.jobRequest.currentJobRequest);
  const log = useStoreState((state) => state.log);
  const accessToken = useStoreState((state) => state.accessToken);

  const [loading, setLoading] = useState(false);
  const [noteLoading, setNoteLoading] = useState(false);

  function onSaveNotes() {
    setNoteLoading(true);
    let jobRequestId;
    if (location?.state?.id === undefined) {
      jobRequestId = jobRequest.id;
    } else {
      jobRequestId = location?.state?.id;
    }

    const res = updateJobRequest(jobRequest)
      .then(res => {
        if ((res instanceof Error)) {
          updateError({ ...log, message: res.message, isValid: true });
        } else {
          setSaveNotesBtnDisabled(true);
        }
        setNoteLoading(false);
      })
      .catch(err => {
        updateError({ ...log, message: res.message, isValid: false });
        return new Error(err.message);
      })
  }

  function onSubmit() {
    setLoading(true);
    updateError(null);
    setOtherInformationValidationPass(false);
    if (props.editmode !== null && pageModeValue === "edit-dashboard") {
      setOtherInformationValidationPass(true);
      setLoading(false);
    }
    else {
      if (validateJobRequest(jobRequest)) {
        if (jobRequest.id === 0 || IsNullOrEmpty(jobRequest.id)) {
          const res = createJobRequest(jobRequest).then(res => {
            if (!(res instanceof Error)) {
              if (res === "Zip Code doesn't exist") {
                setPopUpDialog(prevState => ({
                  ...prevState,
                  shouldPopUp: true,
                  msgHeader: "",
                  msgBody: "Due to limited resources in the area, appointment scheduling is not available online. We will get this assigned to a representative and confirm the appointment date and time within 48 hours. ",
                }));
              }
              else {
                localStorage.setItem('jobRequestId', res.data);
                updateError(null);
                setOtherInformationValidationPass(true);
              }
            }
            else {
              if (!res.message.startsWith("A Service Appointment has already been open for the Claim Number") && !res.message.startsWith("One or more validation errors occurred") && !res.message.startsWith("Zip Code doesn't exist")) {
                updateError({ ...log, message: res.message, isValid: false });
                if (isAdmin) {
                  history.push('/admin/notfound');
                }
                else {
                  history.push('/notfound');
                }
              }
              else {
                setOtherInformationValidationPass(false);
                updateError({ ...log, message: res.message, isValid: false });
              }
            }
            setLoading(false);
          }).catch(err => {
            updateError({ ...log, message: res.message, isValid: false });
            return new Error(err.message);
          })

        }
        else {
          const res = updateJobRequest(jobRequest).then(res => {
            if (!(res instanceof Error)) {
              if (res === "Zip Code doesn't exist") {
                setPopUpDialog(prevState => ({
                  ...prevState,
                  shouldPopUp: true,
                  msgHeader: "",
                  msgBody: "Due to limited resources in the area, appointment scheduling is not available online. We will get this assigned to a representative and confirm the appointment date and time within 48 hours. ",
                }));
              }
              else {
                localStorage.setItem('jobRequestId', res.data);
                updateError(null);
                setOtherInformationValidationPass(true);
              }
            }
            else {
              updateError({ ...log, message: res.message, isValid: false });
            }
            setLoading(false);
          }).catch(err => {
            updateError({ ...log, message: res.message, isValid: false });
            return new Error(err.message);
          })
        }
      } else {
        setOnSubmitClick(true);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (onSubmitClick === true) {
      scrollToTop();
      setOnSubmitClick(false);
    }
  }, [onSubmitClick])

  const toggleAdjusterValidation = () => {
    setCurrentJobRequest({ ...jobRequest, isAdjusterAssigned: isAdjusterAssigned });
    validateAdjusterInformation(jobRequest);
  }

  useEffect(() => {
    toggleAdjusterValidation();
  }, [isAdjusterAssigned, jobRequest.serviceType])

  const handleAdjusterAssigned = () => {
    setIsAdjusterAssigned(!isAdjusterAssigned);
  };

  function showValidationMessageOnTop(message) {
    updateError({ ...log, message: message, isValid: false });
    window.scrollTo(0, 0);
  }

  const scrollToTop = () => {
    if (!IsNullOrEmpty(jobRequestValidation.result?.contractorOnClaim?.message)) {
      showValidationMessageOnTop(jobRequestValidation.result?.contractorOnClaim?.message)
    }
    else if (!IsNullOrEmpty(jobRequestValidation.result?.contractorFirstName?.message)) {
      showValidationMessageOnTop(jobRequestValidation.result?.contractorFirstName?.message)
    }
    else if (!IsNullOrEmpty(jobRequestValidation.result?.contractorLastName?.message)) {
      showValidationMessageOnTop(jobRequestValidation.result?.contractorLastName?.message)
    }
    else if (!IsNullOrEmpty(jobRequestValidation.result?.contractorPhone?.message)) {
      showValidationMessageOnTop(jobRequestValidation.result?.contractorPhone?.message)
    }
    else if (!IsNullOrEmpty(jobRequestValidation.result?.reinspection?.message)) {
      showValidationMessageOnTop(jobRequestValidation.result?.reinspection?.message)
    }
    else if (!IsNullOrEmpty(jobRequestValidation.result?.notes?.message)) {
      showValidationMessageOnTop(jobRequestValidation.result?.notes?.message)
    }
  };

  const reinspectionOnChange = (event, newValue) => {
    let reinspectionValue = ParseEventStringValue(event);

    if (jobRequestValidation.validationHasRun) {
      validateJobRequestActions.validateReinspection(reinspectionValue);
    }
    if (!IsNullOrEmpty(reinspectionValue)) {
      setIsReinspection(reinspectionValue === 'yes' || reinspectionValue === 'no');
      setReinspectionValue(reinspectionValue);

      setCurrentJobRequest({ ...jobRequest, reinspection: reinspectionValue === 'yes' ? "true" : "false" });
    }
  };

  const contractorOnClaimOnChange = (event, newValue) => {
    let contractorOnClaimValue = ParseEventStringValue(event);

    if (jobRequestValidation.validationHasRun) {
      validateJobRequestActions.validateContractorOnClaim(contractorOnClaimValue);
    }
    if (!IsNullOrEmpty(contractorOnClaimValue)) {
      setIsContractorOnClaim(contractorOnClaimValue === 'yes' || contractorOnClaimValue === 'no');
      setContractorOnClaimValue(contractorOnClaimValue);

      setCurrentJobRequest({ ...jobRequest, contractorOnClaim: contractorOnClaimValue });
    }
  };

  const contractorFirstNameOnChange = (event, newValue) => {
    let contractorFirstNameValue = ParseEventStringValue(event);
    if (jobRequestValidation.validationHasRun) {
      validateJobRequestActions.validateContractorFirstName(contractorFirstNameValue);
    }
    setCurrentJobRequest({ ...jobRequest, contractorFirstName: contractorFirstNameValue });
  };

  const contractorLastNameOnChange = (event, newValue) => {
    let contractorLastNameValue = ParseEventStringValue(event);
    if (jobRequestValidation.validationHasRun) {
      validateJobRequestActions.validateContractorLastName(contractorLastNameValue);
    }
    setCurrentJobRequest({ ...jobRequest, contractorLastName: contractorLastNameValue });
  };

  const contractorPhoneOnChange = (event, newValue) => {
    let contractorPhoneValue = ParseEventStringValue(event);
    if (jobRequestValidation.validationHasRun) {
      validateJobRequestActions.validateContractorPhone(contractorPhoneValue);
    }
    setCurrentJobRequest({ ...jobRequest, contractorPhone: contractorPhoneValue });
  };

  const notesOnChange = (event) => {
    setSaveNotesBtnDisabled(false);
    setCurrentJobRequest({ ...jobRequest, notes: ParseEventStringValue(event) });
  };

  const [popUpDialog, setPopUpDialog] = useState({ shouldPopUp: false, msgHeader: "", msgBody: "", buttonStatus: "" });

  useImperativeHandle(ref, () => ({
    blur: () => {
      onSubmit();
    },
  }));

  useEffect(() => {
    setCurrentJobRequest({ ...jobRequest, accessToken: accessToken });
    setOtherInformationValidationPass(false);
  }, [])

  const handleBack = () => {
    props.handleBack();
  };
  return (
    <div style={{ width: '80vw' }}>
      <PopUpDialog params={popUpDialog} />
      <Box>
        <Grid container spacing={{ xs: 1, sm: 2, md: 3 }} style={{ backgroundColor: 'white' }} >
          <Grid item xs={12} style={{ marginRight: 20, marginTop: 2 }} >
            {
              error && (
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  <strong>{error}</strong>
                </Alert>
              )}
          </Grid>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justify="center"
            style={{ marginLeft: 10, marginRight: 0 }}
          >
            <Grid item xs={12}>
              <Card className={classes.card}>
                <CardContent>
                  <Grid
                    container
                    spacing={3}
                    sx={{ justifyContent: 'space-between' }}
                  >
                    <Grid container
                      spacing={2}
                      alignItems="center"
                      justify="center"
                      style={{ marginLeft: 0, marginTop: 10 }}
                    >
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container
                          spacing={2}
                          alignItems="center"
                          justify="center"
                          style={{ marginLeft: 0, marginRight: 0 }}
                        >
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography
                              color="textSecondary"
                              gutterBottom
                              variant="h6"
                              fontWeight="600"
                            >
                              Contractor on claim?
                            </Typography>
                            <AutoCompleteOffForm>
                              <RadioGroup
                                row
                                aria-label="contractor on claim"
                                value={contractorOnClaimValue}
                                error={(jobRequestValidation.result?.contractorOnClaim?.isValid === false && isContractorOnClaim === null ? "INVALID" : null)}
                                onChange={contractorOnClaimOnChange}
                              >

                                <FormControlLabel
                                  disabled={(pageModeValue === "edit-dashboard")}
                                  value="yes"
                                  control={<Radio required />}
                                  label="Yes"
                                />
                                <FormControlLabel
                                  disabled={(pageModeValue === "edit-dashboard")}
                                  value="no"
                                  control={<Radio required />}
                                  label="No"
                                />
                              </RadioGroup>

                              {(
                                jobRequestValidation.result?.contractorOnClaim?.isValid === false && isContractorOnClaim === null ?
                                  <span style={{ fontSize: '13px', color: "#D32F2F" }}>
                                    {jobRequestValidation.result?.contractorOnClaim?.message}
                                  </span>
                                  :
                                  <span style={{ fontSize: '13px', color: "grey" }}>
                                    Required
                                  </span>
                              )}
                            </AutoCompleteOffForm>
                          </Grid>
                          {
                            (contractorOnClaimValue === 'yes' || jobRequest.contractorOnClaim === 'true') &&
                            <>
                              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                <AutoCompleteOffForm autoComplete={"off"}>
                                  <TextField
                                    disabled={(pageModeValue === "edit-dashboard")}
                                    aria-label="contractor first name"
                                    fullWidth
                                    label="Contractor First Name"
                                    required
                                    InputLabelProps={{ shrink: IsNullOrEmpty(jobRequest.contractorFirstName) ? false : true }}
                                    value={jobRequest.contractorFirstName}
                                    onBlur={scrollToTop}
                                    inputProps={{ maxLength: 255, autocomplete: "new-password" }}
                                    variant="outlined"
                                    onChange={contractorFirstNameOnChange}
                                    error={(jobRequestValidation.result?.contractorFirstName?.isValid === false ? (jobRequestValidation.result?.contractorFirstName?.isValid === false ? jobRequestValidation.result?.contractorFirstName?.message : "INVALID") : null)}
                                    helperText={(jobRequestValidation.result?.contractorFirstName?.isValid === false ? jobRequestValidation.result?.contractorFirstName?.message : "Required")}
                                  />
                                </AutoCompleteOffForm>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                <AutoCompleteOffForm autoComplete={"off"}>
                                  <TextField
                                    disabled={(pageModeValue === "edit-dashboard")}
                                    aria-label="contractor last name"
                                    fullWidth
                                    label="Contractor Last Name"
                                    name="contractorLastName"
                                    required
                                    InputLabelProps={{ shrink: IsNullOrEmpty(jobRequest.contractorLastName) ? false : true }}
                                    value={jobRequest.contractorLastName}
                                    onBlur={scrollToTop}
                                    inputProps={{ maxLength: 255, autocomplete: "new-password" }}
                                    onChange={contractorLastNameOnChange}
                                    variant="outlined"
                                    error={(jobRequestValidation.result?.contractorLastName?.isValid === false ? (jobRequestValidation.result?.contractorLastName?.isValid === false ? jobRequestValidation.result?.contractorLastName?.message : "INVALID") : null)}
                                    helperText={(jobRequestValidation.result?.contractorLastName?.isValid === false ? jobRequestValidation.result?.contractorLastName?.message : "Required")}
                                  />
                                </AutoCompleteOffForm>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                <InputMask
                                  mask="(999)999-9999"
                                  onChange={contractorPhoneOnChange}
                                  value={jobRequest.contractorPhone}
                                >
                                  {() => <AutoCompleteOffForm autoComplete={"off"}>
                                    <TextField
                                      disabled={(pageModeValue === "edit-dashboard")}
                                      aria-label="contractor phone"
                                      label="Contractor Phone"
                                      fullWidth
                                      required
                                      InputLabelProps={{ shrink: IsNullOrEmpty(jobRequest.contractorPhone) ? false : true }}
                                      onBlur={scrollToTop}
                                      inputProps={{ autocomplete: "new-password" }}
                                      error={(jobRequestValidation.result?.contractorPhone?.isValid === false ? (jobRequestValidation.result?.contractorPhone?.isValid === false ? jobRequestValidation.result?.contractorPhone?.message : "INVALID") : null)}
                                      helperText={(jobRequestValidation.result?.contractorPhone?.isValid === false ? jobRequestValidation.result?.contractorPhone?.message : "Required")}
                                    />
                                  </AutoCompleteOffForm>
                                  }
                                </InputMask>
                              </Grid>
                            </>
                          }
                          <Box
                            sx={{
                              pt: 2,
                              display: 'flex',
                              alignItems: 'center'
                            }}
                            style={{ marginLeft: 15 }}
                          >
                            <Typography
                              variant="body2"
                            >
                              If Yes, please fill in all contractor information
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} style={{ margin: 15 }}>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="h6"
                          fontWeight="600"
                        >
                          Is this a Re-inspection?
                        </Typography>
                        <AutoCompleteOffForm>
                          <RadioGroup
                            disabled={(pageModeValue === "edit-dashboard")}
                            row
                            aria-label="reinspection"
                            value={reinspectionValue}
                            error={(jobRequestValidation.result?.reinspection?.isValid === false && isReinspection === null ? "INVALID" : null)}
                            onChange={reinspectionOnChange}
                          >
                            <FormControlLabel
                              disabled={(pageModeValue === "edit-dashboard")}
                              value="yes"
                              control={<Radio required />}
                              label="Yes"
                            />
                            <FormControlLabel
                              disabled={(pageModeValue === "edit-dashboard")}
                              value="no"
                              control={<Radio required />}
                              label="No"
                            />
                          </RadioGroup>
                        </AutoCompleteOffForm>
                        {(
                          jobRequestValidation.result?.reinspection?.isValid === false && isReinspection === null ?
                            <span style={{ fontSize: '13px', color: "#D32F2F" }}>
                              {jobRequestValidation.result?.reinspection?.message}
                            </span>
                            :
                            <span style={{ fontSize: '13px', color: "grey" }}>
                              Required
                            </span>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="h6"
                      fontWeight="600"
                    >
                      Notes and other details you would like to share to complete the inspection:
                    </Typography>
                    <AutoCompleteOffForm>
                      <TextField
                        onChange={notesOnChange}
                        label=""
                        multiline
                        value={jobRequest.notes}
                        InputLabelProps={{ shrink: IsNullOrEmpty(jobRequest.notes) ? false : true }}
                        onBlur={scrollToTop}
                        rows={2}
                        fullWidth
                        inputProps={{ maxLength: 255, autocomplete: "off" }}
                      />
                    </AutoCompleteOffForm>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Grid container justifyContent="flex-end">
        <Button
          {...props}
          type="button"
          variant="contained"
          disabled={props.activeStep === 0}
          onClick={handleBack}
          color="primary"
          sx={{ mr: 1, paddingTop: 1.2 }}
        >
          Back
        </Button>
        {
          props.editmode !== null && pageModeValue === "edit-dashboard" &&
          <React.Fragment>
            <Button type="button" disabled={saveNotesBtnDisabled || noteLoading} variant="contained" onClick={onSaveNotes} aria-label="submit" color="primary" sx={{ paddingTop: 1.2 }}>
              {noteLoading ?
                <CircularProgress size={24} />
                :
                "Save Notes"
              }
            </Button>

          </React.Fragment>
        }
        <Box>
          <Button
            ref={btnNext}
            {...props}
            style={{ marginLeft: 10 }}
            disabled={loading}
            type="button"
            variant="contained"
            aria-label="submit"
            color="primary"
            sx={{ paddingTop: 1.2 }}
          >
            {loading ?
              <CircularProgress size={24} />
              :
              props.editmode !== null && pageModeValue === "edit-dashboard" ? "ReSchedule" : "Next"
            }
          </Button>
        </Box>
      </Grid>
    </div>
  );
}

export default forwardRef(OtherInformation);

