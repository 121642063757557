import React, { useState, useEffect } from "react";
import { useStoreState, useStoreActions } from 'easy-peasy';
import { makeStyles } from '@mui/styles';
import WaitContainerTimeout from './WaitContainerTimeout';
import { format } from 'date-fns';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import {Card,CardContent,Divider,Tooltip,List,ListSubheader,ListItem,ListItemText} from '@mui/material';
const useStyles = makeStyles((theme) => ({
    listItemRoot: {
        "&& .Mui-selected, && .Mui-selected:hover": {
            backgroundColor: '#8a2432',
            '&, & .MuiLitItemIcon-root':{
                color: "white"
            },
        }
    },
    '& .MuiListItemButton-root:hover':{
        backgroundColor: '#8a2432',
    },
  root: {
    width: '100%',    
   },
}));

export default function TimeRangePicker({availabilityByTimeSlotIndex, onTimeSlotSelected, targetDate}) {
  const classes = useStyles();  
  const [selectedIndex, setSelectedIndex] = useState(-1);  
  const addToBookATimeSlot = useStoreActions((actions) => actions.addToBookATimeSlot);
  const timeSlots = useStoreState((state) => state.timeSlots);
  const timeSlot = useStoreState((state) => state.timeSlot);

  const handleListItemClick = (event, i, timeSlotId, startHour, endHour) => {    
    setSelectedIndex(i); 
    let parameters =  { timeSlotId: timeSlotId, startHour: startHour, endHour: endHour, scheduleDate: format(targetDate, "yyyy-MM-dd")};	   
    addToBookATimeSlot(parameters);    
  }; 

  useEffect(() => {
    setSelectedIndex(timeSlots?.findIndex((x) => (x.id ===  timeSlot.timeSlotId)));
  }, [])

  return (
    <div className={classes.root}>
     <WaitContainerTimeout reset={availabilityByTimeSlotIndex} >
        <List style={{padding: '50px', width: '100%'}} className={classes.listItemRoot} component="nav" aria-label="available time slots" aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Choose Time
            </ListSubheader>
          }>
          <Divider />          
          {
          timeSlots.length > 0?(
          timeSlots && timeSlots.map((timeSlot, i) => { return (
            <Tooltip key={i} title={`${!timeSlot.available ? "No resource available.":""}`}>
              <div>
                <ListItem
                  key={i}
                  button
                  classes = {{ root: classes.listItemRoot}}
                  selected={selectedIndex === i}
                  disabled={!timeSlot.available}
                  style={!timeSlot.available ? { pointerEvents: "none" } : {}}
                  onClick={(event) => handleListItemClick(event, i, timeSlot.id, timeSlot.startHour, timeSlot.endHour)}
                >
                  <ListItemText  primary={`${timeSlot.startHour} - ${timeSlot.endHour}${!timeSlot.available ? " (not available)":""}`} />

                </ListItem>
            </div>
            </Tooltip>          
            )})  
          )
          :
          (<>
            <Card>
              <CardContent>
                <DoNotDisturbIcon style = {{ marginTop: 20, marginRight: 10 }}   />
                  <div style = {{ marginTop: -30, marginLeft: 50 }}> Not Available at this time </div>
              </CardContent>
            </Card>
          </>
          )
          }
        </List>
      </WaitContainerTimeout>
    </div>
  );
}