import { useMsal } from "@azure/msal-react";

const Signout = async () => {
    const { instance } = useMsal();
    const isAdmin = window.isCarrierPortalPilotAdmin;
    isAdmin?
    await instance.logoutRedirect({
        postLogoutRedirectUri: "/admin",
    })
    :
    await instance.logoutRedirect({
        postLogoutRedirectUri: "/",
    })
}

export default Signout;
