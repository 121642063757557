import React, { memo } from 'react';
import dayjs from 'dayjs';
import useWeather from './useWeather';
import Loading from './loading';
import { CardContent, Box, Typography } from '@mui/material';

const WeatherCard = ({ foo, date }) => {
  const isMetric = true;
  const { forecast, isLoading, isError } = useWeather(
    'weather',
    date
  );

  if (isLoading || isError) return <Loading />;
  return (
    <>
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            p: 0,
            m: 1,
            bgcolor: 'background.paper',
            borderRadius: 1,
            marginLeft: '30%',
            minWidth: '100%',
          }}
        >
          {
            forecast && forecast.map((item) => {
              return (
                <>
                  <Box
                    display="flex"
                    style={{ flexGrow: 1, textAlign: "center" }}
                  >
                    <Box m="auto">
                      <Typography variant="h6" gutterBottom component="div">
                        {item.location}, {item.country}
                      </Typography>
                      <Typography variant="h6" gutterBottom component="div">
                        {dayjs(item.dt_txt).format('dddd')} {dayjs(item.dt_txt).format('MM-DD')}
                      </Typography>
                      <Typography variant="subtitle2" gutterBottom component="div">
                        <span className={item.forecastIcon} style={{ fontSize: '6rem', margin: 10 }}></span>
                      </Typography>
                      <Typography variant="subtitle2" gutterBottom component="div">
                        <div className="wi wi-strong-wind text-xl" style={{ fontSize: '1.5rem' }} /> {item.wind_speed}
                        {isMetric ? `m/s` : `mph`} winds
                      </Typography>
                      <Typography variant="subtitle2" gutterBottom component="div">
                        <div className="wi wi-humidity text-xl" style={{ fontSize: '1.5rem' }} /> {item.humidity}% humidity
                      </Typography>
                      <Typography variant="subtitle2" gutterBottom component="div">
                        {item.min}&deg; / {item.max}&deg;
                      </Typography>
                      <Typography variant="subtitle2" gutterBottom component="div">
                        Sunrise {item.sunrise}  Sunset {item.sunset}
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom component="div">
                        {item.temperature}&deg; Feels like {item.feels_like}&deg;
                      </Typography>
                    </Box>
                  </Box>
                </>
              )
            })
          }
        </Box>
      </CardContent>
    </>
  )
}
export default (WeatherCard);

