import React, { useState, useEffect, useRef } from "react";
import { useStoreState, useStoreActions } from 'easy-peasy';
import useMediaQuery from '@mui/material/useMediaQuery';
import AddIcon from '@mui/icons-material/Add';
import { TextField } from "../components/TextField";
import { makeStyles } from '@mui/styles';
import { ParseEventStringValue, IsNullOrEmpty, CheckIsValidDomain } from "../helpers/Common";
import { useTheme } from '@mui/material/styles';
import { useMsal } from "@azure/msal-react";
import { GetAccessTokenRequest } from "../components/Account";
import Signout from '../views/Signout';
import { AutoCompleteOffForm } from '../components/AutoCompleteOffForm';
import {
  DialogActions, DialogTitle, DialogContent, DialogContentText, Dialog, Box, Button, Card, CardHeader, CardContent, RadioGroup, Divider, Grid, FormControlLabel, Radio,
  Alert, Typography, AlertTitle, createFilterOptions, Switch, MenuItem, Paper, CircularProgress
} from '@mui/material';
import { setDefaultResultOrder } from "dns";

const useStyles = makeStyles(() => ({
  card: {
    width: '99%',
    marginBottom: 10
  },
  cardHeader: {
    background: 'linear-gradient(0deg, #e0e0e0 10%, #cfd8dc 100%)',
    height: '2.5vw',
    "@media (max-width: 1660px)": {
      "& .MuiCardHeader-title": {
        titleTypographyProps: { variant: 'h6' },
        variant: 'h6'
      },
    }
  },
  phone: {
    marginLeft: 15,
    textDecoration: 'none',
    marginTop: '12%',
    marginRight: 20
  },
  input: {
    height: 6
  },
}));

const Setting = (props) => {
  const { instance } = useMsal();
  const theme = useTheme();
  const classes = useStyles();
  const getDomains = useStoreActions((actions) => actions.getDomains);
  const domains = useStoreState((state) => state.domains);
  const accessToken = useStoreState((state) => state.accessToken);
  const userEmail = useStoreState((state) => state.userEmail);
  const log = useStoreState((state) => state.log);
  const [domain, setDomain] = useState({ companyName: '', emailDomain: '', updatedBy: '', isActive: '', accessToken: accessToken });
  const updateDomain = useStoreActions((actions) => actions.updateDomain);
  const createDomain = useStoreActions((actions) => actions.createDomain);
  const deleteDomain = useStoreActions((actions) => actions.deleteDomain);
  const updateAccessToken = useStoreActions(actions => actions.updateAccessToken);
  const updateError = useStoreActions(actions => actions.updateError);
  const setSidebarSelectedIndex = useStoreActions((actions) => actions.setSidebarSelectedIndex);
  const error = useStoreState((state) => state.error);
  const [switchValue, setSwitchValue] = useState();
  const [popUpInsertDialog, setPopUpInsertDialog] = useState(false);
  const [companyName, setCompanyName] = useState();
  const [emailDomain, setEmailDomain] = useState();
  const [isDomainEnabled, setIsDomainEnabled] = useState(false);
  const [index, setIndex] = useState();
  const [id, setId] = useState();
  const [userAccessToken, setUserAccessToken] = useState('');
  let errorState = { status: undefined, message: '' };
  const [loading, setLoading] = useState({
    index: null,
    deleteLoading: false
  })
  const [validationControl, setValidationControl] = useState({
    index: null,
    id: null,
    emailDomain: errorState,
    companyName: errorState,
    summary: errorState
  });

  const fullScreen = useMediaQuery(theme.breakpoints.down('xl'));

  const handleDomainEnabledOnChange = () => {
    setIsDomainEnabled(!isDomainEnabled)
  };

  const handleCheckboxOnChange = (index, id) => {
    let isActive = !(domains[index].isActive === null ? false : domains[index].isActive)
    domains[index].isActive = isActive;
    setSwitchValue(
      domains?.map((domain, currentIndex) =>
        currentIndex === index
          ? { ...domain, id: id, isActive: isActive }
          : domain.isActive
      )
    )
  };

  const handleEmailDomainOnChange = (event, index, id) => {
    let textfield = ParseEventStringValue(event);
    domains[index].emailDomain = textfield;
    setSwitchValue(
      domains?.map((domain, currentIndex) =>
        currentIndex === index
          ? { ...domain, id: id, emailDomain: textfield }
          : domain.emailDomain
      )
    )
  };

  const handleUpdateDomain = (event, index, id) => {
    var emailDomain = domains[index].emailDomain;
    var companyName = domains[index].companyName;
    var isActive = domains[index].isActive;
    updateError({ ...log, message: "", isValid: true });
    setId(id);
    setIndex(index);
    if (onValidateUpdateDomain(emailDomain, index, id)) {
      let newDomain = { id: id, isActive: isActive, companyName: companyName, emailDomain: emailDomain, updatedBy: userEmail }
      setDomain(newDomain);
      newDomain.accessToken = accessToken;
      const res = updateDomain(newDomain)
        .then(res => {
          if ((res instanceof Error)) {
          }
          else {
            setValidationControl({ index: index, id: id, summary: { status: 'Updated', message: `${companyName} is updated!` } });
            getDomains(accessToken);
          }
        })
        .catch(err => {
          updateError({ ...log, message: res.message, isValid: false });
          return new Error(err.message);
        })
    }
  };

  const handleDeleteDomain = (event, index, id) => {
    var companyName = domains[index].companyName;
    updateError({ ...log, message: "", isValid: true });
    setLoading(prevState => ({ ...prevState, index: index, deleteLoading: true }));
    let newDomain = { id: id }
    newDomain.accessToken = accessToken;
    const res = deleteDomain(newDomain)
      .then(res => {
        if ((res instanceof Error)) {
        }
        else {
          setValidationControl({ index: index, id: id, summary: { status: 'Deleted', message: `${companyName} is deleted!` } });
        }
        setLoading(prevState => ({ ...prevState, index: null, deleteLoading: false }));
      })
      .catch(err => {
        updateError({ ...log, message: res.message, isValid: false });
        return new Error(err.message);
      })
  }

  useEffect(() => {
    if (!IsNullOrEmpty(error)) {
      setValidationControl({ index: index, id: id, summary: { status: 'error', message: error } });
    }
  }, [error])

  const companyNameOnChange = (event) => {
    setCompanyName(ParseEventStringValue(event))
  }

  const emailDomainOnChange = (event) => {
    setEmailDomain(ParseEventStringValue(event))
  }

  const handleNewDomainClick = () => {
    clearValidationControl();
    setPopUpInsertDialog(true);
  };

  const handleClosePopup = () => {
    setPopUpInsertDialog(false);
    setClearAddDomainInputFields();
    let emptyValidationControl = { status: undefined, message: '' };
    setValidationControl({ companyName: emptyValidationControl, emailDomain: emptyValidationControl, summary: emptyValidationControl });
    getDomains(accessToken);
  };

  const setClearAddDomainInputFields = () => {
    setCompanyName('');
    setEmailDomain('');
    setIsDomainEnabled(false);
  }

  const onValidateCreateDomain = () => {
    if (IsNullOrEmpty(companyName)) {
      let errorMessage = { status: 'error', message: 'Company Name is Required!' };
      setValidationControl({
        companyName: errorMessage,
        summary: errorMessage
      });
      return false;
    }
    else {
      setValidationControl({
        companyName: ''
      });
    }
    if (!CheckIsValidDomain(emailDomain)) {
      let errorMessage = { status: 'error', message: 'Wrong Email Domain format!' };
      setValidationControl({
        emailDomain: errorMessage,
        summary: errorMessage
      });
      return false;
    }
    else {
      setValidationControl({
        emailDomain: ''
      });
    }
    if (IsNullOrEmpty(emailDomain)) {
      let errorMessage = { status: 'error', message: 'Email Domain is Required!' };
      setValidationControl({
        emailDomain: errorMessage,
        summary: errorMessage
      });
      return false;
    }
    return true;
  }

  const clearValidationControl = () => {
    setValidationControl({
      id: null,
      index: null,
      emailDomain: ''
    });
  }

  const onValidateUpdateDomain = (emailDomain, index, id) => {
    clearValidationControl();
    if (!CheckIsValidDomain(emailDomain)) {
      let errorMessage = { index: index, id: id, status: 'error', message: 'Wrong Email Domain format!' };
      setValidationControl({
        id: id,
        index: index,
        emailDomain: errorMessage,
        summary: errorMessage
      });
      return false;
    }

    if (IsNullOrEmpty(emailDomain)) {
      let errorMessage = { index: index, id: id, status: 'error', message: 'Email Domain is Required!' };
      setValidationControl({
        id: id,
        index: index,
        emailDomain: errorMessage,
        summary: errorMessage
      });
      return false;
    }
    return true;
  }

  const handleCreateDomain = () => {
    if (onValidateCreateDomain()) {
      let newDomain = { isActive: isDomainEnabled, companyName: companyName, emailDomain: emailDomain, updatedBy: userEmail }
      setDomain(newDomain)
      newDomain.accessToken = accessToken;
      const res = createDomain(newDomain)
        .then(res => {
          if ((res instanceof Error)) {
            updateError({ ...log, message: res.message, isValid: true });
            setValidationControl({ summary: { status: 'error', message: res.message } });
          }
          else {
            setValidationControl({ summary: { status: 'success', message: `${companyName} is created!` } });
            setClearAddDomainInputFields();
          }
        })
        .catch(err => {
          updateError({ ...log, message: res.message, isValid: false });
          return new Error(err.message);
        })
    }
  };

  useEffect(() => {
    const accounts = instance.getAllAccounts();
    instance.acquireTokenSilent(GetAccessTokenRequest(accounts)).then(function (accessTokenResponse) {
      let accessToken = accessTokenResponse.accessToken;
      updateAccessToken(accessToken);
      setUserAccessToken(accessToken);
      domains.accessToken = accessToken;
      getDomains(accessToken);
      setSidebarSelectedIndex(2);
      return accessToken;
    });
    updateError(null);
  }, [])

  useEffect(() => {
    setClearAddDomainInputFields();
  }, [validationControl.summary?.status == 'success'])

  if (error === "access denial") {
    Signout();
  }

  useEffect(
    () => {
      const id = setTimeout(() => {
        setValidationControl(prevState => ({
          ...prevState,
          index: null,
          id: null,
          summary: errorState
        }));
        getDomains(accessToken);
      }, 2000);
      return () => { clearTimeout(id); };
    },
    [loading.deleteLoading],
  );

  return (
    <div className={classes.root}>
      <Box>
        <Grid container spacing={{ xs: 1, sm: 2, md: 3 }} style={{ backgroundColor: 'white' }} >
          <Grid item xs={12} style={{ marginRight: 20, marginTop: 2 }} >
            <Typography variant="h5" className={classes.appBarTitle}>
              Domain Approval
            </Typography>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
          </Grid>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justify="center"
            style={{ marginLeft: 10, marginRight: 0, marginTop: 2 }}
          >
            <Grid item xs={12}>
              <Card className={classes.card}>
                <CardHeader
                  className={classes.cardHeader}
                  title="Domain Setting"
                  titleTypographyProps={{ variant: 'h6' }}
                />
                <Divider />
                <Button
                  style={{ marginBottom: 15 }}
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={handleNewDomainClick}>
                  Add a new company domain
                </Button>
                <CardContent>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid
                      container
                      spacing={{ xs: 1, sm: 2, lg: 2 }}
                      columns={{ xs: 8, sm: 8, md: 8, lg: 8 }}
                    >
                      <Grid item xs={8} sm={8} md={4} lg={12}>
                        <Box sx={{ flexGrow: 1, height: '100%' }}>
                          <Grid
                            sx={{ height: '100%' }}
                            container
                            spacing={{ xs: 1, sm: 2, lg: 2 }}
                            columns={{ xs: 4, sm: 4, md: 8, lg: 8 }} //change column 
                          >
                            {domains.length > 0 && domains.map((domain, index) => {
                              return (
                                <>
                                  <Grid item xs={4} sm={4} md={4}>
                                    <Card sx={{ height: '100%' }}>
                                      <CardContent>
                                        <Typography
                                          sx={{ fontSize: 20, marginBottom: 3 }}
                                          color="text.secondary"
                                          gutterBottom
                                        >
                                          {domain.companyName}
                                        </Typography>
                                        <AutoCompleteOffForm autoComplete={"off"} style={{ marginBottom: 10 }}>
                                          {
                                            validationControl?.index == index && validationControl?.summary?.status != undefined && (
                                              <Alert severity={validationControl?.summary.status == 'error' ? "error" : "success"}>
                                                <AlertTitle>{validationControl?.summary.status == 'error' ? "Error" : validationControl?.summary?.status}</AlertTitle>
                                                <strong>{validationControl?.summary.message}</strong>
                                              </Alert>
                                            )}
                                        </AutoCompleteOffForm>
                                        <AutoCompleteOffForm>
                                          <TextField
                                            fullWidth
                                            style={{ marginBottom: 15 }}
                                            label={`Email Domain`}
                                            InputProps={{ maxLength: 255, autocomplete: "off" }}
                                            InputLabelProps={{ shrink: IsNullOrEmpty(domain.emailDomain) ? false : true }}
                                            value={domain.emailDomain}
                                            onChange={(e) => handleEmailDomainOnChange(e, index, domain.id)}
                                            error={(validationControl?.index == domain.id && validationControl?.emailDomain?.status === 'error') ? validationControl?.emailDomain.message : ''}
                                          />
                                        </AutoCompleteOffForm>
                                        <Grid
                                          container
                                          direction="row"
                                          justifyContent="flex-start"
                                          alignItems="center"
                                        >
                                          <FormControlLabel
                                            control={
                                              <Switch
                                                checked={domain.isActive}
                                                onChange={() => handleCheckboxOnChange(index, domain.id)}
                                                index={index}
                                              />
                                            }
                                            label={domain.isActive ? "Active" : "Inactive"}
                                          />
                                          <Button onClick={(e) => handleUpdateDomain(e, index, domain.id)} sx={{ paddingTop: 1.2 }}>Save</Button>
                                          {
                                            (loading.deleteLoading && loading.index === index) ?
                                              <CircularProgress size={20} />
                                              :
                                              <Button onClick={(e) => handleDeleteDomain(e, index, domain.id)} sx={{ paddingTop: 1.2 }}>Delete</Button>
                                          }
                                        </Grid>
                                      </CardContent>
                                    </Card>
                                  </Grid>
                                </>
                              );
                            })}
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {popUpInsertDialog &&
        <Dialog
          PaperProps={{
            sx: {
              width: '80%',
              maxHeight: "30%",
              maxWidth: '200vh'
            }
          }}
          fullScreen={fullScreen}
          maxWidth='lg'
          open={setPopUpInsertDialog}
          onClose={handleClosePopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Add Company Domain"}
          </DialogTitle>
          <Divider />
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gridGap: 50, width: '100%' }}>
                <div>
                  <TextField
                    aria-label="company name"
                    fullWidth
                    label="Company Name"
                    required
                    InputLabelProps={{ shrink: IsNullOrEmpty(companyName) ? false : true }}
                    value={companyName}
                    onChange={companyNameOnChange}
                    variant="outlined"
                    inputProps={{ maxLength: 40 }}
                    error={(validationControl?.companyName?.status === 'error' ? validationControl?.companyName.message : null)}
                  />
                </div>
                <div>
                  <TextField
                    aria-label="email domain"
                    fullWidth
                    label="Email Domain"
                    required
                    InputLabelProps={{ shrink: IsNullOrEmpty(emailDomain) ? false : true }}
                    value={emailDomain}
                    onChange={emailDomainOnChange}
                    variant="outlined"
                    inputProps={{ maxLength: 40 }}
                    error={(validationControl?.emailDomain?.status) === 'error' ? validationControl?.emailDomain.message : ''}
                  />
                </div>
                <div>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isDomainEnabled}
                        onChange={() => handleDomainEnabledOnChange()}
                      />
                    }
                    label={isDomainEnabled ? "Active" : "Inactive"}
                  />
                  {
                    validationControl?.summary?.status != undefined && (
                      <Alert severity={validationControl?.summary.status == 'error' ? "error" : "success"}>
                        <AlertTitle>{validationControl?.summary.status == 'error' ? "Error" : "Saved"}</AlertTitle>
                        <strong>{validationControl?.summary.message}</strong>
                      </Alert>
                    )}
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClosePopup} sx={{ paddingTop: 1.2 }}>CLose</Button>
            <Button onClick={handleCreateDomain} sx={{ paddingTop: 1.2 }}>Save</Button>
          </DialogActions>
        </Dialog>
      }
    </div>
  );
}

export default Setting;
